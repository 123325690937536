<section class="bg-light">
<div class="container">
  <div class="row align-items-center">
    <!-- <div class="col-md-6 col-4">
      <h1 class="h2 mb-0">Login</h1>
    </div> -->
    <div class="col-md-12 mt-3 mt-md-0">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><a class="text-dark" href="#"><i class="fa fa-home mr-1"></i>{{'menu.Home' | translate}}</a>
          </li>
          <li class="breadcrumb-item active text-primary" aria-current="page">{{'menu.Login' | translate}}</li>
        </ol>
      </nav>
    </div>
  </div>
  <!-- / .row -->
</div>
<!-- / .container -->
</section>



<div class="page-content">

<!--login start-->

<section>
<div class="container" style="margin: 15px 0px;">
  <div class="row justify-content-center">
    <div class="col-lg-5">
      <div class="shadow p-3">

        <h3 class="text-center mb-3 text-uppercase">User Login</h3>
        <form id="contact-form" [formGroup]="signInForm" (ngSubmit)="onSubmit()">
          <div class="messages"></div>
          <div class="form-group">
            <label>{{'label.Username,EmailorPhone' | translate}} <span class="text-danger">*</span></label>
            <input id="form_name" type="text" name="name"  formControlName="UserName" class="form-control" placeholder="{{'label.Username,EmailorPhone' | translate}}" data-error="Username is required.">
            <div class="help-block with-errors">
              <div class="form-error"
              *ngIf="(f.UserName.touched && f.UserName.errors) || (f.UserName.dirty && f.UserName.errors) ||  (submitted && f.UserName.errors)">
              <div *ngIf="f.UserName.errors.required">{{'errors.UserNameIsRequired' | translate}} </div>
            </div>
            </div>
          </div>
          <div class="form-group">
            <label>{{'label.Password' | translate}} <span class="text-danger">*</span></label>
            <input id="form_password" type="password" name="password"  formControlName="Password" class="form-control" placeholder="{{'label.Password' | translate}}" data-error="password is required.">
            <div class="help-block with-errors">
              <div class="form-error"
              *ngIf="(f.Password.touched && f.Password.errors) || (f.Password.dirty && f.Password.errors) ||  (submitted && f.Password.errors)">
              <div *ngIf="f.Password.errors.required">{{'errors.PasswordIsRequired' | translate}}</div>
            </div>
            </div>
          </div>
          <div class="form-group mt-4 mb-3">
            <div class="remember-checkbox d-flex justify-content-between">
              <div class="custom-control custom-checkbox mb-2">
                <input type="checkbox" class="custom-control-input" id="check2" name="check2">
                <label class="custom-control-label " for="check2">
                  <span>
                    {{'label.RememberMe' | translate}}
                  </span></label>
              </div>            
              
               <a  style="cursor: pointer; color: red;" (click)="onForgotPassword()">{{'label.ForgotPassword' | translate}}?</a>
            </div>
          </div> <button   (click)="onSubmit()" class="btn btn-primary btn-animated" style="color: white;">{{'button.LoginNow' | translate}}</button>


        </form>
        <div class="d-flex align-items-center text-center justify-content-center mt-4">
                <span class="text-muted mr-1">{{'label.DontHaveAnAccount' | translate}}</span>
                 <a routerLink="/signup">{{'button.SignUp' | translate}}</a>
              </div>
      </div>
    </div>
  </div>
</div>
</section>

<!--login end-->

</div>


