<section class="bg-light">
<div class="container">
  <div class="row align-items-center">  
    <div class="col-md-6 col-7">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent p-0 m-0">
          <li class="breadcrumb-item"><a class="text-dark" ><i class="las la-home mr-1"></i>Home</a>
          </li>          
          <li class="breadcrumb-item active text-primary" aria-current="page">Profile</li>
        </ol>
      </nav>
    </div>
  </div>
  <!-- / .row -->
</div>
<!-- / .container -->
</section>


<div class="page-content">

<!--feature start-->

<section>
<div class="container">
  <div class="row align-items-end mb-2">
    <div class="col-12 col-md-12 col-lg-6">
      <div>
        <h6 class="text-primary mb-1">
               - Hi Customer
            </h6>
        <h2 class="mb-0">What can we help you with?</h2>
      </div>
    </div>
    <div class="col-12 col-md-12 col-lg-6">
      <div>

      </div>
    </div>
  </div>
  <div class="row align-items-center coming-soon" style="cursor: pointer;"  routerLink="/coming-soon" >
    <div class="col-lg-3 col-md-6 col-6 mb-3">
      <div class="px-4 py-7 rounded text-center border">
        <i class="las la-boxes ic-3x text-primary"></i>
        <h5 class="mt-2 mb-1">Your Orders</h5>
        <p class="mb-0"></p>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-6 mb-3" style="cursor: pointer;" routerLink="/coming-soon">
      <div class="px-4 py-7 rounded text-center border">
        <i class="las la-user-circle ic-3x text-primary"></i>
        <h5 class="mt-2 mb-1">Your Account</h5>
        <p class="mb-0"></p>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-6 mb-3" style="cursor: pointer;" routerLink="/coming-soon">
      <div class="px-4 py-7 rounded text-center border">
        <i class="las la-undo-alt ic-3x text-primary"></i>
        <h5 class="mt-2 mb-1">Returns & Refunds</h5>
        <p class="mb-0"></p>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-6 mb-3" style="cursor: pointer;" routerLink="/coming-soon">
      <div class="px-4 py-7 rounded text-center border">
        <i class="las la-shipping-fast ic-3x text-primary"></i>
        <h5 class="mt-2 mb-1">Delivery Address</h5>
        <p class="mb-0"></p>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-6 mb-3" style="cursor: pointer;" routerLink="/wishlist">
      <div class="px-4 py-7 rounded text-center border">
        <i class="las las la-heart ic-3x text-primary"></i>
        <h5 class="mt-2 mb-1">Wish List</h5>
        <p class="mb-0"></p>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-6 mb-3" style="cursor: pointer;" routerLink="/coming-soon">
      <div class="px-4 py-7 rounded text-center border">
       <i class="las la-money-bill-alt ic-3x text-primary"></i>
        <h5 class="mt-2 mb-1">Payments & Gift Card</h5>
        <p class="mb-0"></p>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-6 mb-3" style="cursor: pointer;" routerLink="/contact" >
      <div class="px-4 py-7 rounded text-center border">
        <i class="las la-credit-card ic-3x text-primary"></i>
        <h5 class="mt-2 mb-1">Contact Us</h5>
        <p class="mb-0"></p>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-6 mb-3" style="cursor: pointer;" (click)="logOut()">
      <div class="px-4 py-7 rounded text-center border">
       <i class="las la-history ic-3x text-primary"></i>
        <h5  class="mt-2 mb-1">Log Out</h5>
        <p class="mb-0"></p>
      </div>
    </div>


  </div>

     <div class="row align-items-center">
    <!-- <div class="col-lg-3 col-md-6 col-6 mb-3">
      <div class="px-4 py-7 rounded text-center border">
        <i class="las la-credit-card ic-3x text-primary"></i>
        <h5 class="mt-2 mb-1">Contact Us</h5>
        <p class="mb-0"></p>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-6 mb-3 mt-6 mt-md-0">
      <div class="px-4 py-7 rounded text-center border">
       <i class="las la-shipping-fast ic-3x text-primary"></i>
        <h5 class="mt-2 mb-1">Log Out</h5>
        <p class="mb-0"></p>
      </div>
    </div> -->

  </div>

</div>
</section>

<!--feature end-->



</div>









