import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  configDetails ;
  userDetails ;
  cartCount : number = 0;
  isLoggedIn : boolean = false;
  forgotUserName : string = "";
  cartList =[]
  constructor() { }
}
