import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DbService } from 'src/app/services/db.service';

declare function showNotification(type : string,titile : string,message :string): any;

@Component({
  selector: 'app-verify-customer',
  templateUrl: './verify-customer.component.html',
  styleUrls: ['./verify-customer.component.scss']
})
export class VerifyCustomerComponent implements OnInit {

  mode : number
  customerId : number
  userName : string
  constructor(private route  : ActivatedRoute , private fb : FormBuilder,
    private spinner : NgxSpinnerService,
    private router : Router,
    private dbService : DbService
    ) {


    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.mode != undefined) {
       this.mode = Number(atob(queryParams.mode));
      }
      if (queryParams.customer != undefined) {
         this.customerId = Number(atob(queryParams.customer));
      }
      if (queryParams.userName != undefined) {
         this.userName = atob(queryParams.userName);
      }

      // this.userName ='sajanasrin@gmail.com'

  })

  this.buildForm();

}
submitted : boolean = false;

  ngOnInit(): void {
  }

  onResendOtp(){

    let body  =
    {
      Email :  this.userName
    }



    this.spinner.show()
    this.dbService.methodGet("Common/ResendOTP/" + this.customerId).subscribe(resdata =>{

      this.spinner.hide();
      if(resdata.userId > 0){
        showNotification('success','Success','OTP sent your registered email . please verify.');
      }
    }
    ,
    error =>{
      this.spinner.hide();
      showNotification('error','Failed',error.error.message);

    })
  }
  onSubmit(){
    let body  ={
      userId : this.customerId ,
      verificationCode : (this.f.Otp.value).toString()
    }



    this.spinner.show();

     this.dbService.methodPut("Common/VerifyUser/" + this.customerId , body).subscribe(resdata =>{
       this.spinner.hide();
      if(resdata.userId > 0){
        showNotification('success','Success','Verified Successfully');
        this.router.navigate(['/login'])
      }
     }
     ,
    error =>{
      this.spinner.hide();
      showNotification('error','Failed',error.error.message);

    })
  }

  get f() { return this.signInForm.controls };

  signInForm : FormGroup;

  buildForm(){
    this.signInForm=this.fb.group({
      CustomerId :[this.customerId , Validators.required],
      Otp :["",[Validators.required , Validators.minLength(6) , Validators.maxLength(6)]]

    })
  }


}
