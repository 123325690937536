export class Constants {
  // public static get HOME_URL(): string {
  //   return "http://localhost:53629/api/";
  // }
  // public static get TOKEN_URL(): string {
  //   return "http://localhost:53629/";
  // }

  // public static get HOME_URL(): string {
  //   //return "https://anbar.clevericsolutions.com/webapi/api/";
  //   return "https://anbar.clevericsolutions.com/webapi/api/";
  // }
  // public static get TOKEN_URL(): string {
  //   //return "https://business.edinemenu.com/";
  //   return "https://anbar.clevericsolutions.com/webapi/";
  // }
  public static get HOME_URL(): string {
    return "https://anbar.clevericsolutions.com/webapi/api/";
    //return "https://anbar.clevericsolutions.com/webapi/api/";
  }
  public static get TOKEN_URL(): string {
    //return "https://business.edinemenu.com/";
    return "https://anbar.clevericsolutions.com/webapi/";
  }
  public static get PAYMENT_SUCEESS_URL(): string {
    //return "https://business.edinemenu.com/";
    return "https://anbar.net/#/success/?";
  }
  public static get PAYMENT_FAILURE_URL(): string {
    //return "https://business.edinemenu.com/";
    return "https://anbar.net/#/failed/?";
  }
  // public static get HOME_URL(): string {
  //   return "https://ecommerce.edinemenu.com/webapi/api/";
  // }
  // public static get TOKEN_URL(): string {
  //   return "https://ecommerce.edinemenu.com/";
  // }
  // public static get HOME_URL(): string {
  //   return window.location.origin + "/api/";
  // }
  // public static get TOKEN_URL(): string {
  //   return window.location.origin + "/";
  // }






  // public static emailFormat = "[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}";
  // public static mobileFormat = /[0-9\+\-\ ]/;
  // public static nameFormat = "[a-zA-Z\s .]+$";
  // public static telephoneFormat = /[0-9\+\-\ ]/;
  // public static numberFormat="[0-9]+"
  // public static decimalFormat="[0-9.]+"
  // public static alphanumricFormat="^[a-zA-Z0-9_.' ]*$"

  // public static productActivationDetails: any;





}
export function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
