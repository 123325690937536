import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss']
})
export class NavigatorComponent implements OnInit {
  productId = 0
  stockId = 0
  productName = ""
  constructor(private route : ActivatedRoute , private router : Router) {

    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.productId != undefined) {
        this.productId = Number(atob(queryParams.productId));



        // this.getProductDetails(this.productId);
      }

      if(queryParams.stockId){
        this.stockId = Number(atob(queryParams.stockId));
      }
      if(queryParams.productName){
        this.productName =queryParams.productName
      }
      this.router.navigate(['/product-details'] ,{
        queryParams: { productName : this.productName  , productId : btoa(this.productId.toString())} })

    })
   }


  ngOnInit(): void {
  }

}
