<section class="bg-light">
    <div class="container">
      <div class="row align-items-center">
        <!-- <div class="col-md-4 col-4">
        <h1 class="h2 mb-0">Signup</h1>
      </div> -->
        <div class="col-md-12 mt-3 mt-md-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent p-0 m-0">
              <li class="breadcrumb-item"><a class="text-dark"><i class="las la-home mr-1"></i>{{'menu.Home' |
                  translate}}</a>
              </li>
              <li class="breadcrumb-item active text-primary" aria-current="page">{{'menu.VendorRegistration' | translate}}</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
  </section>

  <div class="page-content">
    <section class="register bg-vendor">
       


        <div class="container">
            <div class="row justify-content-center text-center">
              <div class="col-lg-8 col-md-12">

                <div class="mb-4">
                    <h2 class="text-primary mb-1">
                      Vendor Registration
                    </h2>
                  <p class="lead"></p>
                  </div></div></div>


                  <div class="row">
                    <div class="col-lg-8 col-md-10 ml-auto mr-auto">
                      <div class="register-form text-center">
                        <form id="contact-form" [formGroup]="regForm" (ngSubmit)="onSubmit()">
                          
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label class="signup">{{'label.Name' | translate}}<span class="text-danger">*</span></label>
                                <div class="help-block with-errors">
                                    <div class="form-error"
                                      *ngIf="(f.vendorName.touched && f.vendorName.errors) || (f.vendorName.dirty && f.vendorName.errors) ||  (submitted && f.vendorName.errors)">
                                      <div *ngIf="f.vendorName.errors.required">{{'errors.Required' | translate}}
                                      </div>
                                    </div>
                                  </div>
                                <input type="text" formControlName="vendorName" name="name" class="form-control"placeholder="Name">
                                
                              </div>
                            </div>
                           
                            <div class="col-md-6">
                                <div class="form-group">
                                  <label class="signup">{{'label.Address' | translate}}<span class="text-danger">*</span></label>
                                  <div class="help-block with-errors">
                                    <div class="form-error"
                                      *ngIf="(f.address.touched && f.address.errors) || (f.address.dirty && f.address.errors) ||  (submitted && f.address.errors)">
                                      <div *ngIf="f.address.errors.required">{{'errors.Required' | translate}}
                                      </div>
                                    </div>
                                  </div>
                                  <input type="text" formControlName="address" name="name" class="form-control"placeholder="Address">
                                  
                                </div>
                              </div>
            
            
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label class="signup">{{'label.ContactPerson' | translate}}<span class="text-danger">*</span></label>
                                  <div class="help-block with-errors">
                                    <div class="form-error"
                                      *ngIf="(f.contactPerson.touched && f.contactPerson.errors) || (f.contactPerson.dirty && f.contactPerson.errors) ||  (submitted && f.contactPerson.errors)">
                                      <div *ngIf="f.contactPerson.errors.required">{{'errors.Required' | translate}}
                                      </div>
                                    </div>
                                  </div>
                                  <input type="text" formControlName="contactPerson" name="name" class="form-control"placeholder="Contact person">
                                  
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label class="signup">{{'label.Phone' | translate}}<span class="text-danger">*</span></label>
                                  <div class="help-block with-errors">
                                    <div class="form-error"
                                      *ngIf="(f.phone.touched && f.phone.errors) || (f.phone.dirty && f.phone.errors) ||  (submitted && f.phone.errors)">
                                      <div *ngIf="f.phone.errors.required">{{'errors.Required' | translate}}
                                      </div>
                                    </div>
                                  </div> 
                                  <input id="form_phone" formControlName="phone" type="text" name="phone" class="form-control"
                      placeholder="Phone">
                                  
                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label class="signup">{{'label.Email' | translate}}<span class="text-danger">*</span></label>
                                  <div class="help-block with-errors">
                                    <div class="form-error"
                                      *ngIf="(f.email.touched && f.email.errors) || (f.email.dirty && f.email.errors) ||  (submitted && f.email.errors)">
                                      <div *ngIf="f.email.errors.required">{{'errors.Required' | translate}}
                                      </div>
                                    </div>
                                  </div>
                                  <input id="form_phone" formControlName="email" type="text" name="phone" class="form-control"
                                  placeholder="Email">
                                  
                                </div>
                              </div>
                          


            
                              
                           
            
                            
            
                          </div>
            
                         
                          <div class="row" style="margin-top: 25px; margin-bottom: 20px;">
                            <div class="col-md-12">
                              <a (click)="onSubmit()" class="btn btn-primary btn-animated"
                                style="color: white;">{{'button.Register' | translate}}</a>
                             
                            </div>
                          </div>
                        </form>



                      </div>
                    </div>
                  </div>



                  </div>


    </section>
  </div>