import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common-values/common.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(private common : CommonService , private router : Router) { }

  ngOnInit(): void {
  }
  logOut(){
    if(confirm('are you sure to logout ?')){
      localStorage.clear();
      this.common.isLoggedIn =false;
      this.router.navigate(['/'])
    }
  }

}
