<section class="bg-light">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-12 mt-3 mt-md-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item">
              <a class="text-dark" href="#"><i class="fa fa-home mr-1"></i>{{'menu.Home' | translate}}</a>
            </li>
            <li class="breadcrumb-item active text-primary" aria-current="page">{{'menu.ProductCart' | translate}}</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>


<div class="page-content">

  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div>
            <h6 class="text-primary text_right" style="margin-left: 10px; margin-top: 10px;">{{'label.MyBag' | translate}} , {{cartList?.length}} {{'label.Items'| translate}}</h6>

          </div>
          <div class="page">
            <div id="store_cart">
           <!--    <ul class="cart_head">
                <li class="cart_head_title">
                 My Cart
               </li>
               <li class="cart_head_product">
                Product
              </li>
              <li class="cart_head_options">
                Quantity
              </li>
          <li class="cart_head_price">
            Size
          </li>
          <li class="cart_head_price">
            Color
          </li>
          <li class="cart_head_price">
            Price
          </li>
          <li class="cart_head_price">
            Total
          </li>
        </ul> -->



<!--         <ul class="cart_item" *ngFor="let item of cartList">

          <li class="cart_img_col">
            <img [src]="item?.productImages[0]?.imagePath">
          </li>

          <li class="cart_product_col">
            <p>{{item?.productName}} </p>
            <span><strong>Size: </strong>{{item?.sizeOptionName}} | {{item?.colorName}}</span>
          </li>

          <li class="cart_options_col">
            <div class="d-flex align-items-center mr-sm-4">
              <button class="btn-product btn-product-up"> <i class="las la-minus" (click)="onQtyChange(item ,1)"></i>
              </button>
              <input [(ngModel)]="item.quantity"  class="form-product" type="number" name="form-product" value="1">
              <button class="btn-product btn-product-down" (click)="onQtyChange(item , 2)"> <i class="las la-plus"></i>
              </button>
            </div>
            <span>Quantity: </span>
              <input type="number" min="1" value="1" [(ngModel)]="item.quantity"   (change)="onChange($event)">
            </li>

          <li class="cart_price_col">
            <h2>{{item?.sizeOptionName}}</h2>
          </li>
          <li class="cart_price_col">
            <h2>{{item?.colorName}}</h2>
          </li>
          <li class="cart_price_col">
            <h2>${{item?.discountPrice | number : default_currencyformat}}</h2>
          </li>
          <li class="cart_price_col">
            <h2>${{item?.total | number : default_currencyformat}}</h2>
          </li>
          <li class="cart_del_col">
            <i (click)="deleteCartItem(item)" class="fa fa-trash" aria-hidden="true" style="cursor: pointer;"></i>
          </li>
        </ul> -->
        <div class="table-responsive-sm" *ngIf="!isMobileView">
          <table class="product-list ">
            <tr>
              <th>{{'label.Product' | translate}}</th>
              <th> </th>
              <th> {{'label.Quantity' | translate}}</th>
              <th> {{'label.Size' | translate}}</th>
              <th> {{'label.Color' | translate}}</th>
              <th> {{'label.Price' | translate}}</th>
              <th> {{'label.Total' | translate}}</th>
              <th></th>
            </tr>
            <tr *ngFor="let item of cartList">
              <td class="cart_item">
                <div class="cart_img_col">
                  <img [src]="item?.productImages[0]?.imagePath">
                </div>
              </td>
              <td class="cart_product">
                <p>
                  <!-- {{item?.productDescription}} -->
                  <span *ngIf="lang ==0">{{item?.productName}}</span>
                  <span *ngIf="lang ==1">{{item?.productNameAr}}</span>


                  <br>
                  <!-- <span *ngIf="item?.availableStock <= 0"  style="color: red;">Out Of Stock</span> -->
                <!-- <span *ngIf="item?.outOfStockSelected == true" style="color: red;">Only {{item.availableStock}} quantity available</span> -->

                <span  *ngIf="item?.availableStock <= 0"  style="color: red; font-size: 11px;">{{'label.OutOfStock' | translate}}</span> 
                 <span *ngIf="item?.outOfStockSelected == true" style="color: red;font-size: 11px;">{{'label.Only' | translate}} {{item.availableStock}} {{'label.QuantityAvailable' | translate}}</span>


                </p>
                <!-- <span><strong>Size: </strong>{{item?.sizeOptionName}} | {{item?.colorName}}</span> -->
              </td>
              <td class="cart_quantity">
                <div class="d-flex align-items-center scale9">
                  <button class="btn-product btn-product-up"> <i class="fa fa-minus" (click)="onQtyChange(item ,1)"></i>
                  </button>
                  <input [(ngModel)]="item.quantity"  class="form-product fwbold" type="text" name="form-product" value="1">
                  <button class="btn-product btn-product-down" (click)="onQtyChange(item , 2)"> <i class="fa fa-plus"></i>
                  </button>
                </div>
              </td>
              <td  class="price"><p>{{item?.sizeOptionName}}</p></td>
              <td  class="price"><p>
                
              
                <span *ngIf="lang ==0">{{item?.colorName}}</span>
                <span *ngIf="lang ==1">{{item?.colorNameAr}}</span>
              </p></td>
              <td class="price"><p>QR.{{item?.discountPrice | number : default_currencyformat}}</p></td>
              <td class="price"><p>QR.{{item?.total | number : default_currencyformat}}</p></td>
              <td><a class="delete_item" (click)="deleteCartItem(item)" ><i class="fa fa-trash" aria-hidden="true" style="cursor: pointer;"></i></a></td>
            </tr>
          </table>
        </div>
        <div class="mobile-table" *ngIf="isMobileView">
          <div *ngFor="let item of cartList" class="row mb-4 borderbottom">
            <div class="col-6">
              <img [src]="item?.productImages[0]?.imagePath">
            </div>
            <div class="col-6 pd010 details">
              <h4>
                <span *ngIf="lang ==0">{{item?.productName}}</span>
                <span *ngIf="lang ==1">{{item?.productNameAr}}</span>
                <br>
                <span *ngIf="item?.availableStock <= 0" style="color: red;">{{'label.OutOfStock' | translate}}</span>
                <span *ngIf="item?.outOfStockSelected == true" style="color: red;">{{'label.Only' | translate}} {{item.availableStock}} {{'label.QuantityAvailable' | translate}}</span>

              </h4>
              <div class="size">
                <span class="label">{{'label.Size' | translate}} :</span> {{item?.sizeOptionName}}
              </div>
              <div class="size">
               <span class="label">{{'label.Color' | translate}} :</span> 
               <span *ngIf="lang ==0">{{item?.colorName}}</span>
               <span *ngIf="lang ==1">{{item?.colorNameAr}}</span>
             </div>
             <div class="d-flex">
              <span class="mauto0 label">{{'label.Qty' | translate}}: </span>
              <div class="d-flex align-items-center mr-sm-4 scale9">
                <button class="btn-product btn-product-up"> <i class="fa fa-minus" (click)="onQtyChange(item ,1)"></i>
                </button>
                <input [(ngModel)]="item.quantity"  class="form-product fwbold" type="text" name="form-product" value="1">
                <button class="btn-product btn-product-down" (click)="onQtyChange(item , 2)"> <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>
                <!-- <p>${{item?.discountPrice | number : default_currencyformat}}</p>
                  <p>${{item?.total | number : default_currencyformat}}</p> -->
                  <div class="mt-1">
                    <span class="product-price">
                      <div class="d-flex">
                        <span>
                          <span class="label">{{'label.Price' | translate}} :&nbsp;</span>QR.{{item?.discountPrice | number:default_currencyformat}}
                        </span>
                      </div>
                      <span>
                        <span class="label">{{'label.Total' | translate}} :&nbsp;</span>QR.{{item?.total | number:default_currencyformat}}
                      </span>
                    </span>
                  </div>
                  <div>
                    <a class="delete_item" (click)="deleteCartItem(item)" ><i class="fa fa-trash" aria-hidden="true" style="cursor: pointer;"></i></a>
                  </div>
              </div>
            </div>
          </div>
<!--
        <ul class="cart_item">

          <li class="cart_img_col">
            <img src="../../../../assets/assets/images/product-thumb/01.jpg">
          </li>

          <li class="cart_product_col">
            <p>Off-Road </p>
            <span><strong>Size: </strong>XL | Black</span>
          </li>

              <li class="cart_options_col">
            <span>Quantity: </span>
            <input type="number" min="1" value="1">
          </li>

          <li class="cart_price_col">
            <h2>$399</h2>
          </li>
          <li class="cart_del_col">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </li>
        </ul>

        <ul class="cart_item">

          <li class="cart_img_col">
            <img src="../../../../assets/assets/images/product-thumb/01.jpg">
          </li>

          <li class="cart_product_col">
            <p>Off-Road </p>
            <span><strong>Size: </strong>XL | White</span>
          </li>

              <li class="cart_options_col">
            <span>Quantity: </span>
            <input type="number" min="1" value="1">
          </li>

          <li class="cart_price_col">
            <h2>$399</h2>
          </li>
          <li class="cart_del_col">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </li>
        </ul> -->


      </div>
    </div>




     
    </div>

    <div class="col-lg-4 pl-lg-5 mt-8 mt-lg-0" style="position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 4px;
    padding-left: 4px;">
    <div class="rounded p-1">

      <h4 class="text-dark text-center mb-2">{{'label.CartTotal'| translate}}</h4>
      <div id="accordion" class="accordion">
        <!-- <div class="card border-0 mb-3">
          <div class="card-header">
            <h6 class="mb-0">
        <a class="link-title" data-toggle="collapse" data-parent="#accordion" href="#collapse4">Coupon</a>
        </h6>
          </div>
          <div id="collapse4" class="collapse" data-parent="#accordion">
            <div class="card-body text-muted">
              <div class="row form-row">
                <div class="col">
                  <input class="form-control" id="coupon" placeholder="Coupon Code" type="text">
                </div>
                <div class="col col-auto">
                  <button class="btn btn-primary btn-animated">Apply Coupon</button>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="d-flex justify-content-between align-items-center border-bottom py-3"> <span class="text-muted">{{'label.SubTotal'| translate}}</span>  <span class="text-dark">QR.{{subTotal | number : default_currencyformat}}</span>
      </div>
      <div class="d-flex justify-content-between align-items-center border-bottom py-3"> <span class="text-muted">{{'label.Shipping'| translate}}</span>  <span class="text-dark">QR.{{shippingAmount | number : default_currencyformat}}</span>
      </div>

      <div class="d-flex justify-content-between align-items-center border-bottom py-3" *ngIf="couponDetails?.discountPercent"> <span class="text-muted" >{{'label.CouponDiscount'| translate}} ({{couponDetails?.discountPercent}} %) </span>  <span class="text-dark">QR.{{couponDiscAmont | number : default_currencyformat}}</span>
      </div>

      <div>
        <label class="text-dark h4" for="coupon">{{'label.Coupon' | translate}}</label>
        <p>{{'desc.EnterCoupon' | translate}}.</p>
        <div class="row form-row">
          <div class="col">
            <input [(ngModel)]="couponCode"[readonly]="couponDetails?.couponCode" class="form-control" id="coupon" placeholder="Coupon Code" type="text">
          </div>
          <div class="col col-auto">
            <button *ngIf="couponDetails?.couponCode" (click)="onRemoveCoupon()" class="btn btn-dark btn-animated">{{'button.Remove' | translate}}</button>
            <button *ngIf="!couponDetails?.couponCode" class="btn btn-dark btn-animated" (click)="applyCoupon()">{{'button.ApplyCoupon' |  translate}}</button>
      
             
          </div>
          
        </div>
        <span *ngIf="couponDetails?.terms" style="color: #bb1b1bed;">
          {{couponDetails?.terms}}
        </span> 
      </div>
      <div class="d-flex justify-content-between align-items-center pt-3 mb-5"> <span class="text-dark h5">{{'label.Total'| translate}}</span>  <span class="text-dark font-weight-bold h5">QR.{{netTotal | number : default_currencyformat}}</span>
      </div>
      <div class="col-md-12  mb-4 mdtcenter">
        <a class="btn btn-dark btn-animated" (click)="onCheckOut()"  style="margin-right: 2px !important; color: white;"><i class="fa fa-credit-card"></i> {{'button.CheckOut'| translate}}</a>
        <a class="btn btn-dark btn-animated" style="color: white;" (click)="navigate()"><i class="fa fa-shopping-cart mr-1"></i>{{'button.CountinueShopping'| translate}}</a>
      </div>
      <br>
      <!-- <h6 class="text-dark text-center mb-2">Free Delivery* <a >More</a></h6> -->
    </div>
  </div>
</div>

<!-- <div class="d-md-flex align-items-end justify-content-between border-top pt-5">
  <div>
    <label class="text-dark h4" for="coupon">Coupon</label>
    <p>Enter your coupon code if you have one.</p>
    <div class="row form-row">
      <div class="col">
        <input [(ngModel)]="couponCode" class="form-control" id="coupon" placeholder="Coupon Code" type="text">
      </div>
      <div class="col col-auto">
        <button class="btn btn-dark btn-animated" (click)="applyCoupon()">Apply Coupon</button>
  <span *ngIf="couponDetails?.terms" style="color: red;">
    {{couponDetails?.terms}}
  </span>      
      </div>
    </div>
  </div>
  
</div> -->

</div>
</section>

</div>

<!--body content end-->










