<div id="search-box" class="media_search_box">
  <span class="mox_close" (click)="onSearchCloseMobile()"> <i class="fa fa-times" aria-hidden="true"></i> </span>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-sm-6 col-12 media_form">
        <form class="search_box_control">
          <input (keyup)="onKeyUpSearch()" [(ngModel)]="searchQuery" type="text" name="search" id="search" class="form-control" placeholder="Search" >
          <button><i class="fa fa-search" aria-hidden="true"></i></button>
        </form>
      </div>
      <div class="col-md-6 col-sm-6 col-12 media_form" *ngIf="showSearchDiv">
        <ul class="mobile-search">
          <!-- <li><img src="https://anbar.clevericsolutions.com/webapi/Images/Products/810d229a-c060-4ef7-be22-c7c41b5fa319.jpg">
            <span>Producst Name - 49 </span> <span class="mobile-search-para">description</span></li>

            <li><img src="https://anbar.clevericsolutions.com/webapi/Images/Products/810d229a-c060-4ef7-be22-c7c41b5fa319.jpg">
              <span>Producst Name - 49 </span> <span class="mobile-search-para">description</span></li> -->

              <li *ngFor="let item of searchList"><img [src]="item?.productImages[0]?.imagePath" (click)="navigateFromSearch(item)">
                <span (click)="navigateFromSearch(item)">
                  <!-- Producst Name - 49 -->

                  <span *ngIf="lang == 0">
                    {{item?.productName}}

                  </span>
                  <span *ngIf="lang ==1">
                    {{item?.productNameAr}}

                  </span>

                </span>




                <span class="mobile-search-para" (click)="navigateFromSearch(item)">
                  <!-- description -->

                  <span *ngIf="lang ===0">
                    {{item?.productDescription}}
                  </span>
                  <span *ngIf="lang ==1">
                    {{item?.productDescriptionAr}}
                  </span>
                </span></li>
            </ul>
      </div>
    </div>
  </div>
</div>
