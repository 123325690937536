import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common-values/common.service';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
 historyList =[];
 showDetails : boolean = false;
 selectedOrderDetails : {};
 selectedMainData :{}
  constructor(private dbService : DbService ,
    private router : Router,
    private confirmationDialogService  : ConfirmationService,
    private common : CommonService) { }

  ngOnInit(): void {
    this.getCustomerOrderHistoryList();
  }


  getCustomerOrderHistoryList(){
     this.dbService.methodGet("order/GetOrderHistoryByUser/" + this.common.userDetails.userId).subscribe(resdata =>{
       this.historyList = resdata;
     })
  }
  onLogOut(){
      localStorage.clear();
      this.common.isLoggedIn =false;
      this.router.navigate(['/'])
  }

  onOrderSelect(item){
    this.dbService.methodGet("order/GetOrderHistoryByOrder/" +item.orderId).subscribe(resdata =>{
      this.selectedMainData = item;
      this.selectedOrderDetails = resdata;
      this.showDetails = true;
    })
  }
  onBackClick(){
    this.showDetails = false;
    this.selectedMainData ={};
    this.selectedOrderDetails = {}
  }
}
