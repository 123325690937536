import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common-values/common.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(public common : CommonService) { }

  ngOnInit(): void {
  }

}
