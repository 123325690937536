<section class="bg-light">
  <div class="container">
    <div class="row align-items-center">

      <div class="col-md-12 mt-3 mt-md-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item">
              <a class="text-dark" href="#"><i class="fa fa-home mr-1"></i>{{'menu.Home' | translate}}</a>
            </li>
            <li class="breadcrumb-item active text-primary" aria-current="page">{{'menu.Cart' | translate}}</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>



<div class="page-content">

  <section>

    <div class="container" style="margin-top: 20px;">
      <div class="row">
        <div class="col-lg-7 col-md-12">
          <div class="accordion">
            <span id="tab1"></span>
            <span id="tab2"></span>
            <span id="tab3"></span>
            <span id="tab4"></span>
            <span id="tab5"></span>
            <div class="tabs">
              <dl class="tab1" *ngIf="!common.isLoggedIn">
                <dd>
                  <a href="#tab1" (click)="disablePlaceOrder()">{{'label.Login/SignUp' | translate}}</a>
                  <!-- <button class="action action-edit checkout_edit">
                    edit
                  </button> -->
                  <div class="inside-tab">

                    <div class="checkout-form box-shadow white-bg">

                      <form class="row" [formGroup]="checkOutForm">
                        <div class="col-md-8">
                          <div class="form-group">
                            <div class="help-block with-errors">
                              <div class="form-error"
                                *ngIf="((e.email.touched && e.email.errors) || (e.email.dirty && e.email.errors) ||  (emailSubmitted && e.email.errors)) && emailSubmitted">
                                <div *ngIf="e.email.errors.required">{{'errors.EmailIsRequired' | translate}}</div>
                                <div *ngIf="e.email.errors.email">{{'errors.InvalidEmail' | translate}}</div>

                              </div>
                            </div>
                            <input type="text" id="fname" formControlName="email" class="form-control"
                              placeholder="Email ">
                          </div>
                        </div>

                        <div class="col-md-4">
                          <a *ngIf="checkOutForm.invalid" class="btn btn-primary btn-animated btn-block"
                            style="text-align: center;" (click)="emailSubmitted = true">{{'header.CONTINUEASGUEST'|
                            translate}}</a>

                          <a *ngIf="checkOutForm.valid" class="btn btn-primary btn-animated btn-block"
                            style="text-align: center;" href="#tab3"
                            (click)="onEmailSubmit()">{{'header.CONTINUEASGUEST'| translate}}</a>
                          <!-- <a class="btn btn-primary btn-animated btn-block button-guest" href="order-complete.html">
                            Continue as a Guest</a> -->
                        </div>
                      </form>
                    </div>
                  </div>
                </dd>
              </dl>
              <!-- <dl class="tab2">
                <dd>
                  <a href="#tab2">Delivery Country</a>
                  <div class="inside-tab">
                    <div class="checkout-form box-shadow white-bg">
                      <p>
                      <form class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label>Delivery Country</label>

                          </div>

                        </div>
                        <div class="col-md-9">
                          <input type="text" id="fname" class="form-control" placeholder="">
                        </div>
                      </form>

                    </div>
                  </div>
                </dd>
              </dl> -->
              <dl class="tab3">
                <dd id="tab3">
                  <a href="#tab3" (click)="disablePlaceOrder()">{{'label.DeliveringTo' | translate}}</a>
                  <div class="inside-tab">
                    <div class="checkout-form box-shadow white-bg">
                      <p>
                      <form class="row" *ngIf="addressList.length > 0 && addNewAddress ==false">
                        <div class="col-md-4" *ngFor="let item of addressList">
                          <!---------newstyle----------->

                          <ul>
                            <li style="list-style: none;">


                              <p class="first-address">{{item?.name}}</p>
                              <p> {{item?.address1}}<br />
                                {{item?.street}}
                                <span> {{item?.landmark}}</span>
                                <span *ngIf="item?.cityName"> {{item?.cityName}} </span><br />
                                {{item?.mobileNumber}}
                              </p>

                            </li>


                          </ul>

                          <!-----------newstyle--------->


                          <!------
                          <div class="custom-control custom-radio radiobuttoncheck">
                            <input [ngModelOptions]="{standalone: true}" [(ngModel)]="addressId" type="radio"
                              [id]="item.addressId" [value]="item.addressId" [name]="item.addressId"
                              class="custom-control-input">
                            <label class="custom-control-label" [for]="item.addressId">{{item?.name}} </label><br />
                          </div>------->
                          <!-- <p style="font-size:18px;">
                            <b> {{item?.name}}<br /> </b>
                          </p> -->
                          <!-----<p class="para-margin">
                            {{item?.address1}} <br />
                            {{item?.street}} {{item?.cityName}}<br />
                            Qatar<br />
                            {{item?.mobileNumber}}<br />
                          </p>-->

                          <div>
                            <a (click)="onSelectAddress(item.addressId)" href="#tab4"
                              class="btn btn-primary btn-animated btn-block"
                              style="font-size: 11px; margin-bottom: 10px; text-align: center; font-weight: 500;"> {{'label.DELIVERTOTHISADDRESS' | translate}}
                            </a>
                          </div>




                        </div>


                      </form>
                      <button (click)="dataValid = false;onAddNewAddress(); "
                        *ngIf="addressList.length > 0  && addNewAddress == false;" class="resend arabic-resend"> Add new
                        address</button>

                      <form class="row" [formGroup]="deliveryForm"
                        *ngIf="addNewAddress == true || addressList.length == 0 ">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>{{'label.Name' | translate}} * </label>
                            <input type="text" formControlName="name" id="fname" class="form-control"
                              placeholder="{{'label.Name' | translate}}">
                          </div>
                        </div>
                        <!-- <div class="col-md-6">
                          <div class="form-group">
                            <label>Last Name</label>
                            <input type="text" id="lname" class="form-control" placeholder="Your lastname">
                          </div>
                        </div> -->
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>{{'label.City' | translate}} *</label>
                            <select formControlName="cityId" class="select-css" name="GenderId" id="GenderId"
                              (change)="onCityChange()">
                              <option value="" disabled selected>{{'label.SelectCity' | translate}}</option>
                              <option *ngFor="let item of cityList" [value]="item.cityId">{{item.city}}</option>

                            </select>

                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>{{'label.Address' | translate}} *</label>
                            <input type="text" id="address" formControlName="address1" class="form-control"
                              placeholder="{{'label.Address' | translate}}">
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label>{{'label.Street' | translate}} *</label>
                            <input type="text" id="email" formControlName="street" class="form-control"
                              placeholder="{{'label.Street' | translate}}">
                          </div>
                        </div>
                        <!-- <div class="col-md-6">
                          <div class="form-group">
                            <label>Street *</label>
                            <input type="text" formControlName="street" id="email" class="form-control"
                              placeholder="Select City">
                          </div>
                        </div> -->
                        <!-- <div class="col-md-6">
                          <div class="form-group">
                            <label>Street</label>
                            <input type="text" id="email" class="form-control" placeholder="Select City">
                          </div>
                        </div> -->
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>{{'label.Landmark' | translate}} *</label>
                            <input type="text" id="email" formControlName="landmark" class="form-control"
                              placeholder="{{'label.Landmark' | translate}}">
                          </div>
                        </div>
                        <!-- <div class="col-md-3">
                          <div class="form-group mb-md-0">
                            <label>Code</label>
                            <input type="text" id="zippostalcode" class="form-control" placeholder="Zip / Postal">
                          </div>
                        </div> -->
                        <!-- <div class="col-md-3">
                          <div class="form-group mb-md-0">
                            <label>PIN *</label>
                            <input type="text" formControlName="pincode" id="zippostalcode" class="form-control"
                              placeholder="Zip / Postal">
                          </div>
                        </div> -->
                        <div class="col-md-6">
                          <div class="form-group mb-md-0">
                            <label>{{'label.Phone' | translate}} *</label>
                            <input type="text" formControlName="mobileNumber" id="zippostalcode" class="form-control"
                              placeholder="Phone">
                          </div>
                        </div><br />
                        <div class="col-md-6">
                          <a *ngIf="deliveryForm.invalid" class="btn btn-primary btn-animated btn-block"
                            style="margin:20px 0px; text-align: center;"
                            (click)="onDelievryToAddressClick()">{{'label.DELIVERTOTHISADDRESS' | translate}}</a>
                          <a *ngIf="deliveryForm.valid" class="btn btn-primary btn-animated btn-block"
                            style="margin:20px 0px; text-align: center;" (click)="onDelievryToAddressClick()"
                            href="#tab4">{{'label.DELIVERTOTHISADDRESS' | translate}}</a>

                        </div>
                        <br>
                        <button (click)="onSavedAddresses()" *ngIf="addressList.length > 0  && addNewAddress == true;"
                          class="resend"> {{'label.SavedAddresses' | translate}}</button>

                      </form>

                    </div>
                  </div>
                </dd>
              </dl>
              <dl class="tab4">
                <dd>
                  <a href="#tab4">{{'label.Address' | translate}}</a>
                  <div class="inside-tab">
                    <div class="checkout-form box-shadow white-bg">
                      <p>
                      <form class="row" *ngIf="common.isLoggedIn == true || addressId > 0">





                        <div class="col-md-12" *ngIf="addressId > 0 ">
                          <!-- <div class="custom-control custom-radio">
                            <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
                            <label class="custom-control-label" for="customRadio1">Address</label><br />
                          </div> -->

                          <table class="col-md-12">
                            <tr>
                              <th>Name</th>
                              <td style="padding: 10px 0px;"> {{selectedAddressDetails?.name}}</td>
                            </tr>
                            <tr>
                              <th>Address</th>
                              <td style="padding: 10px 0px;"> {{selectedAddressDetails?.address1}}</td>
                            </tr>
                            <tr>
                              <th>City</th>
                              <td style="padding: 10px 0px;"> {{selectedAddressDetails?.cityName}}</td>
                            </tr>

                            <tr>
                              <th>Landmark</th>
                              <td style="padding: 10px 0px;"> {{selectedAddressDetails?.landmark}}</td>
                            </tr>

                            <tr>
                              <th>Phone</th>
                              <td style="padding: 10px 0px;"> {{selectedAddressDetails?.mobileNumber}}</td>
                            </tr>

                          </table>
                        </div>
                        <div class="col-md-12" *ngIf="addressId ==0 ">
                          <!-- <div class="custom-control custom-radio">
                            <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
                            <label class="custom-control-label" for="customRadio1">Address</label><br />
                          </div> -->

                          <table class="col-md-12">
                            <tr>
                              <th>Name</th>
                              <td style="padding: 10px 0px;">{{f.name.value}}</td>
                            </tr>
                            <tr>
                              <th>Address</th>
                              <td style="padding: 10px 0px;">{{f.address1.value}}</td>
                            </tr>
                            <tr>
                              <th>City</th>
                              <td style="padding: 10px 0px;"> {{f.cityName.value}}</td>
                            </tr>

                            <tr>
                              <th>Landmark</th>
                              <td style="padding: 10px 0px;">{{f.landmark.value}} </td>
                            </tr>

                            <tr>
                              <th>Phone</th>
                              <td style="padding: 10px 0px;"> {{f.mobileNumber.value}}</td>
                            </tr>

                          </table>
                        </div>
                      </form>
                      <form class="row" *ngIf="!common.isLoggedIn">
                        <div class="col-md-12">
                          <!-- <div class="custom-control custom-radio">
                            <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
                            <label class="custom-control-label" for="customRadio1">Address</label><br />
                          </div> -->
                          <p style="font-size:18px;">
                            {{f.name.value}}
                          </p>
                          <p class="para-margins-cart-checkout">
                            {{f.address1.value}}
                            {{f.street.value}}
                            {{f.cityName.value}}

                            {{f.mobileNumber.value}}<br />
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>

                </dd>
              </dl>
              <!-- <dl class="tab5">
                <dd>
                  <a href="#tab5">Delivery Option</a>
                  <div class="inside-tab">
                    <div class="checkout-form box-shadow white-bg">
                      <p class="">
                      <form class="row">

                        <div class="col-md-12">
                          <div class="custom-control custom-radio">
                            <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
                            <label class="custom-control-label" for="customRadio1">Pay by Credit / Debit Card</label>
                          </div>
                          <div class="form-group">
                            <label>Card Number </label>
                            <input type="text" id="email" class="form-control" placeholder="State Province">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Expiration Date </label>
                            <input type="text" id="fname" class="form-control" placeholder="Your firstname">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Card Verification Number</label>
                            <input type="text" id="lname" class="form-control" placeholder="Your lastname">
                          </div>
                        </div>





                        <br />

                      </form>
                      <form class="row">
                        <div class="col-md-6">
                          <div class="custom-control custom-radio">
                            <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
                            <label class="custom-control-label" for="customRadio1">Cash on Delivery</label>
                          </div>

                        </div>
                      </form>



                    </div>
                  </div>

                </dd>
              </dl> -->
            </div>
          </div>
        </div>


        <div class="col-lg-5 col-md-12 mt-5 mt-lg-0" style="margin-bottom: 20px;">
          <div class="shadow p-lg-5" style="padding: 15px;">
            <!-- <div class="row">
              <div class="col-md-12">
                <a class="items"> {{cartList?.length}} items</a> <a class="edit-btn"> Edit</a>
              </div>
            </div> -->
            <div class="row" *ngFor="let item of cartList ; let i =index">
              <div class="col-md-12" *ngIf="i == 0">
                <a class="items-cart-check-head"> {{cartList?.length}} {{'label.Items' | translate}}</a> <a
                  routerLink="/product-cart" style="cursor: pointer;" class="items-cart-check"> {{'label.Edit' |
                  translate}}</a>
              </div>
              <div class="col-md-4">
                <img [src]="item?.productImages[0]?.imagePath"
                  style=" padding: 6px; border: 1px solid rgb(230, 230, 230);" class="product-imgae-arabic" />
              </div>
              <div class="col-md-6" style="padding: 3px 18px 3px 18px;">
                <div class="cart-checkout">

                  <ul class="list-unstyled">
                    <li class="mb-p" style="margin-left: 7px;"><span> <b>

                          <span *ngIf="lang ==0">
                            {{item?.productName}}
                          </span>
                          <span *ngIf="lang ==1">
                            {{item?.productNameAr}}
                          </span>
                        </b></span> </li>


                    <li class="mb-p" style="margin-left: 7px;"><span> {{item?.colorName}} |</span>
                      {{'label.Qty' | translate}}: {{item?.quantity}}</li>
                    <li class="mb-p" style="margin-left: 7px;"> <span> {{'label.Size' | translate}} :
                        {{item?.sizeOptionName}} </span>
                    </li>
                    <li class="mb-p" style="margin-left: 7px;"><span> {{'label.Price' | translate}} :</span>
                      {{item?.discountPrice | number : '1.2-2'}}</li>
                  </ul>

                </div>

              </div>
              <div _ngcontent-pta-c46="" class="cartborder"></div>
            </div>
            <!-- <div class="row">
              <div class="col-md-5">
                <img src="../../../../assets/images/img1.jpg" style="width:100%; " />
              </div>
              <div class="col-md-7">
                <div class="left-cart-image">

                  <ul class="list-unstyled">
                    <li class="mb-p border-bottom pb-3"><span> <b> Crew Neck Long Sleeve Thin T Form Single Jersey
                          Dress-Black </b></span> </li>


                    <li class="mb-p"><span> BLUE |</span>
                      Qty: 1</li>
                    <li class="mb-p"> <span> Size : XXL / XL / M / S </span>
                    </li>
                    <li class="mb-p"><span> Price |</span> 45</li>
                  </ul>

                </div>

              </div>
            </div>

            <div class="row">
              <div class="col-md-12">

              </div>
              <div class="col-md-5">
                <img src="../../../../assets/images/img1.jpg" style="width:100%; " />
              </div>
              <div class="col-md-7">
                <div class="left-cart-image">

                  <ul class="list-unstyled">
                    <li class="mb-p border-bottom pb-3"><span> <b> Crew Neck Long Sleeve Thin T Form Single Jersey
                          Dress-Black </b></span> </li>


                    <li class="mb-p"><span> BLUE |</span>
                      Qty: 1</li>
                    <li class="mb-p"> <span> Size : XXL / XL / M / S </span>
                    </li>
                    <li class="mb-p "><span> Price |</span> 45</li>
                  </ul>

                </div>

              </div>
            </div> -->

            <div class="row">


              <div class="col-md-12">

                <div class="d-flex justify-content-between align-items-center pt-3 mb-5">
                  <span class="text-dark h5">{{'label.Total'| translate}}</span>
                  <span class="text-dark font-weight-bold h5">QR.{{netTotal | number : default_currencyformat}}</span>
                </div>
              </div>


              <div class="col-md-12" style="margin-top: 10px;">

                <div class="col-md-6 cart-check" style="float: left;">
                  <div class="custom-control custom-checkbox mb-2">
                    <input type="checkbox" class="custom-control-input" id="cashcheck" name="cashcheck">
                    <label class="custom-control-label " for="cashcheck">
                      <span>
                        Cash on Delivery
                      </span></label>
                  </div>
                  <!-- <label class="radio radio-gradients" >
                  <span class="radio__input">
                    <input type="radio">
                    <span class="radio__controls color1" ></span>
                  </span>
                  <span class="radio__label pricelable">Cash on Delivery </span>
                 </label> -->
                </div>

                <div class="col-md-6 cart-check" style="float: left;">
                  <div class="custom-control custom-checkbox mb-2">
                    <input type="checkbox" class="custom-control-input" id="cardcheck" name="cardcheck">
                    <label class="custom-control-label " for="cardcheck">
                      <span>
                        Card Payment
                      </span></label>
                  </div>           
                  <!-- <label class="radio radio-gradients" >
                    <span class="radio__input">
                      <input type="radio">
                      <span class="radio__controls color1" ></span>
                    </span>
                    <span class="radio__label pricelable">Cash on Delivery </span>
                   </label> -->
                </div>



              </div>

              <div class="col-md-12" *ngIf="dataValid">
                <a class="btn btn-primary btn-animated btn-block" style="margin:20px 0px; color: white;"
                  (click)="placeOrder()">{{'button.PLACEORDER' | translate}}</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>

</div>