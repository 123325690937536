import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
// import { NgxSpinnerService } from 'ngx-spinner';
import { error } from 'protractor';
import { CommonService } from 'src/app/common-values/common.service';
import { DbService } from 'src/app/services/db.service';
declare function showNotification(type : string,titile : string,message :string): any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  signInForm : FormGroup;
  submitted : boolean = false;
  constructor(private fb : FormBuilder , 
    private db : DbService , 
    private spinner : NgxSpinnerService,

    private router : Router ,
    // private spinner : NgxSpinnerService,
    private common : CommonService) { }

  ngOnInit(): void {
    // this.spinner.show();
    this.buildForm(); 
  }


  buildForm(){
    this.signInForm=this.fb.group({
      UserName :["" , Validators.required],
      Password :["",Validators.required]

    })
  }


  get f(){ return this.signInForm.controls;}


  onForgotPassword(){
     this.router.navigate(['/forgot-password'])

    // if(!this.f.UserName.value){
    // showNotification('error','Failed','please enter username');
    // }
    // else{
    //  this.common.forgotUserName = this.f.UserName.value;
    //  this.router.navigate(['/forgot-password'])
    // }



  }

  onSubmit(){
    this.submitted = true;
    if(this.signInForm.invalid){
      return;
    }

    let body =
    {
      UserName : this.f.UserName.value,
      Password : this.f.Password.value,
      carts : this.common.cartList
    }




    this.spinner.show();
    
    this.db.methodPost("Authenticate" , body).subscribe(resdata =>{
    this.spinner.hide();
      

      if(resdata.userId > 0 && resdata.isCustomer == true){
        this.router.navigate(['/']);
        localStorage.setItem('currentUser',JSON.stringify(resdata));
        localStorage.setItem('userToken', resdata.token);
        this.common.userDetails = resdata;
        this.common.isLoggedIn = true;
        this.getCartList();

        
        // if(resdata.isVerified == true){
        //   this.router.navigate(['/']);
        //   localStorage.setItem('currentUser',JSON.stringify(resdata));
        //   localStorage.setItem('userToken', resdata.token);
        //   this.common.userDetails = resdata;
        //   this.common.isLoggedIn = true;
        //   this.getCartList();
        // }
        // else{
        // showNotification('error','Failed','User not Verified , Please check your Email/Mobile for OTP');
        // let mode  = 0;
        // this.router.navigate(['/verify-customer'] ,{
        //   queryParams: { mode : btoa("1")  , customer : btoa((resdata.userId).toString())  ,
        //     userName : btoa(this.f.UserName.value).toString()
        //   }})   
        // }
       
      }
      else{
      showNotification('error','Failed','Invalid Username /  Password');

      }
    },
    error =>{
      this.spinner.hide();
      showNotification('error','Failed',error.error.message);

    })
  }


  getCartList(){
    this.db.methodGet("Carts/GetUserCart/" + this.common.userDetails.userId).subscribe(resdata =>{
      this.common.cartCount = resdata.length;
      this.common.cartList = resdata;
    })
  }
}
