<header>
  <!--start top bar-->
  <!-- <div class="top_bar">
    <div class="container">
     
      <div class="row">
      
        <div class="col-md-6 col-sm-6">
          <div class="list_item">
            <button class="services" (click)="onCustomerServiceClick()">{{'label.CustomerService' | translate}}</button>
            <div class="contact-list {{className}}">
              <ul>
                <li><span>We are available all days from :<br> 9am - 9pm </span></li>
                <li> <span><i class="fa fa-phone" aria-hidden="true"></i></span> {{common?.configDetails?.phone}}</li>
                <li> <span><i class="fa fa-envelope" aria-hidden="true"></i></span> {{common?.configDetails?.email}}</li>
              </ul>
            </div>

          </div>

          <div class="time_item">
            <span>Available 9am - 9pm </span>
            <span>{{common?.configDetails?.phone}}</span>
          </div>
        </div>
       



        
        <div class="col-md-6 col-sm-6">

         
          <div class="row">


           
            <div class="col-md-12 col-sm-12">
              <div class="language">
                <ul>
                  <li *ngIf="common?.isLoggedIn"><a style="cursor: none;">{{common?.userDetails.userName}}.</a></li>
                  <li (click)="selectLang(1)"><a >العربية</a></li>
                  <li><a  class="pipe">|</a></li>
                  <li (click)="selectLang(0)"><a >English  </a></li>
                  <li><a  class="pipe">|</a></li>
                  <li>
                    <a ><span class="flag">
                      <aside style="background-image: url(https://anbar.net/assets/images/icons/flags.jpg);"></aside>
                    </span></a>
                  </li>
                  <li>
                    <a  class="arow">Qatar</a>
                  </li>




                </ul>
              </div>

            </div>
           

          </div>
        

        </div>
       



      </div>
     
    </div>
  </div> -->
  <!--end top bar-->

  <!--start top midle bar-->
  <div class="top_midle_bar">
    <!--start container-->
    <div class="container">
      <!--start row-->
      <div class="row">
        <!--start col md 3-->
        
        
        <div class="col-lg-7 col-md-12 ">
          <div class="row">
            <div class="col-lg-4 col-md-12 ">
              <div class="min-logo logo-english">
                <a href="index.html">
                  <img src="https://anbar.net/assets/images/logo/minlogo.png" alt="" />
                </a>
              </div>
              <div class="min-logo logo-arabic">
                <a href="index.html">
                  <img src="https://anbar.net/assets/images/logo/minlogo-arabic.png" alt="" />
                </a>
              </div>
            </div>
            <div class="col-lg-8 col-md-12 ">

              <div class="list_item" style="margin-top: 30px;">
                <button class="services" (click)="onCustomerServiceClick()">{{'label.CustomerService' | translate}}</button>
                <div class="contact-list {{className}}">
                  <ul>
                    <!-- <li><span>We are available all days from :<br> 9am - 9pm </span></li> -->
                    <li> <span><i class="fa fa-phone" aria-hidden="true"></i></span> {{common?.configDetails?.phone}}</li>
                    <li> <span><i class="fa fa-envelope" aria-hidden="true"></i></span> {{common?.configDetails?.email}}</li>
                  </ul>
                </div>
    
              </div>


            </div>
          </div>
        </div>
        <!--end col md 3-->
        <!--
				<div class="col-md-5 col-sm-5">


				</div> -->

        
        <!--start col md 3-->
        <div class="col-lg-5 col-md-12 ">
          <div class="row">
            <div class="col-lg-6 col-md-12 " style="margin-top:10px;">
              <span class="like">
                <table>
                  <tr >
                    <td (click)="navigateToWishList()">
                      <input type="checkbox" name="checkboxG1" id="checkboxG1"
                      class="css-checkbox" /><label for="checkboxG1" class="css-label"></label></td>
  
                    </tr>
                  </table>
                </span>
              
                <div class="login_and_register" style="cursor: pointer;">
  
                  <span>
                    <div class="dropdown">
                      <button class="dropbtn" (click)="navigateToProfile()">
                    <img  src="https://anbar.net/assets/images/icons/log-admin.png" alt="" /></button>
                    <div class="dropdown-content" *ngIf="common.isLoggedIn == true">
                      <a (click)="myAccountNavigation(1)" class=""><i class="fa fa-user"style="margin:0px 5px ;"></i>{{'header.MyAccount' | translate}}</a>
                      <a (click)="myAccountNavigation(2)"><i class="fa fa-address-card"style="margin:0px 5px ;"></i>{{'header.OrderHistory' | translate}}</a>
                      <a (click)="myAccountNavigation(3)"><i class="fa fa-shopping-basket"style="margin:0px 5px ;"></i>{{'label.DeliveryAddress' | translate}}</a>
                      <a (click)="logOut()"><i class="fa fa-close"style="margin:0px 5px ;"></i>{{'label.LogOut' | translate}}</a>
                    </div>
                  </div>
                  </span>
  
                  <span >
                   
                    <button *ngIf="common?.isLoggedIn == false;" routerLink="/login">{{'label.Login' | translate}}</button>
  
                    <button *ngIf="common?.isLoggedIn == false;" routerLink="/signup">{{'label.Register' | translate}}</button>
  
                    <button *ngIf="common?.isLoggedIn == true;"  (click)="logOut();">{{'label.LogOut' | translate}}</button>
  
  
                  </span>
  
                  <span >
                    <a routerLink="/product-cart">
                      <aside>{{common.cartList.length}}</aside>
                      <img src="https://anbar.net/assets/images/icons/cart.png" alt="" />
                    </a>
                  </span>
                  </div>
            </div>
            <div class="col-lg-6 col-md-12 ">
              <div class="language" style="margin-top:30px;">
                <ul>
                  <li *ngIf="common?.isLoggedIn"><a style="cursor: none;">{{common?.userDetails.userName}}.</a></li>
                  <li (click)="selectLang(1)"><a >العربية</a></li>
                  <li><a  class="pipe">|</a></li>
                  <li (click)="selectLang(0)"><a >English  </a></li>
                  <li><a  class="pipe">|</a></li>
                  <li>
                    <a ><span class="flag">
                      <aside style="background-image: url(https://anbar.net/assets/images/icons/flags.jpg);"></aside>
                    </span></a>
                  </li>
                  <li>
                    <a  class="arow">Qatar</a>
                  </li>




                </ul>
              </div>
            </div>
            


            </div>

          </div>
          <!--end col md 3-->

        </div>



        <!--end row-->
      </div>
      <!--end container-->
    </div>
    <!--end top midle bar-->

    <!--start nav cover-->
    <div class="nav_cover">
      <button class="min-toggle" (click)="onMenuToggleButtonClickMobile()">
        <span></span>
        <span></span>
      </button>


      <div class="sm-logo logo-english">
        <a href="index.html">
          <img src="https://anbar.net/assets/images/logo/minlogo.png" alt="">
        </a>
      </div>
      <div class="sm-logo logo-arabic">
        <a href="index.html">
          <img src="https://anbar.net/assets/images/logo/minlogo-arabic.png" alt="">
        </a>
      </div>
      <div class="language-header">
        <a (click)="selectLang(1)">AR </a>
        <a> | </a>
        <a (click)="selectLang(0)">  EN</a> </div>

      <div class="col-cover media_controls">
        <!-- <ul class="mobile-search">
          <li>
            item 1
          </li>
          <li>
            item 1
          </li>
        </ul> -->
        <span *ngIf="this.common.isLoggedIn" (click)="logOut()"><button style="margin-top: 15px;">Logout</button></span>
      
        <span *ngIf="!this.common.isLoggedIn" routerLink="/login"><button style="margin-top: 15px;">Login</button></span>
        <span *ngIf="!this.common.isLoggedIn" routerLink="/signup"><button style="margin-top: 15px; margin-right: -5px ;">Register</button></span>

        <div class="search_panel">
          <button (click)="onSearchClickMobile()"><i class="fa fa-search" aria-hidden="true"></i></button>
        </div>

        <!-- <div class="login_and_register">
          <span  (click)="navigateToWishList()">
            <i class="fa fa-heart" aria-hidden="true" ></i>
          </span>

        </div>
        <div class="login_and_register">
          <span (click)="navigateToProfile()">
            <img  src="https://anbar.net/assets/images/icons/log-admin.png" alt="" />
          </span>

          <span>
               <button *ngIf="common?.isLoggedIn == false;" routerLink="/login">{{'label.Login' | translate}}</button>

            <button *ngIf="common?.isLoggedIn == false;" routerLink="/signup">{{'label.Register' | translate}}</button>

            <button *ngIf="common?.isLoggedIn == true;"  (click)="logOut();">Log {{'label.LogOut' | translate}}</button>
          </span>      

        <span >
          <a routerLink="/product-cart">
            <aside>{{common.cartList.length}}</aside>
            <img src="https://anbar.net/assets/images/icons/cart.png" alt="" />
          </a>
        </span>
      </div> -->
    </div>


    <div class="container">
      <div id="bg-nav"  (click)="toggleClose()"></div>
      <div class="row">
        <div class="col-md-9 col-sm-12">
          <div id="navBar" class="min-navigation">
            <button class="toggle_button" (click)="toggleClose()">
              <i class="fa fa-chevron-left mobile" aria-hidden="true"></i>
              <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
            <ul>

                 <div class="media_logo">
                  <a href="#">
                  <img src="https://anbar.net/assets/images/logo/minlogo.png" alt="">
                </a>
              </div>



              <!--start page controls-->
              <div class="page_controls">
                <!--start womens home page-->
                <div class="womensHome wrapperPagr">
                  <div *ngIf="!isSubMenu">
                    <h4 >{{'label.Categories' | translate}}</h4>
                      <div class="fit_panel" *ngFor="let item of menuList">
                        <span>
                          <!-- <h5 *ngIf="item.shopByBrand?.length>0"><a (click)="navigate(item)">{{item?.menuName}}</a></h5> -->
                          <h5  *ngIf="lang == 0"><a (click)="subMenus(item)">{{item?.menuName}}</a></h5>
                          <h5  *ngIf="lang == 1"><a (click)="subMenus(item)">{{item?.menuNameAr}}</a></h5>

                        </span>
                        <span>
                          <img (click)="navigate(item)" src="{{item?.imageUrl}}" alt="" />
                        </span>
                      </div>
                    </div>
                    <div *ngIf="isSubMenu">
                      <a (click)="isSubMenu=false;"><h4 class="text-right">
                        <i class="fa fa-arrow-left mobile" aria-hidden="true"></i>
                        <i class="fa fa-arrow-right ng" aria-hidden="true"></i>
                        &nbsp;{{'header.ShopByCategory' | translate}}</h4></a>
                      <div class="fit_panel" *ngFor="let item of subCategoryList">
                        <span>
                          <h5><a (click)="onMenuItemClickMobileView(item)">

                            <span *ngIf="lang ===0">
                              {{item.name}}

                            </span>
                            <span *ngIf="lang ===1">
                              {{item.nameAr}}
                            </span>
                          </a></h5>
                        </span>
                        <!-- <span>
                          <img (click)="onMenuItemClickMobileView(item)" src="" alt="" />
                        </span> -->
                      </div>
                    </div>





                </div>
                <!--end womens home page-->






                <div class="another_controls">
                  <div class="">


                    <span class="min-display">
                      <!-- <button>login</button> /

                        <button>Register</button> -->
                        <!-- <button routerLink="/vendor-registration">Vendor Registration</button> -->
                        <div class="clear"></div>
                        <button *ngIf="common?.isLoggedIn == false;" routerLink="/login" (click)="toggleClose()">Login</button>

                        <button *ngIf="common?.isLoggedIn == false;" routerLink="/signup" (click)="toggleClose()">Register</button>

                        <button *ngIf="common?.isLoggedIn == true;"  (click)="logOut();">Log Out</button>

                      </span>

                      <span>
                      <!-- <a href="#">
                <aside>1</aside>
                <img src="../../../../assets/images/icons/cart.png" alt="">
              </a> -->
            </span>
          </div>
        </div>


        <div class="another_controls side_menu">
          <a href="#"> DOWNLOAD THE APP</a>
          <div class="row margin-control">
            <div class="col-md-6 col-sm-6 col-6 padd-5">
              <div class="app">
                <a href="#" class="padd_control_pan"><img src="https://anbar.net/assets/images/icons/app1.png"
                  alt=""></a>
                </div>
              </div>

              <div class="col-md-6 col-sm-6 col-6 padd-5">
                <div class="app">
                  <a href="#" class="padd_control_pan"><img src="https://anbar.net/assets/images/icons/app2.png"
                    alt=""></a>
                  </div>
                </div>

              </div>
            </div>


            <div class="another_controls">
              <a href="#" class="text-left"> Consumer Rights </a>
            </div>

            <div class="another_controls">
              <a href="#" class="text-left"> Disclaimer </a>
            </div>


            <div class="another_controls">
              <div class="language">
                <ul style="color: black;">
                  <i><a (click)="selectLang(1)">العربية</a></i>
                  <i><a href="#" class="pipe">|</a></i>
                  <i><a (click)="selectLang(0)">English</a></i>

                  <i>
                    <!-- <a href="#"><span class="flag">
                      <aside style="background-image: url(https://anbar.net/assets/images/icons/flags.jpg);"></aside>
                    </span></a> -->
                  </i>
                  <i>
                    <!-- <a href="#" class="arow">Qatar</a> -->
                  </i>




                </ul>
              </div>
            </div>
          </div>
          <!--end page controls-->













              <li class="add_drop" style="color: white;" *ngFor="let item of menuList">
                <a *ngIf="lang == 0"  (click)="onMenuClick(item)" (mouseenter)="showSubMenu=true" >
                {{item.menuName}}</a>
                <a *ngIf="lang == 1"  (click)="onMenuClick(item)" (mouseenter)="showSubMenu=true" >
                {{item.menuNameAr}}</a>
                <div class="drop_box" *ngIf="showSubMenu">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="row panel-title">
                        <h4 >{{'header.ShopByProduct' | translate}}</h4>
                        <div class="col-md-6 min-drop_panel">
                          <ul >
                            <!-- <li> <a href="#"> View All</a></li> -->
                            <li *ngFor="let element of item?.shopByProduct">
                              <a *ngIf="lang == 0"style="cursor: pointer;" (click)="onMenuItemClick(element , item)">
                                 {{element.name}}</a>
                              <a *ngIf="lang ===1" style="cursor: pointer;" (click)="onMenuItemClick(element , item)">
                                 {{element.nameAr}}</a>

                            </li>
                          </ul>
                        </div>
                        <div class="col-md-6 min-drop_panel">
                          <!-- <ul>
                            <li><a href="#">Jack &amp; Jones</a></li>
                            <li><a href="#">Nike</a></li>
                            <li><a href="#">Lc waikiki</a></li>
                            <li><a href="#">Calvin Klein</a></li>
                            <li><a href="#">Puma</a></li>
                          </ul> -->

                        </div>

                      </div>

                    </div>


                    <div class="col-md-3 padd_none">


                      <div class="row panel-title">
                        <h4>{{'header.ShopByBrand' | translate}}</h4>
                        <div class="col-md-6 min-drop_panel">


                          <ul>

                            <li *ngFor="let element of item?.shopByBrand">
                              <a *ngIf="lang == 0" style="cursor: pointer;" (click)="onMenuItemClick(element , item)">{{element.name}}</a>
                              <a *ngIf="lang == 1" style="cursor: pointer;" (click)="onMenuItemClick(element , item)">{{element.nameAr}}</a>

                            </li>
                            <!-- <li><a href="#">R&B</a></li>
                            <li><a href="#">Levis</a></li>
                            <li><a href="#">Tommy Hilfiger</a></li>
                            <li><a href="#">Ardene</a></li> -->

                          </ul>

                        </div>



                        <div class="col-md-6 min-drop_panel">


                          <!-- <ul>
                            <li><a href="#">La Vie En Rose</a></li>
                            <li><a href="#">Adidas</a></li>
                            <li><a href="#">Vero Moda</a></li>
                            <li><a href="#">Nike</a></li>
                            <li><a href="#">Beverly Hills Polo Club</a></li>

                          </ul> -->

                        </div>

                      </div>

                    </div>


                    <div class="col-md-5">
                      <div class="row panel-title">
                        <div class="col-md-6 drop_img_cover">
                          <span><img (click)="onMenuItemClick(item?.featuredItems[0])" [src]="item?.featuredItems[0]?.imageUrl" alt="" /></span>
                          <h4 *ngIf="lang == 0">{{item?.featuredItems[0]?.name}}</h4>
                          <h4 *ngIf="lang == 1">{{item?.featuredItems[0]?.nameAr}}</h4>

                          <a (click)="onMenuItemClick(item?.featuredItems[0])" class="shop_now_in_drop">{{'button.ShopNow' | translate}}</a>
                        </div>
                        <div class="col-md-6 drop_img_cover">
                          <span><img (click)="onMenuItemClick(item?.featuredItems[1])" [src]="item?.featuredItems[1]?.imageUrl" alt="" /></span>
                          <h4 *ngIf="lang == 0">{{item?.featuredItems[1]?.name}}</h4>
                          <h4 *ngIf="lang == 1">{{item?.featuredItems[1]?.nameAr}}</h4>

                          <a  (click)="onMenuItemClick(item?.featuredItems[1])"class="shop_now_in_drop">{{'button.ShopNow' | translate}}</a>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </li>
              <li class="add_drop">
              </li>

              <!-- <li class="add_drop"><a href="#">Clothing</a>


                <div class="drop_box">
                  <div class="row">
                    <div class="col-md-4">


                      <div class="row panel-title">
                        <h4>SHOP BY PRODUCT</h4>
                        <div class="col-md-6 min-drop_panel">


                          <ul>
                            <li><a href="#"> View All</a></li>
                            <li><a href="#"> Adidas</a></li>
                            <li><a href="#"> Beverly Hills Polo Club</a></li>
                            <li><a href="#"> Tommy Hilfiger</a></li>
                            <li><a href="#"> Aeropostale</a></li>
                            <li><a href="#"> Levis</a></li>

                          </ul>

                        </div>


                        <div class="col-md-6 min-drop_panel">
                          <ul>

                            <li><a href="#">Jack &amp; Jones</a></li>
                            <li><a href="#">Nike</a></li>
                            <li><a href="#">Lc waikiki</a></li>
                            <li><a href="#">Calvin Klein</a></li>
                            <li><a href="#">Puma</a></li>
                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-3 padd_none">


                      <div class="row panel-title">
                        <h4>SHOP BY BRAND</h4>
                        <div class="col-md-6 min-drop_panel">


                          <ul>

                            <li><a href="#">LC Waikiki</a></li>
                            <li><a href="#">R&B</a></li>
                            <li><a href="#">Levis</a></li>
                            <li><a href="#">Tommy Hilfiger</a></li>
                            <li><a href="#">Ardene</a></li>

                          </ul>

                        </div>



                        <div class="col-md-6 min-drop_panel">


                          <ul>
                            <li><a href="#">La Vie En Rose</a></li>
                            <li><a href="#">Adidas</a></li>
                            <li><a href="#">Vero Moda</a></li>
                            <li><a href="#">Nike</a></li>
                            <li><a href="#">Beverly Hills Polo Club</a></li>

                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-5">
                      <div class="row panel-title">
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img1.jpg" alt="" /></span>
                          <h4>TEE TIME</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img2.jpg" alt="" /></span>
                          <h4>SPRINGS NEW MOOD</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>


              </li>
              <li class="add_drop"><a href="#">Shoes</a>


                <div class="drop_box">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="row panel-title">
                        <h4>SHOP BY PRODUCT</h4>
                        <div class="col-md-6 min-drop_panel">
                          <ul>

                            <li><a href="#">View All</a></li>
                            <li><a href="#">Sandals</a></li>
                            <li><a href="#">Pumps</a></li>
                            <li><a href="#">Sneakers</a></li>
                            <li><a href="#">Flats</a></li>

                          </ul>

                        </div>
                        <div class="col-md-6 min-drop_panel">
                          <ul>

                            <li><a href="#">Mules</a></li>
                            <li><a href="#">Comfort Shoes</a></li>
                            <li><a href="#">Wedges</a></li>
                            <li><a href="#">Flip Flops</a></li>
                            <li><a href="#">Ballerinas & Loafers</a></li>


                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-3 padd_none">


                      <div class="row panel-title">
                        <h4>SHOP BY BRAND</h4>
                        <div class="col-md-6 min-drop_panel">


                          <ul>

                            <li><a href="#">LC Waikiki</a></li>
                            <li><a href="#">R&B</a></li>
                            <li><a href="#">Levis</a></li>
                            <li><a href="#">Tommy Hilfiger</a></li>
                            <li><a href="#">Ardene</a></li>

                          </ul>

                        </div>



                        <div class="col-md-6 min-drop_panel">


                          <ul>
                            <li><a href="#">La Vie En Rose</a></li>
                            <li><a href="#">Adidas</a></li>
                            <li><a href="#">Vero Moda</a></li>
                            <li><a href="#">Nike</a></li>
                            <li><a href="#">Beverly Hills Polo Club</a></li>

                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-5">
                      <div class="row panel-title">
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img1.jpg" alt="" /></span>
                          <h4>TEE TIME</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img2.jpg" alt="" /></span>
                          <h4>SPRINGS NEW MOOD</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>


              </li>
              <li class="add_drop"><a href="#">Bags</a>


                <div class="drop_box">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="row panel-title">
                        <h4>SHOP BY PRODUCT</h4>
                        <div class="col-md-6 min-drop_panel">
                          <ul>

                            <li><a href="#">View All</a></li>
                            <li><a href="#">Sandals</a></li>
                            <li><a href="#">Pumps</a></li>
                            <li><a href="#">Sneakers</a></li>
                            <li><a href="#">Flats</a></li>

                          </ul>

                        </div>
                        <div class="col-md-6 min-drop_panel">
                          <ul>

                            <li><a href="#">Mules</a></li>
                            <li><a href="#">Comfort Shoes</a></li>
                            <li><a href="#">Wedges</a></li>
                            <li><a href="#">Flip Flops</a></li>
                            <li><a href="#">Ballerinas & Loafers</a></li>


                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-3 padd_none">


                      <div class="row panel-title">
                        <h4>SHOP BY BRAND</h4>
                        <div class="col-md-6 min-drop_panel">


                          <ul>

                            <li><a href="#">LC Waikiki</a></li>
                            <li><a href="#">R&B</a></li>
                            <li><a href="#">Levis</a></li>
                            <li><a href="#">Tommy Hilfiger</a></li>
                            <li><a href="#">Ardene</a></li>

                          </ul>

                        </div>



                        <div class="col-md-6 min-drop_panel">


                          <ul>
                            <li><a href="#">La Vie En Rose</a></li>
                            <li><a href="#">Adidas</a></li>
                            <li><a href="#">Vero Moda</a></li>
                            <li><a href="#">Nike</a></li>
                            <li><a href="#">Beverly Hills Polo Club</a></li>

                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-5">
                      <div class="row panel-title">
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img1.jpg" alt="" /></span>
                          <h4>TEE TIME</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img2.jpg" alt="" /></span>
                          <h4>SPRINGS NEW MOOD</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>


              </li>


              <li class="add_drop"><a href="#">Accessories</a>


                <div class="drop_box">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="row panel-title">
                        <h4>SHOP BY PRODUCT</h4>
                        <div class="col-md-6 min-drop_panel">
                          <ul>

                            <li><a href="#">View All</a></li>
                            <li><a href="#">Sandals</a></li>
                            <li><a href="#">Pumps</a></li>
                            <li><a href="#">Sneakers</a></li>
                            <li><a href="#">Flats</a></li>

                          </ul>

                        </div>
                        <div class="col-md-6 min-drop_panel">
                          <ul>

                            <li><a href="#">Mules</a></li>
                            <li><a href="#">Comfort Shoes</a></li>
                            <li><a href="#">Wedges</a></li>
                            <li><a href="#">Flip Flops</a></li>
                            <li><a href="#">Ballerinas & Loafers</a></li>


                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-3 padd_none">


                      <div class="row panel-title">
                        <h4>SHOP BY BRAND</h4>
                        <div class="col-md-6 min-drop_panel">


                          <ul>

                            <li><a href="#">LC Waikiki</a></li>
                            <li><a href="#">R&B</a></li>
                            <li><a href="#">Levis</a></li>
                            <li><a href="#">Tommy Hilfiger</a></li>
                            <li><a href="#">Ardene</a></li>

                          </ul>

                        </div>



                        <div class="col-md-6 min-drop_panel">


                          <ul>
                            <li><a href="#">La Vie En Rose</a></li>
                            <li><a href="#">Adidas</a></li>
                            <li><a href="#">Vero Moda</a></li>
                            <li><a href="#">Nike</a></li>
                            <li><a href="#">Beverly Hills Polo Club</a></li>

                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-5">
                      <div class="row panel-title">
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img1.jpg" alt="" /></span>
                          <h4>TEE TIME</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img2.jpg" alt="" /></span>
                          <h4>SPRINGS NEW MOOD</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>


              </li>

              <li class="add_drop"><a href="#">Beauty</a>


                <div class="drop_box">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="row panel-title">
                        <h4>SHOP BY PRODUCT</h4>
                        <div class="col-md-6 min-drop_panel">
                          <ul>

                            <li><a href="#">View All</a></li>
                            <li><a href="#">Sandals</a></li>
                            <li><a href="#">Pumps</a></li>
                            <li><a href="#">Sneakers</a></li>
                            <li><a href="#">Flats</a></li>

                          </ul>

                        </div>
                        <div class="col-md-6 min-drop_panel">
                          <ul>

                            <li><a href="#">Mules</a></li>
                            <li><a href="#">Comfort Shoes</a></li>
                            <li><a href="#">Wedges</a></li>
                            <li><a href="#">Flip Flops</a></li>
                            <li><a href="#">Ballerinas & Loafers</a></li>


                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-3 padd_none">


                      <div class="row panel-title">
                        <h4>SHOP BY BRAND</h4>
                        <div class="col-md-6 min-drop_panel">


                          <ul>

                            <li><a href="#">LC Waikiki</a></li>
                            <li><a href="#">R&B</a></li>
                            <li><a href="#">Levis</a></li>
                            <li><a href="#">Tommy Hilfiger</a></li>
                            <li><a href="#">Ardene</a></li>

                          </ul>

                        </div>



                        <div class="col-md-6 min-drop_panel">


                          <ul>
                            <li><a href="#">La Vie En Rose</a></li>
                            <li><a href="#">Adidas</a></li>
                            <li><a href="#">Vero Moda</a></li>
                            <li><a href="#">Nike</a></li>
                            <li><a href="#">Beverly Hills Polo Club</a></li>

                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-5">
                      <div class="row panel-title">
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img1.jpg" alt="" /></span>
                          <h4>TEE TIME</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img2.jpg" alt="" /></span>
                          <h4>SPRINGS NEW MOOD</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>


              </li>
              <li class="add_drop"><a href="#">Sports</a>


                <div class="drop_box">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="row panel-title">
                        <h4>SHOP BY PRODUCT</h4>
                        <div class="col-md-6 min-drop_panel">
                          <ul>

                            <li><a href="#">View All</a></li>
                            <li><a href="#">Sandals</a></li>
                            <li><a href="#">Pumps</a></li>
                            <li><a href="#">Sneakers</a></li>
                            <li><a href="#">Flats</a></li>

                          </ul>

                        </div>
                        <div class="col-md-6 min-drop_panel">
                          <ul>

                            <li><a href="#">Mules</a></li>
                            <li><a href="#">Comfort Shoes</a></li>
                            <li><a href="#">Wedges</a></li>
                            <li><a href="#">Flip Flops</a></li>
                            <li><a href="#">Ballerinas & Loafers</a></li>


                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-3 padd_none">


                      <div class="row panel-title">
                        <h4>SHOP BY BRAND</h4>
                        <div class="col-md-6 min-drop_panel">


                          <ul>

                            <li><a href="#">LC Waikiki</a></li>
                            <li><a href="#">R&B</a></li>
                            <li><a href="#">Levis</a></li>
                            <li><a href="#">Tommy Hilfiger</a></li>
                            <li><a href="#">Ardene</a></li>

                          </ul>

                        </div>



                        <div class="col-md-6 min-drop_panel">


                          <ul>
                            <li><a href="#">La Vie En Rose</a></li>
                            <li><a href="#">Adidas</a></li>
                            <li><a href="#">Vero Moda</a></li>
                            <li><a href="#">Nike</a></li>
                            <li><a href="#">Beverly Hills Polo Club</a></li>

                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-5">
                      <div class="row panel-title">
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img1.jpg" alt="" /></span>
                          <h4>TEE TIME</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img2.jpg" alt="" /></span>
                          <h4>SPRINGS NEW MOOD</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>


              </li>
              <li class="add_drop"><a href="#">Home</a>


                <div class="drop_box">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="row panel-title">
                        <h4>SHOP BY PRODUCT</h4>
                        <div class="col-md-6 min-drop_panel">
                          <ul>

                            <li><a href="#">View All</a></li>
                            <li><a href="#">Sandals</a></li>
                            <li><a href="#">Pumps</a></li>
                            <li><a href="#">Sneakers</a></li>
                            <li><a href="#">Flats</a></li>

                          </ul>

                        </div>
                        <div class="col-md-6 min-drop_panel">
                          <ul>

                            <li><a href="#">Mules</a></li>
                            <li><a href="#">Comfort Shoes</a></li>
                            <li><a href="#">Wedges</a></li>
                            <li><a href="#">Flip Flops</a></li>
                            <li><a href="#">Ballerinas & Loafers</a></li>


                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-3 padd_none">


                      <div class="row panel-title">
                        <h4>SHOP BY BRAND</h4>
                        <div class="col-md-6 min-drop_panel">


                          <ul>

                            <li><a href="#">LC Waikiki</a></li>
                            <li><a href="#">R&B</a></li>
                            <li><a href="#">Levis</a></li>
                            <li><a href="#">Tommy Hilfiger</a></li>
                            <li><a href="#">Ardene</a></li>

                          </ul>

                        </div>



                        <div class="col-md-6 min-drop_panel">


                          <ul>
                            <li><a href="#">La Vie En Rose</a></li>
                            <li><a href="#">Adidas</a></li>
                            <li><a href="#">Vero Moda</a></li>
                            <li><a href="#">Nike</a></li>
                            <li><a href="#">Beverly Hills Polo Club</a></li>

                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-5">
                      <div class="row panel-title">
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img1.jpg" alt="" /></span>
                          <h4>TEE TIME</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img2.jpg" alt="" /></span>
                          <h4>SPRINGS NEW MOOD</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>


              </li>
              <li class="add_drop"><a href="#">Outlet</a>


                <div class="drop_box">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="row panel-title">
                        <h4>SHOP BY PRODUCT</h4>
                        <div class="col-md-6 min-drop_panel">
                          <ul>

                            <li><a href="#">View All</a></li>
                            <li><a href="#">Sandals</a></li>
                            <li><a href="#">Pumps</a></li>
                            <li><a href="#">Sneakers</a></li>
                            <li><a href="#">Flats</a></li>

                          </ul>

                        </div>
                        <div class="col-md-6 min-drop_panel">
                          <ul>

                            <li><a href="#">Mules</a></li>
                            <li><a href="#">Comfort Shoes</a></li>
                            <li><a href="#">Wedges</a></li>
                            <li><a href="#">Flip Flops</a></li>
                            <li><a href="#">Ballerinas & Loafers</a></li>


                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-3 padd_none">


                      <div class="row panel-title">
                        <h4>SHOP BY BRAND</h4>
                        <div class="col-md-6 min-drop_panel">


                          <ul>

                            <li><a href="#">LC Waikiki</a></li>
                            <li><a href="#">R&B</a></li>
                            <li><a href="#">Levis</a></li>
                            <li><a href="#">Tommy Hilfiger</a></li>
                            <li><a href="#">Ardene</a></li>

                          </ul>

                        </div>



                        <div class="col-md-6 min-drop_panel">


                          <ul>
                            <li><a href="#">La Vie En Rose</a></li>
                            <li><a href="#">Adidas</a></li>
                            <li><a href="#">Vero Moda</a></li>
                            <li><a href="#">Nike</a></li>
                            <li><a href="#">Beverly Hills Polo Club</a></li>

                          </ul>

                        </div>

                      </div>

                    </div>


                    <div class="col-md-5">
                      <div class="row panel-title">
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img1.jpg" alt="" /></span>
                          <h4>TEE TIME</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                        <div class="col-md-6 drop_img_cover">
                          <span><img src="../../../../assets/images/drop/img2.jpg" alt="" /></span>
                          <h4>SPRINGS NEW MOOD</h4>
                          <a href="#" class="shop_now_in_drop">Shop Now</a>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>


              </li> -->

            </ul>

          </div>
          <!-- <div class="menu-overlay" style="display: block;"></div> -->
          <!--<div class="menu-overlay"></div>-->
        </div>
        <div class="col-md-3 col-sm-3" >
          <div class="search_box media_control">
            <div class="search_results searchselect" *ngIf="showSearchDiv" [ngClass]="{'dnone':!showSearchDiv}" (mouseenter)="clearTimerBox()" >
              <div class="search-txt" *ngIf="searchList.length == 0 && isLoading == false">
                Sorry , No Items Found !
               </div>
              <div class="row" style="width: 98%;" *ngFor="let item of searchList" (click)="navigateFromSearch(item)">

                  <div class="col-md-4" >
                    <img (click)="navigateFromSearch(item)" style="cursor: pointer; margin: 5px;" [src]="item?.productImages[0]?.imagePath" alt="" />
                  </div>

                  <div class="col-md-8">
                    

                   
                    <p style="cursor: pointer;">

                      <span class="search-head search-head-item" *ngIf="lang == 0" style="font-weight: bold; font-size: 15px;">
                        {{item?.productName}}
                      </span> 

                      <span class="search-head search-head-item" *ngIf="lang ==1"  style="font-weight: bold; font-size: 15px;">
                        {{item?.productNameAr}}
                       </span>
                       <br>




                    <span class="search-head-item" *ngIf="lang ===0">
                          {{item?.productDescription}}
                       </span>
                      <span class="search-head-item" *ngIf="lang ==1">
                          {{item?.productDescriptionAr}}
                     </span></p>

                  </div>
              </div>
            </div>
            <form>
              <span (mouseleave)="leaveSearch()">
                <span class="form-group" (click)="handleAsideClick($event)" > 
                  <input type="text" name="search" id="search" name="search" placeholder="{{'label.WhatAreYouLookingFor' | translate}}"
                  class="form-control" [(ngModel)]="searchQuery" (change)="showSearchBox()" (keyup)="onKeyUpSearch()"  autocomplete="off">
                </span>
  
                <span >
                  <button (click)="onSearchButtonClick()" ><i class="fa fa-search" aria-hidden="true"></i></button>
                </span>
  
              </span>
              
            </form>

          </div>

        </div>


      </div>
    </div>

  </div>
  <!--end nav cover-->
</header>
<!--end header-->

