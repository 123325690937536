import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common-values/common.service';
import { Config } from 'src/app/common-values/config';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-order-complete',
  templateUrl: './order-complete.component.html',
  styleUrls: ['./order-complete.component.scss']
})
export class OrderCompleteComponent implements OnInit {
  orderId : number = 0;
  orderDetails : any ;
  default_currencyformat = Config.currencyFormat;
  constructor(private route : ActivatedRoute ,
    private router : Router,
    private db : DbService , public common : CommonService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.orderId != undefined) {
        this.orderId = Number(atob(queryParams.orderId));
        this.getOrderDetails(this.orderId);
      }
      if (queryParams.basket_id != undefined) {
        localStorage.removeItem('cart');
        this.common.cartList =[]
        this.orderId = Number((queryParams.basket_id));
        this.getOrderDetails(this.orderId);
      }
    })
  }


  navigate(){
    let el =0
    this.router.navigate(['/product-list'] ,{
      queryParams: { name : 'View All'  , brandId : btoa(el.toString())  , categoryId : btoa(el.toString())  ,
        subCategoryId : btoa(el.toString())  ,
      }})
  }


  couponDiscount =  0;

  getOrderDetails(orderId){
   this.db.methodGet("order/" + this.orderId).subscribe(resdata=>{

    
    
     this.orderDetails = resdata;
     if(this.orderDetails.couponId && this.orderDetails.discountPercent > 0){
      //  this.couponDiscount = this.orderDetails.totalAmount * 
     }
   })

  }
}
