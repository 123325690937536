import { Component, OnInit } from '@angular/core';
import { Config } from 'src/app/common-values/config';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  lang =Number(Config.lang);
  constructor() { }

  ngOnInit(): void {
  }

}
