


<section class="bg-light">
  <div class="container">
    <div class="row align-items-center">
      <!-- <div class="col-md-6 col-5">
        <h1 class="h2 mb-0">Wish list</h1>
      </div> -->
      <div class="col-md-12 mt-3 mt-md-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item">
              <a class="text-dark" href="#"><i class="fa fa-home mr-1"></i>{{'menu.Home' | translate}}</a>
            </li>
            <li class="breadcrumb-item active text-primary" aria-current="page">{{'menu.Wishlist' | translate}}</li>
          </ol>

        </nav>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>



<div class="page-content">


<section>
  <div class="container">
    <div class="row mb-4 align-items-center">
      <div class="col-md-5 mb-3 mb-md-0">
         <span class="text-muted">{{'label.Showing' | translate }}
            {{wishList?.length}} {{'label.Products' | translate }}</span>
      </div>
      <div class="col-md-7 d-flex align-items-center justify-content-md-end">
        <!-- <div class="view-filter"> <a class="active" href="shop-grid-left-sidebar.html"><i class="lab la-buromobelexperte"></i></a>
              <a href="shop-list-no-sidebar.html"><i class="las la-list"></i></a>
            </div> -->
        <!-- <div class="sort-filter ml-2 d-flex align-items-center">
          <select class="custom-select" id="inputGroupSelect02">
            <option selected disabled>Sort By</option>
            <option value="1">Newest Item</option>
            <option value="2">Populer</option>
            <option value="3">Best Match</option>
          </select>
        </div> -->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-4 col-6 mb-3"  *ngFor="let item of wishList">
        <div class="card product-card">

          <button (click)="deleteWishListItem(item)" type="button" class="close btn-wishlist" aria-label="Close">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
          <a *ngIf="item?.productImages.length == 1" class="card-img-hover d-block" (click)="navigateToProductDetails(item)" style="cursor: pointer;">
            <img class="card-img-top card-img-back" [src]="item?.productImages[0]?.imagePath" alt="...">
            <img class="card-img-top card-img-front" [src]="item?.productImages[0]?.imagePath" alt="...">
          </a>
          <a *ngIf="item?.productImages.length > 1" class="card-img-hover d-block" (click)="navigateToProductDetails(item)" style="cursor: pointer;">
            <img class="card-img-top card-img-back" [src]="item?.productImages[0]?.imagePath" alt="...">
            <img class="card-img-top card-img-front" [src]="item?.productImages[1]?.imagePath" alt="...">
          </a>
          <div class="card-info">
            <div class="card-body">
              <div class="product-title"><a class="link-title" style="cursor: pointer;" (click)="navigateToProductDetails(item)">
                <span *ngIf="lang ==0">{{item?.productName}}</span>
                <span *ngIf="lang ==1">{{item?.productNameAr}}</span>

                </a>
              </div>
              <div class="mt-1">
                <!-- <span class="product-price">
                  QR {{item?.discountPrice  | number:default_currencyformat}}
                  <del class="text-muted">QR {{item?.price  | number:default_currencyformat}}</del>
                </span>
                <span> Size: {{item?.sizeOptionName}}</span> |<span class="dot" style="background-color: yellow;"></span> -->
                <span class="product-price">
                  <div class="d-flex">
                    <span>
                      QR {{item?.discountPrice | number:default_currencyformat}}
                    </span>
                    <del class="text-muted align-items-center justify-content-center">QR {{item?.price | number:default_currencyformat}}</del>
                  </div>
                  <div class="size-color">
                    <span>{{'label.Size' | translate }}: {{item?.sizeOptionName}}</span>&nbsp;|<span class="dot"  [style.background-color]="item?.colorCode"></span>
                  </div>
                </span>
                <div class="star-rating"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
                </div>
              </div>
            </div>
            <div class="card-footer bg-transparent border-0">
              <div class="product-link d-flex justify-content-center">
                <button class="btn-cart btn btn-primary btn-animated mx-3" (click)="addToCart(item)" type="button"><i class="fa fa-shopping-cart mr-1"></i>
                </button>
                <!-- <button class="btn btn-view" data-toggle="tooltip" data-placement="top" title="Quick View"><span data-target="#quick-view" data-toggle="modal"><i class="las la-eye"></i></span>
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-2 col-md-4 ">
        <div class="card product-card">
          <button class="btn-wishlist btn-sm" type="button" data-toggle="tooltip" data-placement="left" title="Add to wishlist"><i class="la la-heart"></i>
          </button>
          <a class="card-img-hover d-block" href="product-left-image.html">
            <img class="card-img-top card-img-back" src="assets/assets/images/product/02.jpg" alt="...">
            <img class="card-img-top card-img-front" src="assets/assets/images/product/02.jpg" alt="...">
          </a>
          <div class="card-info">
            <div class="card-body">
              <div class="product-title"><a class="link-title" href="product-left-image.html">Women Lather Jacket</a>
              </div>
              <div class="mt-1"> <span class="product-price"><del class="text-muted">QR 35.00</del> QR 25.00</span>
                <div class="star-rating"><i class="las la-star"></i><i class="las la-star"></i><i class="las la-star"></i><i class="las la-star"></i><i class="las la-star"></i>
                </div>
              </div>
            </div>
            <div class="card-footer bg-transparent border-0">
              <div class="product-link d-flex align-items-center justify-content-center">

                <button class="btn-cart btn btn-primary btn-animated mx-3" type="button"><i class="las la-shopping-cart mr-1"></i>
                </button>
                <button class="btn btn-view" data-toggle="tooltip" data-placement="top" title="Quick View"><span data-target="#quick-view" data-toggle="modal"><i class="las la-eye"></i></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-lg-2 col-md-4">
        <div class="card product-card">
          <button class="btn-wishlist btn-sm" type="button" data-toggle="tooltip" data-placement="left" title="Add to wishlist"><i class="la la-heart"></i>
          </button>
          <a class="card-img-hover d-block" href="product-left-image.html">
            <img class="card-img-top card-img-back" src="assets/assets/images/product/02.jpg" alt="...">
            <img class="card-img-top card-img-front" src="assets/assets/images/product/02.jpg" alt="...">
          </a>
          <div class="card-info">
            <div class="card-body">
              <div class="product-title"><a class="link-title" href="product-left-image.html">Women Lather Jacket</a>
              </div>
              <div class="mt-1"> <span class="product-price"><del class="text-muted">QR 35.00</del> QR 25.00</span>
                <div class="star-rating"><i class="las la-star"></i><i class="las la-star"></i><i class="las la-star"></i><i class="las la-star"></i><i class="las la-star"></i>
                </div>
              </div>
            </div>
            <div class="card-footer bg-transparent border-0">
              <div class="product-link d-flex align-items-center justify-content-center">

                <button class="btn-cart btn btn-primary btn-animated mx-3" type="button"><i class="las la-shopping-cart mr-1"></i>
                </button>
                <button class="btn btn-view" data-toggle="tooltip" data-placement="top" title="Quick View"><span data-target="#quick-view" data-toggle="modal"><i class="las la-eye"></i></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div class="row">
      <div class="col">
        <nav aria-label="Page navigation" class="mt-8">
          <ul class="pagination">
            <li class="page-item"><a class="page-link" >Previous</a>
            </li>
            <li class="page-item"><a class="page-link" >1</a>
            </li>
            <li class="page-item"><a class="page-link" >2</a>
            </li>
            <li class="page-item"><a class="page-link" >3</a>
            </li>
            <li class="page-item"><a class="page-link" >Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </div> -->
  </div>
</section>


<!-- Cart Modal -->
<div class="modal fade cart-modal" id="cartModal" tabindex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ModalLabel">Your Cart (2)</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <div class="row align-items-center">
            <div class="col-5 d-flex align-items-center">
              <div class="mr-4">
                <button type="submit" class="btn btn-primary btn-sm"><i class="las la-times"></i>
                </button>
              </div>
              <!-- Image -->
              <a href="product-left-image.html">
                <img class="img-fluid" src="assets/images/product/01.jpg" alt="...">
              </a>
            </div>
            <div class="col-7">
              <!-- Title -->
              <h6><a class="link-title" href="product-left-image.html">Women Lather Jacket</a></h6>
              <div class="product-meta"><span class="mr-2 text-primary">QR 25.00</span><span class="text-muted">x 1</span>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-5">
        <div>
          <div class="row align-items-center">
            <div class="col-5 d-flex align-items-center">
              <div class="mr-4">
                <button type="submit" class="btn btn-primary btn-sm"><i class="las la-times"></i>
                </button>
              </div>
              <!-- Image -->
              <a href="product-left-image.html">
                <img class="img-fluid" src="assets/images/product/13.jpg" alt="...">
              </a>
            </div>
            <div class="col-7">
              <!-- Title -->
              <h6><a class="link-title" href="product-left-image.html">Men's Brand Tshirts</a></h6>
              <div class="product-meta"><span class="mr-2 text-primary">QR 27.00</span><span class="text-muted">x 1</span>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-5">
        <div class="d-flex justify-content-between align-items-center mb-8"> <span class="text-muted">Subtotal:</span>  <span class="text-dark">QR 52.00</span>
        </div> <a href="product-cart.html" class="btn btn-primary btn-animated mr-2"><i class="las la-shopping-cart mr-1"></i>View Cart</a>
        <a href="product-checkout.html" class="btn btn-dark"><i class="las la-money-check mr-1"></i>Continue To Checkout</a>
      </div>
    </div>
  </div>
</div>

<!-- Quick View Modal -->
<div class="modal fade view-modal" id="quick-view" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header border-bottom-0 pb-0">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row align-items-center">
          <div class="col-lg-5 col-12">
            <img class="img-fluid rounded" src="assets/assets/images/product/02.jpg" alt="" />
          </div>
          <div class="col-lg-7 col-12 mt-5 mt-lg-0">
            <div class="product-details">
              <h3 class="mb-0">Women Sweater</h3>
              <div class="star-rating mb-4"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
              </div> <span class="product-price h4">QR 25.00 <del class="text-muted h6">QR 35.00</del></span>
              <ul class="list-unstyled my-4">
                <li class="mb-2">Availibility: <span class="text-muted"> In Stock</span>
                </li>
                <li>Categories :<span class="text-muted"> Women's</span>
                </li>
              </ul>
              <p class="mb-4">Nulla eget sem vitae eros pharetra viverra Nam vitae luctus ligula suscipit risus nec eleifend Pellentesque eu quam sem, ac malesuada</p>
              <div class="d-sm-flex align-items-center mb-5">
                <div class="d-flex align-items-center mr-sm-4">
                  <button class="btn-product btn-product-up"> <i class="fa fa-minus"></i>
                  </button>
                  <input class="form-product" type="number" name="form-product" value="1">
                  <button class="btn-product btn-product-down"> <i class="fa fa-plus"></i>
                  </button>
                </div>
                <select class="custom-select mt-3 mt-sm-0" id="inputGroupSelect01">
                  <option selected>Size</option>
                  <option value="1">XS</option>
                  <option value="2">S</option>
                  <option value="3">M</option>
                  <option value="3">L</option>
                  <option value="3">XL</option>
                  <option value="3">XXL</option>
                </select>
                <div class="d-flex text-center ml-sm-4 mt-3 mt-sm-0">
                  <div class="form-check pl-0 mr-3">
                    <input type="radio" class="form-check-input" id="color-filter01" name="Radios">
                    <label class="form-check-label" for="color-filter01" data-bg-color="#3cb371"></label>
                  </div>
                  <div class="form-check pl-0 mr-3">
                    <input type="radio" class="form-check-input" id="color-filter02" name="Radios" checked>
                    <label class="form-check-label" for="color-filter02" data-bg-color="#4876ff"></label>
                  </div>
                  <div class="form-check pl-0 mr-3">
                    <input type="radio" class="form-check-input" id="color-filter03" name="Radios">
                    <label class="form-check-label" for="color-filter03" data-bg-color="#0a1b2b"></label>
                  </div>
                  <div class="form-check pl-0">
                    <input type="radio" class="form-check-input" id="color-filter04" name="Radios">
                    <label class="form-check-label" for="color-filter04" data-bg-color="#f94f15"></label>
                  </div>
                </div>
              </div>
              <div class="d-sm-flex align-items-center mt-5">
                <button class="btn btn-primary btn-animated mr-sm-4 mb-3 mb-sm-0"><i class="las la-shopping-cart mr-1"></i>Add To Cart</button>
                <a class="btn btn-animated btn-dark" > <i class="lar la-heart mr-1"></i>Add To Wishlist</a>
              </div>
              <div class="d-sm-flex align-items-center border-top pt-4 mt-5">
                <h6 class="mb-sm-0 mr-sm-4">Share It:</h6>
                <ul class="list-inline">
                  <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2" ><i class="la la-facebook"></i></a>
                  </li>
                  <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2" ><i class="la la-dribbble"></i></a>
                  </li>
                  <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2" ><i class="la la-instagram"></i></a>
                  </li>
                  <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2" ><i class="la la-twitter"></i></a>
                  </li>
                  <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2" ><i class="la la-linkedin"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>













