<section class="bg-light arabic-top" style="">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6">
        <h2 class="h2 mb-0">{{'header.MyAccount' | translate}}</h2>
      </div>

    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>


<div class="page-content" style="margin-top: 2%;">

  <div class="container my-account-bottom">
    <div class="row ">
      <div class="tabordion order-height">
        <!-- <section id="section1">
          <input type="radio" name="sections" id="option1" checked>
          <label for="option1">My Account</label>
          <article>
            <h2>My Account</h2>
          </article>
        </section> -->
        <section id="section1">
          <input type="radio" name="sections" id="option1" checked>
          <label for="option1">{{'header.OrderHistory' | translate}}</label>
          <article>
            <h2>{{'header.OrderHistory' | translate}}</h2>
            <div class="col-md-8">

              <!-- list section starts -->
              <div *ngIf="!showDetails">
              <div  class="row " *ngFor="let item of historyList">

                <div class="col-md-12">
                  <a class="items" (click)="onOrderSelect(item)">
                    <h5 class="orderid">{{'label.OrderID' | translate}} : <a  style="cursor: pointer;" >{{item?.billNo}}</a>  </h5>
                  </a>
                  <a class="edit-btn"> {{'label.Status' | translate}} |  {{item?.orderStatus}}</a><br />




                  <a class="edit-btn"></a>
                </div><br />
                <div class="col-md-3 order-item">

                  <img src="./assets/images/img1.jpg" class="order-img" style="" />
                  <div class="left-cart-image" style="margin:0px; padding:0px;">

                    <ul class="" style="list-style: none;">
                      <li class=""><span> <b> QR : {{item?.totalAmount}}</b></span>
                      </li>


                      <li class="">{{'label.Item' | translate}} : 1
                      </li>
                      <li class="">{{'label.OrderPlaced' | translate}} : {{item?.date | date :'dd-MM-yyyy'}}
                      </li>
                      <!-- <li class=""><span> BLUE |</span> <span> Size : XXL / XL / M / S </span>
                      </li> -->

                    </ul>

                  </div>
                </div>

                  <div class="border-bottm"></div><div class="border-bottm"></div>
              </div>
            </div>
              <!-- list section ends -->

<!-- order details section -->
              <div *ngIf="showDetails">
                <div class="row">

                  <div class="col-md-12">
                    <a class="items" (click)="onBackClick()" style="cursor: pointer;">
                      <h5 class="orderid"><img src="./assets/images/back.png" style="width:15px; height:15px;" /> {{'label.OrderID' | translate}}  : {{selectedMainData?.billNo}}</h5>
                    </a>
                    <a class="edit-btn"> {{'label.Status' | translate}} |  {{selectedMainData?.orderStatus}}</a><br />
                    <a class="edit-btns">  {{'label.OrderPlacedOn' | translate}} : {{selectedMainData?.date | date: 'dd-MM-yyyy'}}</a>

                  </div>

                  <div class="row" *ngFor="let item of selectedOrderDetails">
                    <div class="col-md-3">
                      <img src="./assets/images/img1.jpg" class="order-imge"/>

                    </div>
                    <div class="col-md-3">
                      <div class="left-cart-images" style="margin:0px; padding:0px;">
                        <ul class="" style="list-style: none; ">
                          <li class=""><span> <b>
                             {{item?.productName}}
                             </b></span>
                          </li>
                          <li class=""><span> {{item?.colorName}} |</span> <span> {{'label.Size' | translate}} : {{item?.sizeOptionName}} </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>


                </div>
                <div class="row">



                  <div class="col-md-8">

                    <ul class="list-unstyled list-cart" style="">

                      <li><span> <strong>{{'label.Subtotal' | translate}} </strong></span>
                      </li>
                      <li><span> <strong>{{'label.Shipping' | translate}} </strong></span>
                      </li>
                      <li><span> <strong>{{'label.Subtotal' | translate}} </strong></span>
                      </li>
                      <li><span><strong> {{'label.CashOnDelivery' | translate}} </strong></span>

                      </li>
                      <li><span> <b> {{Total}}</b> (Taxes Included) </span>

                      </li>
                    </ul>
                  </div>
                  <div class="col-md-4">

                    <ul class=" list-style-right list-style-delivery" style="">

                      <li><span><strong class="cart-total"> </strong></span>  {{selectedMainData?.totalAmount}}
                      </li>
                      <li><span><strong class="cart-total"> </strong></span> QR 0.00
                      </li>
                      <li><span><strong class="cart-total"> </strong></span> QR {{selectedMainData?.totalAmount}}
                      </li>
                      <li><span><strong class="cart-total"> </strong></span>
                      </li>
                      <li><span><strong class="cart-total"> </strong></span> QR 0.00
                      </li>
                      <li><span><strong class="cart-total"> </strong></span> QR {{selectedMainData?.totalAmount}}
                      </li>
                    </ul>
                  </div>
                  <div class="border"></div>

                  <div class="col-md-8">

                    <ul class="list-unstyled list-cart" style="">

                      <li><span> <b> {{'label.DeliveringTo' | translate}}</b>
                        </span>
                      </li>
                      <p> {{selectedMainData?.address.name}} <br />
                        {{selectedMainData?.address.address1}}<br />
                        {{selectedMainData?.address.mobileNumber}}
                      </p>
                    </ul>
                  </div>

                  <div class="border"></div>
                  <div class="col-md-8">

                    <ul class="list-unstyled list-cart" style="">

                      <li><span> <b> {{'label.PaymentMethod' | translate}}</b>
                        </span>
                      </li>
                      <p> {{'label.CashOnDelivery' | translate}}</p>
                    </ul>
                  </div>





                </div>
              </div>


<!--  order details section ends-->

            </div>

          </article>
        </section>
        <section id="section3" >
          <input (change)="onLogOut()" type="radio" name="sections" id="option3">
          <label for="option3">{{'label.LogOut' | translate}}</label>
          <article>

          </article>
        </section>
        <!-- <section id="section4">
          <input type="radio" name="sections" id="option4">
          <label for="option4">My Wishlist</label>
          <article>
          </article>
        </section> -->


      </div>
    </div>
  </div>
</div>
