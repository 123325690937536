import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DbService } from 'src/app/services/db.service';
declare function showNotification(type : string,titile : string,message :string): any;
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  signUpForm : FormGroup;
  submitted : boolean = false;
  mode : number = 0;  // 0 for email and 1 for mobile
  genderList =[
    {id : 1 , name :'Male'},
    {id : 2 , name :'Female'},
    {id : 3 , name :'Prefer not to say'},


  ]
  constructor(private fb : FormBuilder , private db : DbService ,
    private spinner : NgxSpinnerService,
    private router: Router) { }

  ngOnInit(): void {
    this.buildForm();
  }


  buildForm(){
    this.signUpForm=this.fb.group({
      UserFullName :["",Validators.required],
      UserName :["",[Validators.required,Validators.pattern(/^\S*$/), Validators.minLength(4)]],
      Password :["",Validators.required],
      ConfirmPassword:["",Validators.required],
      EmailOrMobile:["",Validators.required],
      Email :["",[Validators.email]],
      Mobile :[""],
      GenderId :["",Validators.required],
      Agree :[false, Validators.required]
    })
  }

 get f() { return this.signUpForm.controls };
  onSubmit(){
    this.submitted = true;


    this.f.UserFullName.setValue(this.f.UserName.value);
    if(this.signUpForm.invalid){
      return;
    }

    if((this.f.EmailOrMobile.value).includes('@')){
      this.mode = 1;
      this.f.Email.setValue(this.f.EmailOrMobile.value);
      this.f.Mobile.setValue("");
    }
    else{
      this.mode = 0;
      this.f.Mobile.setValue(this.f.EmailOrMobile.value);
      this.f.Email.setValue("");
    }

    if(this.f.Password.value !== this.f.ConfirmPassword.value){
      showNotification('error','Failed','passwords not match !');
      return;
    }
    if(this.f.Agree.value !== true){
      // alert('you must agree our terms and policies');
      return;
    }




    this.spinner.show();
    
    this.db.methodPost("Common/RegisterUser", this.signUpForm.value).subscribe(resdata =>{
      this.spinner.hide();
      if(resdata.userId > 0){
          showNotification('success','Success','Successfully registered. you can login now');
          this.router.navigate(['/login']);
          //new changes for verification disable
        // if(this.mode == 0){
        //   showNotification('success','Success','OTP sent your registered email . please verify.');
        // }
        // if(this.mode == 1){
        //   showNotification('success','Success','OTP sent your registered email . please verify.');
        // }
        // this.router.navigate(['/verify-customer'] ,{
        //   queryParams: { mode : btoa(this.mode.toString())  , customer : btoa((resdata.userId).toString())  ,
        //     userName : btoa(this.f.EmailOrMobile.value).toString()
        //   }})      
        
        
        }
      else{
        showNotification('error','Failed','Failed !');
      }
    },
     error => {
       this.spinner.hide();
    showNotification('error','Failed',error.error.message);
     }
    )
  }

}
