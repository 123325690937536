import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from 'src/app/common-values/config';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit {
  showSearchDiv : boolean = false;
  searchQuery =""
  lang = Number(Config.lang)
  searchList =[]
  isLoading : boolean =false;
  constructor(private db : DbService , private router : Router) { }


  ngOnInit(): void {
  }

  navigateFromSearch(element){
    document.getElementById("search-box").style.display = "none";
    this.router.navigate(['/product-details'] ,{
      queryParams: { productName : element.productName  , productId : btoa(element.productId)} });
    this.showSearchDiv=false;
    this.searchQuery = '';
  }



  onSearchCloseMobile(){
    document.getElementById('body').classList.remove('scron_none');
    document.getElementById("search-box").style.display = "none";

  }
  onKeyUpSearch(){
    if(this.searchQuery.length > 0){
      this.showSearchDiv = true;
      this.searchList =[]
      let body = {
         ProductName : this.searchQuery
      }
      this.isLoading  = true;
      this.db.methodPost("Common/SearchProductByName" , body).subscribe(resdata =>{
        this.isLoading = false;
        this.searchList = resdata;
      })
    }
    else{
      this.showSearchDiv = false;
    }

  }
}
