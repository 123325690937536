import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { textChangeRangeIsUnchanged } from 'typescript';
import { threadId } from 'worker_threads';
import { CommonService } from './common-values/common.service';
import { Config } from './common-values/config';
import { FacebookService, InitParams } from 'ngx-facebook';
import { NgxSpinnerService } from 'ngx-spinner';
import { DbService } from './services/db.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'women-fashion-store';
  user ;
  isShow : boolean= false;

  url : string = 'https://payments.pay2m.com/Ecommerce/api/Transaction/PostTransaction';

  orderData= {
    merchant_id : '1010',
    merchant_name : 'arshad',
    token : 'k5XMRoHNPCfzgtsBCU1_AJoNHm-cOmRbRvZUYO0x-xY',
    successUrl : 'http://localhost:4200/#/success',
    failureUrl:'http://localhost:4200/#/failed',
    checkoutUrl :'https://anbar.net/',
    transactionAmount :'100',
    basketId : '93933',
    mobile :'9605855957',
    email : 'arshadntd@gmail.com',
  }

  constructor(private translate : TranslateService , private common : CommonService,private router : Router ,
    private http : DbService,
    private spinner : NgxSpinnerService,
    private facebookService: FacebookService,
    private route: ActivatedRoute){

      var a = [{
        id: 1,
        name: "First Name",
        description: ""
      },{
        id: 2,
        name: "Second Name",
        description: ""
      }]
     
      const result = a.map(({id, name})=>({id, name}))



      console.log(result);
      this.initFacebookService();

    this.route.params.subscribe(resdata =>{
    })

    translate.setDefaultLang('en');
     this.user = JSON.parse(localStorage.getItem('currentUser'))
     if(localStorage.getItem('cart')){
      this.common.cartList = JSON.parse(localStorage.getItem('cart'))
     }

    if(localStorage.getItem('currentUser')){
      this.common.isLoggedIn = true;
      this.common.userDetails = JSON.parse(localStorage.getItem('currentUser'))
    }

   this.langSet();

  //  setTimeout(() => this.callFucntion(), 100); //

  }






 

  
  onSubmit() {

    this.http.getApi("https://anbar.clevericsolutions.com/webapi/api/Payments/AccessToken").subscribe(resdata =>{
      console.log(resdata);

    })
    // let data = {
    //   MERCHANT_ID :'',
    //   MERCHANT_NAME :'',
    //   TOKEN :'',
    //   PROCCODE :'',
    //   TXNAMT :'',
    //   CUSTOMER_MOBILE_NO :'',
    //   CUSTOMER_EMAIL_ADDRESS : '',
    //   SIGNATURE :'',
    //   VERSION :'',
    //   TXNDESC :'',
    //   SUCCESS_URL :'',
    //   FAILURE_URL :'',
    //   BASKET_ID :'',
    //   ORDER_DATE : '2018-01-01',
    //   CHECKOUT_URL :'',
    //   STORE_ID :'',
    //   // CURRENCY_CODE
    // }

  
  }

  private initFacebookService(): void {
    const initParams: InitParams = { xfbml:true, version:'v3.2'};
    this.facebookService.init(initParams);
  }


  langSet(){
    if(localStorage.getItem('lang')){
      if(localStorage.getItem('lang') == '0'){
        Config.lang = "0";
        this.translate.use('en')
      }
      else if(localStorage.getItem('lang') == '1'){
        Config.lang = "1";
        this.translate.use('ar')
      }
    }
    else{
      this.translate.use('en');
      Config.lang = "0";
      localStorage.setItem('lang','0')
    }

    this.addStyleSheet(Config.lang);
  }

  addStyleSheet(lang) {

    if(lang == "0"){
      document.getElementById('link1').setAttribute('href', './assets/css/bootstrap.min.css');
      document.getElementById('link2').setAttribute('href', './assets/css/style.css');
      document.getElementById('link3').setAttribute('href', './assets/css/responsive.css');
      document.getElementById('link4').setAttribute('href', '../assets/css/tabs.css');
      //document.getElementById('link5').setAttribute('href', './assets/css/font-awesome.min.css');//
      document.getElementById('link6').setAttribute('href', './assets/assets/css/theme-plugin.css');
     document.getElementById('link7').setAttribute('href', './assets/assets/css/theme.min.css')
     this.isShow = true;
    }
    else{
      document.getElementById('link1').setAttribute('href', './assets/css/ar/bootstrap.min.css');
      document.getElementById('link2').setAttribute('href', './assets/css/ar/style.css');
      document.getElementById('link3').setAttribute('href', './assets/css/ar/responsive.css');
      document.getElementById('link4').setAttribute('href', '../assets/css/ar/tabs.css');
     // document.getElementById('link5').setAttribute('href', './assets/css/ar/font-awesome.min.css');//
      document.getElementById('link6').setAttribute('href', './assets/assets/css/ar/theme-plugin.css');
    document.getElementById('link7').setAttribute('href', './assets/assets/css/ar/theme.min.css')
   // this.isShow = true;
   setTimeout(() => {
    this.isShow = true;
 }, 1000);
  }
 //  var myobj = document.getElementById("link1");
    //  myobj.remove();
    //  var myobj = document.getElementById("link2");
    //  myobj.remove();
    //  var myobj = document.getElementById("link3");
    //  myobj.remove();
    //  var myobj = document.getElementById("link4");
    //  myobj.remove();
    //  var myobj = document.getElementById("link5");
    //  myobj.remove();
    //  var myobj = document.getElementById("link6");
    //  myobj.remove();
    //  var myobj = document.getElementById("link7");
    //  myobj.remove();
    //  var headID = document.getElementsByTagName('head')[0];
    //  var link = document.createElement('link');
    //  //link.type = 'text/css';
    //  link.rel = 'stylesheet';
    // // link.id = 'widget_styles';
    //  headID.appendChild(link);
    //  link.href = './assets/css/ar/bootstrap.min.css';


  //    var link = document.createElement('link');
  //    link.type = 'text/css';
  //    link.rel = 'stylesheet';
  //  //  link.id = 'widget_styles';
  //    headID.appendChild(link);
  //    link.href = '../assets/css/ar/style.css';


  //    var link = document.createElement('link');
  //    link.type = 'text/css';
  //    link.rel = 'stylesheet';
  //  //  link.id = 'widget_styles';
  //    headID.appendChild(link);
  //    link.href = '../assets/css/ar/responsive.css';


  //    var link = document.createElement('link');
  //    link.type = 'text/css';
  //    link.rel = 'stylesheet';
  //  //  link.id = 'widget_styles';
  //    headID.appendChild(link);
  //    link.href = '../assets/css/ar/tabs.css';



  //    var link = document.createElement('link');
  //    link.type = 'text/css';
  //    link.rel = 'stylesheet';
  //  //  link.id = 'widget_styles';
  //    headID.appendChild(link);
  //    link.href = '../assets/css/ar/font-awesome.min.css';



   //   var link = document.createElement('link');
   // //  link.type = 'text/css';
   //   link.rel = 'stylesheet';
   // //  link.id = 'widget_styles';
   //   headID.appendChild(link);
   //   link.href = './assets/assets/css/ar/theme-plugin.css';



   //   var link = document.createElement('link');
   //  // link.type = 'text/css';
   //   link.rel = 'stylesheet';
   // //  link.id = 'widget_styles';
   //   headID.appendChild(link);
   //   link.href = './assets/assets/css/ar/theme.min.css';
  //    setTimeout(() => {
  //      this.isLoaded = true;
  //  }, 500);
  //    this.isLoaded = true;

   }

}
