<!-- Quick View Modal -->
<div class="modal fade view-modal" id="quick-view" tabindex="-1" role="dialog" aria-hidden="true" style="margin-bottom: 90px;">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header border-bottom-0 pb-0">
        <button type="button" id="closeId" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePopup()"> <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
         <div class="row">

              <!-------------new style ----------------->

              <div class="col-lg-5">

                <div class="quick-view-product-image">
                  <img [src]="imagePath"/>

                </div>
                <div class="main">


                  <div class="containers flex"  >
                    <span *ngFor="let item of selectedProductDetails?.productImages">
                      <div class="item-product-quick-view flex-item" (mouseenter)="imagePath = item.imagePath">
                        <img src="{{item.imagePath}}"/>
                      </div>
                    </span>
                    <!-- <div class="item flex-item">
                      <img src="https://anbar.clevericsolutions.com/webapi/Images/Products/913b1ca6-2f87-42ea-b133-c5ff70805d41.jpg"/>

                    </div>
                    <div class="item flex-item">
                      <img src="https://anbar.clevericsolutions.com/webapi/Images/Products/913b1ca6-2f87-42ea-b133-c5ff70805d41.jpg"/>
                    </div>
                    <div class="item flex-item">
                      <img src="https://anbar.clevericsolutions.com/webapi/Images/Products/913b1ca6-2f87-42ea-b133-c5ff70805d41.jpg"/>
                    </div>
                    <div class="item flex-item">
                      <img src="https://anbar.clevericsolutions.com/webapi/Images/Products/913b1ca6-2f87-42ea-b133-c5ff70805d41.jpg"/>
                    </div>
                    <div class="item flex-item">
                      <img src="https://anbar.clevericsolutions.com/webapi/Images/Products/913b1ca6-2f87-42ea-b133-c5ff70805d41.jpg"/>
                    </div> -->


                  </div>
                </div>
                </div>



             <!-------------new style ----------------->

            <!---<div class="col-lg-6 col-12">




              <div class="container_zoom">
                <div class="exzoom hidden" id="exzoom">
                  <div class="exzoom_img_box">
                    <ul class='exzoom_img_ul'>
                      <li *ngFor="let item of this.selectedProductDetails?.productImages"><img src="{{item.imagePath}}"/></li>
                    </ul>
                  </div>
                  <div class="exzoom_nav"></div>
                </div>
              </div>
            </div>-->
            <div class="col-lg-7 col-12 mt-5 mt-lg-0">
              <div class="product-details-quick-view">
                <h3 class="mb-0 chk-cart">



                   <span *ngIf="lang ==0">
                    {{selectedProductDetails?.productName}}

                  </span>
                   <span *ngIf="lang ==1">
                    {{selectedProductDetails?.productNameAr}}
</span>


                </h3>

                <p class="mb-1">

                  <span *ngIf="lang ==0">
                    {{selectedProductDetails?.productDescription}}

                  </span>
                  <span *ngIf="lang ==1">
                    {{selectedProductDetails?.productDescriptionAr}}

                  </span>


                </p>

                <ngb-rating [max]="5" [(rate)]="productRating" [readonly]="true" ></ngb-rating>

                <!-- <div class="star-rating mb-1">
                  <i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
                </div> -->









                <div class="clear"></div>
                <div>

              <span class="product-price h6" *ngIf="selectedStock.stockId != undefined">

                <span *ngIf="selectedStock?.discountPercent > 0">
                  QR {{selectedStock?.discountPrice | number:default_currencyformat}}
                  <del class="text-muted h6">QR {{selectedStock?.price | number:default_currencyformat}} </del>
                </span>

                <span *ngIf="selectedStock?.discountPercent == 0">
                  QR {{selectedStock?.price | number:default_currencyformat}}
                </span>

              </span>
              <!-- <span class="product-price h6" *ngIf="selectedStock.stockId == undefined">
                <span *ngIf="selectedProductDetails?.discount > 0">
                  QR {{selectedProductDetails?.discountPrice | number:default_currencyformat}}
                  <del class="text-muted h6">QR {{selectedProductDetails?.price | number:default_currencyformat}}  </del>
                </span>

                <span *ngIf="selectedProductDetails?.discount == 0">
                  QR {{selectedProductDetails?.price | number:default_currencyformat}}
                </span>


              </span> -->
                </div>


                <div>
                  <div class="clear"></div>
                  <div class="text-center product-price">
                    <span *ngFor="let item of selectedProductDetails?.colors; let i=index;" class="pro_details-1">
                    <label class="radio radio-gradient " >
                      <span class="radio__input " >
                        <input type="radio" name="radio" class="" [value]="item?.colorId" [(ngModel)]="selectedColor" (change)="onRadioColorChange(item,2)">
                        <span class="radio__control"  [style.background-color]="item?.colorCode"></span>
                      </span>
                      <span class="radio__label"></span>

                    </label>
                  </span>
                  </div>
                </div>

                <div class="clear"></div>
                <select class="custom-select col-lg-3 col-md-12" *ngIf="sizeOptionsList.length > 0" id="inputGroupSelect01" [(ngModel)]="selectedSizeMaster" >
                  <!-- <option disabled value="0">Size</option> -->
                  <option  [value]="item?.id" *ngFor="let item of sizeMasterList">{{item.name}}</option>
                </select>


              <select *ngIf="sizeOptionsList.length > 0" class="custom-select col-lg-3 col-md-12" id="inputGroupSelect01" [(ngModel)]="selectedSizeOption" (change)="onRadioSizeChange()">
                <option disabled value="0">{{'label.Size' | translate}}</option>
                <option [value]="item?.sizeOptionId" *ngFor="let item of sizeOptionsList">

                  <span *ngIf="selectedSizeMaster == 0"> {{item?.eUsizeOption}} </span>
                  <span *ngIf="selectedSizeMaster == 1"> {{item?.uKsizeOption}} </span>
                  <span *ngIf="selectedSizeMaster == 2"> {{item?.uSsizeOption}} </span>


                </option>
              </select>




              <div style="clear: both;"></div>

            <div class="col-md-12" style="margin-top: 8px;">

              <div class="row">
              <div class=" align-items-center">
                <div class="align-items-center">
                  <button class="btn-product btn-product-up" (click)="onQtyChange(1)"> <i class="fa fa-minus"></i>
                  </button>
                  <input class="form-product" type="number" readonly [(ngModel)]="selectedQty"  >
                  <button class="btn-product btn-product-down" (click)="onQtyChange(2)"> <i class="fa fa-plus"></i>
                  </button>
                </div>
                <div>

                </div>
                   </div>

                   <!-- <div class="row" style="margin:10px 5px">
                <div class="align-items-center">
                <img class="ruler" src="https://anbar.net/assets/images/icons/ruler.svg" alt="">
                  <a  style="text-decoration: underline; cursor: pointer; font-size:13px;" data-toggle="modal" data-target="#sizechart">
                  {{'label.SizeGuide' | translate}}</a>
                 </div>
                  </div> -->

                </div>




                <!--  <div class="d-sm-flex align-items-center ">
                <span class="label right-arabic-color">{{'label.Color' | translate}}  &nbsp;</span>
                <div class="form-check  list-color" *ngFor="let item of selectedProductDetails?.colors; let i=index;">
                  <input  [(ngModel)]="selectedColor" (change)="onRadioColorChange()" type="radio"  [value]="item?.colorId"  class="form-check-input"  id="color-filter{{i}}" name="Radios">
                  <label class="form-check-label" for="color-filter{{i}}">
                    <div  [style.background-color]="item?.colorCode" ></div>
                  </label>
                </div>
              </div>-->












              </div>



              <div style="clear: both;"></div>

          <div class="row" style="margin: 10px 0px 10px 0px;">


            <ul class="list-unstyled ">
              <li><span class="label">{{'label.Availability' | translate}}:</span>

                <span class="text-muted" *ngIf="outOfStock == false;">  {{'label.InStock' | translate}}</span>
                <span class="text-muted" style="color: red !important" *ngIf="outOfStock == true;"> {{'label.OutOfStock' | translate}}</span>
              </li>
              <li>
                <a *ngIf="outOfStock && common.isLoggedIn == true" _ngcontent-vuo-c42="" (click)="notifyMe();" style="color: #ff0000; cursor: pointer; text-decoration: underline; margin-top: -10px;"> {{'desc.NotifyMe' | translate}}</a>

                <a *ngIf="outOfStock && common.isLoggedIn != true" _ngcontent-vuo-c42="" data-toggle="modal" data-target="#notifyMe" style="color: #ff0000; cursor: pointer; text-decoration: underline; margin-top: -10px;"> {{'desc.NotifyMe' | translate}}</a>
              </li>
              <li><span class="label">{{'label.Categories' | translate}} :</span><span class="text-muted"> {{'label.Women' | translate}}

              </span>
              </li>
              <li>
                <p class="mb-4">


                  <span *ngIf="lang ==0">
                    {{selectedProductDetails?.productDescription}}

                  </span>
                  <span *ngIf="lang ==1">
                    {{selectedProductDetails?.productDescriptionAr}}

                  </span>

                </p>

              </li>
            </ul>



            <div>

    </div>






          </div>





      <div class="d-sm-flex align-items-center quickviewsizebutton">
        <button class="btn btn-primary btn-animated  btn-align" (click)="addToCart()">
          <i class="fa fa-shopping-cart mr-1" ></i>&nbsp; {{'button.AddToCart' | translate}}</button>
        <a class="btn btn-animated btn-dark btn-details"  (click)="addWishList()" style="color: rgb(255, 255, 255) !important;">
          <i class="fa fa-heart mr-1" style="color: rgb(255, 255, 255) !important;"></i> {{'button.AddToWishList' | translate}}
        </a>
      </div>
      <!-- <div class="d-flex align-items-center  quickviewsize" style="margin-top: 10px;">
        <h6 class="mb-0 mr-4">  {{'label.ShareIt' | translate}}:</h6>
        <ul class="list-inline">
          <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2" ><i class="fa fa-facebook"></i></a>
          </li>
          <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2" ><i class="fa fa-dribbble"></i></a>
          </li>
          <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2" ><i class="fa fa-instagram"></i></a>
          </li>
          <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2" ><i class="fa fa-twitter"></i></a>
          </li>
          <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2" ><i class="fa fa-linkedin"></i></a>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</div>




</div>
</div>
</div>
</div>
</div>
<div id="sizechart" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <img src="https://anbar.net/assets/images/chart.png" class="img-responsive">
      </div>
      <div class="modal-footer">
        <button  type="button" class="btn btn-primary btn-md" data-dismiss="modal">OK</button>
      </div>

    </div>
  </div>
</div>




<div id="notifyMe" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="notify-div">
   <div class="modal-dialog">
     <div class="modal-content">
       <div class="modal-body">
         <h3 class="text-center mb-3 text-uppercase">{{'button.NotifyMe' | translate}}</h3>
         <div class="notify-close">
         <span (click)="popUpClose()" _ngcontent-ins-c62="" aria-hidden="true">×</span></div>


         <form id="contact-form" [formGroup]="notifyForm" (ngSubmit)="onNotifySubmit()" >
           <div class="messages"></div>
           <div class="form-group">
             <label>{{'label.Name' | translate}} <span class="text-danger">*</span></label>
             <input id="form_password" type="text" name="password" formControlName="Name"   class="form-control" placeholder="{{'label.Name' | translate}}" data-error="password is required.">
             <div class="help-block with-errors">
               <div class="form-error"
               *ngIf="(f.Name.touched && f.Name.errors) || (f.Name.dirty && f.Name.errors) ||  (submitted && f.Name.errors)">
               <div *ngIf="f.Name.errors.required">{{'errors.Required' | translate}}</div>
             </div>
             </div>
           </div>
           <div class="form-group">
             <label>{{'label.EmailorPhone' | translate}} <span class="text-danger">*</span></label>
             <input id="form_name" type="text" name="name" formControlName="EmailOrPhone"  class="form-control" placeholder="{{'label.EmailorPhone' | translate}}" data-error="Username is required.">
             <div class="help-block with-errors">
               <div class="form-error"
               *ngIf="(f.EmailOrPhone.touched && f.EmailOrPhone.errors) || (f.EmailOrPhone.dirty && f.EmailOrPhone.errors) ||  (submitted && f.EmailOrPhone.errors)">
               <div *ngIf="f.EmailOrPhone.errors.required">{{'errors.Required' | translate}} </div>
             </div>
             </div>
           </div>




         </form>
         <!-- <span>You will be notified when its avaiable.</span> -->
       </div>
       <div class="modal-footer">
         <a (click)="onNotifySubmit()"  class="btn btn-primary btn-animated" style="color: white;">
           {{'button.NotifyMe' | translate}}</a>

         <button type="button" id="close-button" style="display: none;" class="btn btn-primary btn-md" data-dismiss="modal">OK</button>
       </div>

     </div>
   </div>

 </div>
 </div>
