<section class="bg-light">
    <div class="container">
      <div class="row align-items-center">
        <!-- <div class="col-md-6 col-4">
          <h1 class="h2 mb-0">Login</h1>
        </div> -->
        <div class="col-md-12 mt-3 mt-md-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent p-0 m-0">
              <li class="breadcrumb-item"><a class="text-dark" href="#"><i class="fa fa-home mr-1"></i>{{'menu.Home' | translate}}</a>
              </li>
              <li class="breadcrumb-item active text-primary" aria-current="page">{{'menu.VerifyCustomer' | translate}}</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
    </section>



    <div class="page-content">

    <!--login start-->

    <section>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div class="shadow p-3">

            <!-- <h3 class="text-center mb-3 text-uppercase">Verify </h3> -->
            <form id="contact-form" [formGroup]="signInForm" (ngSubmit)="onSubmit()">
              <div class="messages"></div>
              <div class="form-group">
                <!-- <label>OTP <span class="text-danger">*</span></label> -->

                <!-- <label>please enter otp sent to your registered <span *ngIf="mode == 0">email</span> <span *ngIf="mode == 1">mobile</span> <span class="text-danger">*</span></label> -->
                <input id="form_name" type="mobile" name="name" maxlength="6" formControlName="Otp" class="form-control" placeholder="OTP" data-error="Otp is required.">
                <!-- <div class="help-block with-errors">
                  <div class="form-error"
                  *ngIf="(f.UserName.touched && f.UserName.errors) || (f.UserName.dirty && f.UserName.errors) ||  (submitted && f.UserName.errors)">
                  <div *ngIf="f.UserName.errors.required">{{'errors.UserNameIsRequired' | translate}} </div>
                </div>
                </div> -->
              </div>

              <div class="form-group mt-4 mb-3">
                <div class="remember-checkbox d-flex justify-content-between">
                  <div class="custom-control custom-checkbox mb-2">
                    <input type="checkbox" class="custom-control-input" id="check2" name="check2">
                    <!-- <label class="custom-control-label " for="check2">
                      <span>
                        {{'label.RememberMe' | translate}}
                      </span></label> -->
                  </div>

                   <a  style="cursor: pointer; color: red;" (click)="onResendOtp()">{{'button.ResendOtp' | translate}}</a>
                </div>
              </div> <button   [disabled]="signInForm.invalid" class="btn btn-primary btn-animated" style="color: white;">{{'button.Verify' | translate}}</button>


            </form>
            <!-- <div class="d-flex align-items-center text-center justify-content-center mt-4">
                    <span class="text-muted mr-1">{{'label.DontHaveAnAccount' | translate}}</span>
                     <a routerLink="/signup">{{'button.SignUp' | translate}}</a>
                  </div> -->
          </div>
        </div>
      </div>
    </div>
    </section>

    <!--login end-->

    </div>


