
<div [dir]="'dir' | translate" *ngIf="isShow">
<app-search-box></app-search-box>
<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>



</div>

<div class="fb-customerchat"
     page_id="105679095044194">
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#a83030" type = "square-loader" ><p style="color: white" > Loading... </p></ngx-spinner>




<!-- <form ngNoForm [action]="url" method="POST" class="paymentWidgets" data-brands="VISA MASTER">
  Currency Code : <input type="text" name="CURRENCY_CODE" value="QAR" readonly> <br>
  Merchand Id : <input type="text" name="MERCHANT_ID" [value]="orderData.merchant_id" readonly> <br>
  Token : <input type="text" name="MERCHANT_NAME" [value]="orderData.merchant_name" readonly> <br>
  Token : <input type="text" name="TOKEN" [value]="orderData.token" readonly> <br>
  Success Url : <input type="text" name="SUCCESS_URL" [value]="orderData.successUrl" readonly> <br>
  Failure Url : <input type="text" name="FAILURE_URL" [value]="orderData.failureUrl" readonly> <br>
  Checkout Url : <input type="text" name="CHECKOUT_URL" [value]="orderData.checkoutUrl" readonly> <br>
  Email : <input type="text" name="CUSTOMER_EMAIL_ADDRESS" [value]="orderData.email" readonly> <br>
  Mobile : <input type="text" name="CUSTOMER_MOBILE_NO" [value]="orderData.mobile" readonly> <br>
  Amount : <input type="text" name="TXNAMT" [value]="orderData.transactionAmount" readonly> <br>
  Basket Id : <input type="text" name="BASKET_ID" [value]="orderData.basketId" readonly> <br>
  Order Date : <input type="text" name="ORDER_DATE" value="2023-03-23" readonly> <br>
  Signature : <input type="text" name="SIGNATURE" value="sign" readonly> <br>
  Version : <input type="text" name="VERSION" value="merchane_cart_0.1" readonly> <br>
  Desc : <input type="text" name="TXNDESC" value="description" readonly> <br>
  Pro code : <input type="text" name="PROCCODE" value="00" readonly> <br>
  <button type="submit">Submit</button>
  </form> -->
  
 <!-- <a href="https://api.whatsapp.com/send?phone=9605855957">Send Message</a>


<a href="wtai://wp/ap;9605855957;firstName lastName">sss</a> -->






<!-- <a href="www.facebook.com"> Facebook.com</a> -->
<!-- {{'Test' | translate}} -->
