import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common-values/common.service';
import { Config } from 'src/app/common-values/config';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { DbService } from 'src/app/services/db.service';
import { runInThisContext } from 'vm';
declare var $: any;
declare function owlcarousel(): any;
declare function zoomPic(): any;
declare function showNotification(type : string,titile : string,message :string): any;





@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  submitted : boolean = false;
  productId : number = 0;
  seectedStockIdForRelatableStock : number =0;
  stockId : number = 0;
  notifyForm : FormGroup;
  relatableProductsList = []
  imagePath=""
  imagePath1=""
  imagePath2=""
  imagePath3=""
  lang = Number(Config.lang) ;
  selectedProductDetails : any
  selectedSizeOption : number =0;
  selectedColor : number =0;
  selectedStock : any = {}
  outOfStock : boolean = false;
  selectedQty : number = 1;
  sizeMasterList = [
    {
      id: 0  , name : 'EU'
    },
    {
      id : 1 , name : 'UK'
    },
    {
      id : 2 , name :'US'
    }
  ];
  selectedSizeMaster : number = 0;
  default_currencyformat = Config.currencyFormat;
  constructor(private route : ActivatedRoute ,
    private db : DbService ,
    public common : CommonService,
    private router : Router,
    private spinner : NgxSpinnerService,

    private confirmationDialogService  : ConfirmationService,
    private fb : FormBuilder,
    private datePipe : DatePipe) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
     }

  ngOnInit(): void {

    this.buildForm();
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.productId != undefined) {
        this.productId = Number(atob(queryParams.productId));
        if(queryParams.stockId){
          this.stockId = Number(atob(queryParams.stockId));
        }
        this.getProductDetails(this.productId);
      }
    })
  //   setTimeout(() => {
  //     owlcarousel();

  //  }, 2000);


   setTimeout(() => this.callFucntion(), 100); //

  }

  notifyMe(){
    let body = {
      UserId : this.common.userDetails.userId,
      ProductId : this.productId ,
      ColorId :this.selectedColor ,
      SizeOptionId : this.selectedSizeOption
    }
    this.db.methodPost("Common/PostNotification",body).subscribe(resdata =>{
      if(resdata.notifyId > 0){
        showNotification('success','Success','You will be notified when its avaiable. !');
      }
    },
    error =>{
      showNotification('error','Failed','already added to notified list !');
    })
  }



  onNotifySubmit(){
    this.submitted = true;
    if(this.notifyForm.invalid){
      return;
    }
    let email ="0"
    let phone ="0"
    if(this.notifyForm.controls.EmailOrPhone.value.includes('@')){
      email = this.notifyForm.controls.EmailOrPhone.value
    }
    else{
      phone = this.notifyForm.controls.EmailOrPhone.value
    }
    let body = {
      UserId : 0 ,
      ProductId : this.productId ,
      ColorId :this.selectedColor ,
      SizeOptionId : this.selectedSizeOption,
      UserName : this.notifyForm.controls.Name.value,
      Email : email ,
      Phone : phone
    }

    this.db.methodPost("Common/PostNotification",body).subscribe(resdata =>{
      if(resdata.notifyId > 0){
        showNotification('success','Success','You will be notified when its avaiable. !');
        document.getElementById('close-button').click();
      }
      else{
        alert('Failed')
      }
    },
    error =>{
      showNotification('error','Failed','already added to notified list !');
    }

    )
  }

  // ngAfterViewInit():void{
  //   this.imageAlignCenter();
  // }

  // imageAlignCenter(){
  //   setTimeout(function(){
  //     // var outerContentWidth = $('#outer_container').width();
  //     // var scrollPosition = outerContentWidth/2;
  //     // $('#inner_container').scrollLeft(scrollPosition);

  //     var inner = $("#inner_container");
  //     var outer = $("#outer_container");
  //     var scrollto = outer.offset().left + (outer.width() / 4);
  //     inner.animate({ scrollLeft:  scrollto});
  //   }, 1000);
  // }

  isLoading  : boolean = false;



  changeImage(){
    this.imageUrl = 'https://media.istockphoto.com/id/508653866/photo/sunlight-through-clouds.jpg?s=612x612&w=0&k=20&c=AfguTwiaJSXMs3yudVD_FBajLxyA4SRQ32aZzy2qQx8=';
    setTimeout(() => this.callFucntion(), 100); //
 
  }

  imageUrl = 'https://media.geeksforgeeks.org/wp-content/uploads/20220316223348/gfg-300x300.jpg';

  callFucntion(){
    var img :any = document.getElementById("gfg-img");
    var preview  :any= document.querySelector(".zoom-preview");

    //calculating the ratio by which we want to magnify the image
    //you can increase or decrease it according to your requirement
    var x = preview.offsetWidth / 100;
    var y = preview.offsetHeight / 100;

    img.addEventListener("mousemove", (e) => {
        preview.style.backgroundImage = 
"url(" +  this.imageUrl + ")";
        preview.style.backgroundSize = img.width * x + 
          "px " + img.height * y + "px";
        var posX = e.offsetX;
        var posY = e.offsetY;
        preview.style.backgroundPosition = "-" + posX * x + 
          "px -" + posY * y + "px";
    });
    img.addEventListener("mouseout", () => {
        preview.style.backgroundImage = "none";
    });
  }
  

  sizeOptionsList = []
  onRadioColorChange(color , key){
    // this.selectedColor = value;

    this.isLoading = true;
    let images : Array<any>=[];
    if(color.productColorImages?.imageName)
    images.push( { id : 1, imagePath : color.productColorImages?.imageName}); this.imagePath = color.productColorImages?.imageName;
    if(color.productColorImages?.imageName1)
    images.push( { id : 1, imagePath : color.productColorImages?.imageName1})
    if(color.productColorImages?.imageName2)
    images.push( { id : 2, imagePath : color.productColorImages?.imageName2})
    if(color.productColorImages?.imageName3)
    images.push( { id : 2, imagePath : color.productColorImages?.imageName3})
    this.selectedProductDetails.productImages = images;



    setTimeout(() => this.isLoading = false, 1000); //



    this.selectedSizeOption = 0;
    let body  = {
      ProductId : this.selectedProductDetails?.productId,
      ColorId : this.selectedColor
    }
this.db.methodPost("Common/GetStockSizeList" , body).subscribe(resdata =>{
  
  this.sizeOptionsList = resdata;
  if(this.sizeOptionsList.length > 0){
    this.selectedSizeOption = this.sizeOptionsList[0].sizeOptionId;
    this.onRadioSizeChange();
  }


})

    // this.checkStock();
  }
  onRadioSizeChange(){
    // this.selectedSizeOption = value;
    // this.checkStock();

    let body = {
      ProductId : this.selectedProductDetails?.productId,
      ColorId: this.selectedColor ,
      SizeOptionId : this.selectedSizeOption
    }
    this.db.methodPost("Common/GetProductStock", body).subscribe(resdata =>{
      
      this.selectedStock=  resdata.stock;
      console.log(this.selectedStock);
      
      this.checkStock();
    })
  }


  onSubCategBreadCumb(){
    this.router.navigate(['/product-list'] ,{
      queryParams: { subMenuName : this.selectedProductDetails.subCategoryName  ,name : this.selectedProductDetails.categoryName ,
           brandId : btoa("0")  , categoryId : btoa(this.selectedProductDetails.categoryId)  ,
        subCategoryId : btoa(this.selectedProductDetails.subCategoryId)  ,
      }})
  }


  onCategBreadCumb(){
    this.router.navigate(['/product-list'] ,{
      queryParams: { subMenuName : ""  ,name : this.selectedProductDetails.categoryName ,
           brandId : btoa("0")  , categoryId : btoa(this.selectedProductDetails.categoryId)  ,
        subCategoryId : btoa("")  ,
      }})
  }
  imageZoom(imgID, resultID) {
    var img, lens, result, cx, cy;
    img = document.getElementById(imgID);
    result = document.getElementById(resultID);
    /*create lens:*/
    lens = document.createElement("DIV");
    lens.setAttribute("class", "img-zoom-lens");
    /*insert lens:*/
    img.parentElement.insertBefore(lens, img);
    /*calculate the ratio between result DIV and lens:*/
    cx = result.offsetWidth / lens.offsetWidth;
    cy = result.offsetHeight / lens.offsetHeight;
    /*set background properties for the result DIV:*/
    result.style.backgroundImage = "url('" + img.src + "')";
    result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
    /*execute a function when someone moves the cursor over the image, or the lens:*/
    lens.addEventListener("mousemove", moveLens);
    img.addEventListener("mousemove", moveLens);
    /*and also for touch screens:*/
    lens.addEventListener("touchmove", moveLens);
    img.addEventListener("touchmove", moveLens);
    function moveLens(e) {
      var pos, x, y;
      /*prevent any other actions that may occur when moving over the image:*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      /*calculate the position of the lens:*/
      x = pos.x - (lens.offsetWidth / 2);
      y = pos.y - (lens.offsetHeight / 2);
      /*prevent the lens from being positioned outside the image:*/
      if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
      if (x < 0) { x = 0; }
      if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
      if (y < 0) { y = 0; }
      /*set the position of the lens:*/
      lens.style.left = x + "px";
      lens.style.top = y + "px";
      /*display what the lens "sees":*/
      result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    }
    function getCursorPos(e) {
      var a, x = 0, y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }
  }

   imageZooms(imgID, resultID) {
    var img, lens, result, cx, cy;
    img = document.getElementById(imgID);
    result = document.getElementById(resultID);
    /*create lens:*/
    lens = document.createElement("DIV");
    lens.setAttribute("class", "img-zoom-lens");
    /*insert lens:*/
    img.parentElement.insertBefore(lens, img);
    /*calculate the ratio between result DIV and lens:*/
    cx = result.offsetWidth / lens.offsetWidth;
    cy = result.offsetHeight / lens.offsetHeight;
    /*set background properties for the result DIV:*/
    result.style.backgroundImage = "url('" + img.src + "')";
    result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
    /*execute a function when someone moves the cursor over the image, or the lens:*/
    lens.addEventListener("mousemove", moveLens);
    img.addEventListener("mousemove", moveLens);
    /*and also for touch screens:*/
    lens.addEventListener("touchmove", moveLens);
    img.addEventListener("touchmove", moveLens);
    function moveLens(e) {
      var pos, x, y;
      /*prevent any other actions that may occur when moving over the image:*/
      e.preventDefault();
      /*get the cursor's x and y positions:*/
      pos = getCursorPos(e);
      /*calculate the position of the lens:*/
      x = pos.x - (lens.offsetWidth / 2);
      y = pos.y - (lens.offsetHeight / 2);
      /*prevent the lens from being positioned outside the image:*/
      if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
      if (x < 0) {x = 0;}
      if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
      if (y < 0) {y = 0;}
      /*set the position of the lens:*/
      lens.style.left = x + "px";
      lens.style.top = y + "px";
      /*display what the lens "sees":*/
      result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    }
    function getCursorPos(e) {
      var a, x = 0, y = 0;
      e = e || window.event;
      /*get the x and y positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x and y coordinates, relative to the image:*/
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return {x : x, y : y};
    }
  }

 get f() { return this.notifyForm.controls}
  buildForm(){
    this.notifyForm=this.fb.group({
      Name :["" , Validators.required],
      EmailOrPhone :["",Validators.required]

    })
  }

  productRating : number = 0;
  getProductDetails(productId){
    

    this.spinner.show();
    this.db.methodGet("Common/GetProductStockList/" + productId).subscribe(resdata =>{
      this.spinner.hide();



      
      
      this.selectedProductDetails = resdata[0];
      console.log(this.selectedProductDetails);
      this.productRating = this.selectedProductDetails.starRating;
      
      if(this.selectedProductDetails?.colors.length > 0 ){

      
        this.selectedColor = this.selectedProductDetails.colors[0].colorId;
        this.onRadioColorChange(this.selectedProductDetails.colors[0], 1);
      }


      this.relatableProducts(resdata[0].categoryId)
      // if(this.selectedProductDetails.stocks.length > 0){
      //   if(this.stockId > 0){
      //     this.selectedStock = this.selectedProductDetails.stocks.find(x => x.stockId ==  this.stockId)
      //   }
      //   else{
      //   this.selectedStock = this.selectedProductDetails.stocks[0];

      //   }
      //   // document.getElementById('color1').click();
      //   this.selectedColor = this.selectedStock.colorId;
      //   this.selectedSizeOption = this.selectedStock.sizeOptionId;
      // }
      // else{
      //   this.selectedColor = 0;
      //   this.selectedSizeOption = 0;
      // }
      if(this.selectedProductDetails?.productImages?.length > 0){
        this.imagePath = this.selectedProductDetails?.productImages[0]?.imagePath;
        this.imagePath1 = this.selectedProductDetails?.productImages[1]?.imagePath;
        this.imagePath2 = this.selectedProductDetails?.productImages[2]?.imagePath;
        this.imagePath3 = this.selectedProductDetails?.productImages[3]?.imagePath;

      };
      zoomPic();
      // this.checkStock();
      //  showNotification('success','Success','Successfully fetched images!');
    }, error =>{

      this.spinner.hide();
    })
  }

  onQuickView(product){
    this.seectedStockIdForRelatableStock = product.stockId
    this.db.methodGet("Common/GetProductList/" + product.productId).subscribe(resdata =>{
      this.selectedProductDetails = resdata[0];
    })

  }

  onRadioChange(){
  
  }
  relatableProducts(catgeoryId){
    let categories = [];
    categories.push(catgeoryId)

    let body =
    JSON.stringify({
      PageNumber : "1",
      PageSize : "8",
      CategoryId : categories,
      BrandId : [],
      SubCategoryId : [],
      ColourId : [],
       SortBy : "0",
       PriceMin : "0",
      PriceMax : "10000",
       SizeOptionId : []

    })
    this.db.methodPost("Common/GetProductList" , body).subscribe(resdata =>{
      this.relatableProductsList = resdata.products;
      owlcarousel();


    })
  }

  ratingId =2
  rating =[] ;

  onRatingClick(){
    this.db.methodGet("Configs/GetProductCustomerRatings/" + this.selectedProductDetails?.productId).subscribe(resdata =>{
      
      this.rating = resdata ; 
      // this.rating[0].fiveStar = 100
    })
  }

  onQtyChange(key){
    if(this.selectedColor == 0){
      showNotification('error','Failed','Please Select Color!');
      return;
    }
    if(this.selectedSizeOption == 0){
      showNotification('error','Failed','Please Select Size!');
      return;
    }
    if(key === 1){
      if(this.selectedQty != 1){
        this.selectedQty = this.selectedQty - 1;
      }
    }
    else{
      this.selectedQty = this.selectedQty + 1;
    }

    this.checkStock();
  }

  checkStock(){
        if(this.selectedStock.availableStock > 0){
          this.outOfStock = false;
          if(this.selectedQty > this.selectedStock.availableStock){
            showNotification('error','Failed','sorry available stock is ' + this.selectedStock.availableStock);
            this.outOfStock = true;
          }
          else{
            this.outOfStock = false;
          }
        }
        else{
          this.outOfStock = true;
        }
      


  }

  addWishList(){

    
    if(this.selectedColor == 0){
      showNotification('error','Failed','Select a Color !');
      return;
    }
    if(this.selectedSizeOption == 0){
      showNotification('error','Failed','Select a Size !');
      return;
    }
      if(this.common.isLoggedIn == true){
        let body ={
          productId : this.selectedStock.productId,
          userId : this.common.userDetails?.userId,
          stockId : this.selectedStock.stockId,
          quantity : this.selectedQty,
          date : this.datePipe.transform(new Date , 'yyyy-MM-dd'),
          isActive : true
        }


        this.spinner.show();
        this.db.methodPost("Wishlists" , body).subscribe(resdata =>{
          this.spinner.hide();
          if(resdata.wishlistId > 0){
            showNotification('success','Success','Successfully added to wishlist !');

            // this.router.navigate(['/wishlist'])
          }

        } , error =>{
          this.spinner.hide();
          showNotification('error','Failed', error.error.message);
        })
      }
      else{
        this.router.navigate(['/login'])
      }


  }
  addToCart(){


    if(this.selectedColor == 0){
      showNotification('error','Failed','Select a Color !');
      return;
    }
    if(this.selectedSizeOption == 0){
      showNotification('error','Failed','Select a Size !');
      return;
    }
    if(this.outOfStock){
      showNotification('error','Failed','Sorry Item Not Available !');
    }
    else{
      if(this.common.isLoggedIn == true){
        let body ={
          productId : this.selectedStock.productId,
          userId : this.common.userDetails?.userId,
          stockId : this.selectedStock.stockId,
          quantity : this.selectedQty,
          date : this.datePipe.transform(new Date , 'yyyy-MM-dd'),
          isActive : true
        }
        this.spinner.show();
        this.db.methodPost("Carts" , body).subscribe(resdata =>{


          this.spinner.hide();
          if(resdata.cartId > 0){
            showNotification('success','Success','Successfully added to cart !');
            this.getCartList();
            // this.router.navigate(['/product-cart'])
          }
        })
      }
      else{

        if(this.common.cartList.find(x => x.stockId === this.selectedStock.stockId)){

          showNotification('success','Success','Successfully added to cart !');
          // alert('already exists in cart')
          let element = this.common.cartList.find(
            (x) => x.stockId == this.selectedStock.stockId
          );
          element.quantity = element.quantity + 1;
        }
        else{
          let item = {
            stockId : this.selectedStock.stockId,
            quantity : this.selectedQty,
            productId : this.selectedStock.productId,
            date : this.datePipe.transform(new Date , 'yyyy-MM-dd'),
            isActive : true,
            userId : 0,
            cartId : 0

          } ;

          this.common.cartList.push(item);
          showNotification('success','Success','Successfully added to cart !');

        }
        localStorage.setItem('cart' , JSON.stringify(this.common.cartList))

        // this.router.navigate(['/login'])
      }

    }


  }

  getCartList(){
    this.spinner.show();
    this.db.methodGet("Carts/GetUserCart/" + this.common.userDetails.userId).subscribe(resdata =>{
      this.spinner.hide();
      this.common.cartCount = resdata.length;
      this.common.cartList = resdata;
    })
  }
  navigateToProductDetails(product){
    this.router.navigate(['/product-details'] ,{
      queryParams: { productName : product.productName  , productId : btoa(product.productId) , stockId : btoa(product.stockId)} })
  //     setTimeout(() =>{
  //       window.location.reload();
  //     }
  //  ,1000
  //      ); //

  }

  addRelatableToWishList(item){
    //  if(confirm('are you sure to add to wishlist ?')){
      if(this.common.isLoggedIn == true){
        let body ={
          productId : item.productId,
          userId : this.common.userDetails?.userId,
          stockId : item.stockId,
          quantity : 1,
          date : this.datePipe.transform(new Date , 'yyyy-MM-dd'),
          isActive : true,
        }
        this.spinner.show();
        this.db.methodPost("Wishlists" , body).subscribe(resdata =>{
          this.spinner.hide();
          if(resdata.wishlistId > 0){
            showNotification('success','Success','Successfully added to wishlist !');
            // this.router.navigate(['/wishlist'])
          }

        } , error =>{



          this.spinner.hide();
          showNotification('error','Failed','already in wishlist !');
        })
      }
      else{
        this.router.navigate(['/login'])
      }
      //  }

    }
    popUpClose(){

        document.getElementById('close-button').click();
    }

  }
