import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common-values/common.service';
import { Config } from 'src/app/common-values/config';
import { Constants } from 'src/app/common-values/constants';
import { DbService } from 'src/app/services/db.service';
declare function showNotification(type : string,titile : string,message :string): any;

@Component({
  selector: 'app-cart-checkout-page',
  templateUrl: './cart-checkout-page.component.html',
  styleUrls: ['./cart-checkout-page.component.scss']
})
export class CartCheckoutPageComponent implements OnInit {


  section1Active : boolean = false;
  section1Valid : boolean = false;

  section2Active : boolean = false;
  section2Valid : boolean = false;

  section3Active : boolean = false;
  section3Valid : boolean = false;
  cartList = [];
  lang = Number(Config.lang)
  addNewAddress : boolean = false
  subTotal: number = 0;
  shippingAmount: number = 0;
  netTotal: number = 0;
  loginTabClass = '';
  deliveringToTabClass = '';
  addressTabClass = '';
  checkOutForm: FormGroup;
  emailSubmitted : boolean =false;
  delievryFormSubmitted : boolean = false;
  dataValid: boolean = false;
  submitted : boolean = false;
  addressId  =0;
  addressList =[];
  cityList = []
  isCashOnDelievry : boolean = true;
  isCardPayment : boolean = false;
  deliveryForm : FormGroup
  default_currencyformat = Config.currencyFormat;
  constructor(
    public common: CommonService,
    private db: DbService,
    private spinner : NgxSpinnerService,

    private fb: FormBuilder,
    private datePipe : DatePipe,
    private router : Router,
  ) {
    this.buildForm();
    this.buildDeliveryForm();
    this.getCities();





  }


  onPayTypeChange(type : number){


    if(type == 0) this.isCardPayment = false;
    if(type == 1) this.isCashOnDelievry = false;

  }

  getCities(){

    this.spinner.show();
    this.db.methodGet("Configs/GetCities/1").subscribe(resdata =>{
      this.spinner.hide();
      this.cityList = resdata;
    })
  }


  couponDetails ;

  couponCode =""
  applyCoupon(){


    let userId = 0;
    if(this.common.userDetails && this.common.userDetails?.userId){
      userId = this.common.userDetails?.userId
    }
    let body = {
      couponCode : this.couponCode,
      userId : userId
    }


     this.db.methodPost("Configs/ApplyCoupon/" , body ).subscribe(resdata =>{
       if(resdata.couponId > 0 && ( this.subTotal  > resdata.minAmount)){
          this.couponDetails = resdata;
          localStorage.setItem('coupon', this.couponCode);
          this.findTotal();
       }
       else{

         localStorage.removeItem('coupon')
         this.couponCode =""
         this.couponDetails = {}
       }
     }
     ,error =>{
      this.couponDetails = {}
      localStorage.removeItem('coupon')
      showNotification('error','Failed', error.error.message);
     }
     )
  }

  couponDiscAmont = 0


  onCityChange(){

    this.f.cityName.setValue(this.cityList.find(x => x.cityId === Number(this.f.cityId.value)).city1);
  }
  onAddNewAddress(){
    this.addNewAddress = true;
    this.addressId = 0;
  }

  onSavedAddresses(){
    this.addNewAddress = false;
    this.buildDeliveryForm();

  }


  onEmailSubmit(){
    this.emailSubmitted = true;
    if(this.checkOutForm.invalid){
      return;
    }

    this.section1Valid = true;
    this.section1Active = false;
    this.section2Active = true;
    this.spinner.show();
    this.db.methodGet("Addresses/CheckUserExists/" + this.checkOutForm.controls.email.value).subscribe(resdata =>{
    this.spinner.hide();
    this.addressList = resdata;
    if(this.addressList.length > 0){
      this.addNewAddress = false;
    }
    else{
      this.addNewAddress = true;
    }
    })
  }


  getUserAddresses(){
    this.spinner.show();
    this.db.methodGet("Addresses/GetUserAddress/" + this.common.userDetails?.userId).subscribe(resdata =>{
      this.spinner.hide();
      this.addressList = resdata;
    })
  }
  ngOnInit(): void {
    if (this.common.isLoggedIn) {
      this.section1Valid = true;
      this.section2Active = true;
      this.getCartList(1);this.getUserAddresses();
    } else {
      this.section1Active = true;
      this.getCartListFromLocalStorageData(1);
    }



    // document.getElementById('tab3').;

    // this.hideEventsClass();
  }

  // hideEventsClass(){
  //   this.
  // }
  buildForm() {
    this.checkOutForm = this.fb.group({
      email: ['', [Validators.required , Validators.email]],
    });
  }
  buildDeliveryForm() {
    this.deliveryForm = this.fb.group({

      name: ['', Validators.required],  //
      address1: ['', Validators.required],  //
      street: ['',Validators.required],
      landmark: ['' ,Validators.required],  //
      pincode: [''],
      cityId: ['', Validators.required], //
      cityName: [''],
      mobileNumber: ['', Validators.required],
      countryCode: [''],
      carrierCode: [''],
      isPrimary: true,
      isHomeAddess: true,
    });
  }


  get df() { return this.deliveryForm.controls}
  onDelievryToAddressClick() {
    this.addressId = 0;
    this.delievryFormSubmitted = true;
    if (this.deliveryForm.valid) {
      this.dataValid = true;
      this.section2Active = false;
      this.section2Valid = true;
      this.section3Active = true;
    }


  }



  disablePlaceOrder(){
    this.dataValid = false;
  }
  selectedAddressDetails
  onSelectAddress(addressId){
    this.addressId = addressId;
    if(this.addressId > 0){
      this.selectedAddressDetails = this.addressList.find(x => x.addressId === this.addressId);
      this.section2Valid = true;
      this.section2Active = false;
      this.section3Active = true
      this.dataValid = true;
    }

  }
  placeOrder(key  : number) {


    let couponId = null;
    let couponDiscountPer = 0;
    let payModeID : number = 0;
    if(this.isCashOnDelievry) payModeID = 1;
    if(this.isCardPayment) payModeID = 2;

    if(this.couponDetails){
       couponId = this.couponDetails.couponId
       couponDiscountPer = this.couponDetails.discountPercent
    }
    if (this.common.isLoggedIn) {
     if(this.addressId == 0 && this.deliveryForm.invalid){
       alert('Complete Address Form');
       return;
     }


     if(this.cartList.length == 0){
       alert('please add atlease one item');
       return;
     }




    //  if(!this.isCashOnDelievry){
    //   showNotification('danger','Payment','Please Select Payment Method');
    //   return;
    //  }




    

     let body ;

    
      if(this.addressId == 0){
         body= {
          userId: this.common.userDetails?.userId,
          addressId: this.addressId,
          date: this.datePipe.transform(new Date() , 'yyyy-MM-dd'),
          totalAmount: this.subTotal,
          orderDetails: this.cartList,
          DiscountPercent : couponDiscountPer,
          CouponId : couponId,
          paymentModeId : payModeID,
          user: {
            Email: this.common.userDetails?.email,
          },
          address: {
            name: this.f.name.value,
            address1: this.f.address1.value,
            street: this.f.street.value,
            landmark: this.f.landmark.value,
            pincode: this.f.pincode.value,
            cityId:Number(this.f.cityId.value),
            cityName: this.f.cityName.value,
            mobileNumber: this.f.mobileNumber.value,
            alternateMobileNumber: null,
            countryCode: null,
            carrierCode: '91',
            isPrimary: true,
            isHomeAddress: true,
          }
        };
      }
      else{
        body= {
          userId: this.common.userDetails?.userId,
          paymentModeId : payModeID,
          addressId: this.addressId,
          date: this.datePipe.transform(new Date() , 'yyyy-MM-dd'),
          totalAmount: this.subTotal,
          orderDetails: this.cartList,
          }
      }



      this.spinner.show();
      this.db.methodPost("order" , body).subscribe(resdata =>{
        this.spinner.hide();
        if(resdata.orderId > 0){
          if(resdata.paymentModeId == 1){
            localStorage.removeItem('cart');
            this.common.cartList =[]
            this.router.navigate(['/order-success'] ,{
              queryParams: { billNo : resdata.billNo  , orderId : btoa((resdata.orderId).toString())  ,
              }})
          }
          else {
            this.gateWayDetails = resdata.gateways;
            if(this.gateWayDetails){
              this.gateWayDetails.successUrl =Constants.PAYMENT_SUCEESS_URL;
              this.gateWayDetails.failureUrl = Constants.PAYMENT_FAILURE_URL;
              setTimeout(() => document.getElementById('payId').click(), 500); //
            }
          }
        }
      }, error =>{
        this.spinner.hide();
        showNotification('error','Failed', error.error.message);
        if(error.error.code  == "001" || error.error.code  == "002"){
          localStorage.removeItem('coupon')
          this.router.navigate(['/product-cart'])
        }
      })

    } else {

      if(this.deliveryForm.invalid && this.addressId == 0){
        alert('Complete Address Form');
      }
      if(this.checkOutForm.invalid){
        alert('please provide a valid email')
      }

      // if(!this.isCashOnDelievry){
      //   showNotification('danger','Payment','Please Select Payment Method');
      //   return;
      //  }

      let cityId = 0
      if(this.f.cityId.value == ""){
        cityId = 0
      }
      else{
        cityId = Number(this.f.cityId.value)
      }
      let body= {
        userId: 0,
        addressId: this.addressId,
        paymentModeId : payModeID,
        date: this.datePipe.transform(new Date() , 'yyyy-MM-dd'),
        totalAmount: this.subTotal,
        DiscountPercent : couponDiscountPer,
        CouponId : couponId,
        orderDetails: this.cartList,
        user: {
          Email: this.e.email.value,
        },
        address: {
          name: this.f.name.value,
          address1: this.f.address1.value,
          street: this.f.street.value,
          landmark: this.f.landmark.value,
          pincode: this.f.pincode.value,
          cityId: Number(cityId),
          cityName: this.f.cityName.value,
          mobileNumber: this.f.mobileNumber.value,
          alternateMobileNumber: null,
          countryCode: null,
          carrierCode: '91',
          isPrimary: true,
          isHomeAddress: true,
        }
      };

      this.spinner.show();

      this.db.methodPost("order" , body).subscribe(resdata =>{
        this.spinner.hide();
        if(resdata.orderId > 0){
          if(resdata.paymentModeId == 1){
            localStorage.removeItem('cart');
            this.common.cartList =[]
            this.router.navigate(['/order-success'] ,{
              queryParams: { billNo : resdata.billNo  , orderId : btoa((resdata.orderId).toString())  ,
              }})
          }
          else {
            this.gateWayDetails = resdata.gateways;
            this.gateWayDetails.successUrl =Constants.PAYMENT_SUCEESS_URL;
            this.gateWayDetails.failureUrl = Constants.PAYMENT_FAILURE_URL;
            setTimeout(() => document.getElementById('payId').click(), 500); //
          }
        }
      }, error =>{
        showNotification('error','Failed', error.error.message);
        this.spinner.hide();

        if(error.error.code  == "001" || error.error.code  == "002"){
          localStorage.removeItem('coupon')
          this.router.navigate(['/product-cart'])
        }
      })
    }

  }


  url : string = 'https://pay.pay2m.com/Ecommerce/api/Transaction/PostTransaction';

  gateWayDetails : any;

  get e(){
    return this.checkOutForm.controls;

  }

  get f() {
    return this.deliveryForm.controls;
  }

  findTotal() {
    this.subTotal = 0;
    this.netTotal = 0;
    this.cartList.forEach((x) => {
      if(x.availableStock < x.quantity && x.availableStock > 0){
        x.outOfStockSelected = true;
      }
      else{
        x.outOfStockSelected = false;

      }
       if(x.availableStock <= 0){
        x.outOfStock = true;
      }
      else{
        x.outOfStock = false;

      }

      if(x.outOfStock == false && x.outOfStockSelected == false){
        x.total = x.quantity * x.discountPrice;
        this.subTotal = this.subTotal + x.total;
      }



    });
    if(this.couponDetails && ( this.subTotal) > this.couponDetails.minAmount){
      this.couponDiscAmont = ((this.subTotal) * this.couponDetails.discountPercent)/ 100
    }
    this.netTotal  = this.subTotal + this.shippingAmount - this.couponDiscAmont ;

    this.cartList = this.cartList.filter(x=> x.outOfStockSelected == false && x.outOfStock == false)
  }
  getCartListFromLocalStorageData(key ?) {
    this.spinner.show();
    this.db
      .methodPost('Carts/GetCartDetails', this.common.cartList)
      .subscribe((resdata) => {
        this.spinner.hide();
        this.cartList = resdata;
        this.cartList.forEach((x) => {
          let element = this.common.cartList.find(
            (y) => y.stockId === x.stockId
          );
          x.quantity = element.quantity;
        });

        this.common.cartList = this.cartList;

        this.findTotal();


        if(key == 1){
          if(localStorage.getItem('coupon') != undefined){
            this.couponCode = localStorage.getItem('coupon');
            this.applyCoupon();
         }
        }
      });
  }
  getCartList(key?) {
    this.spinner.show();
    this.db
      .methodGet('Carts/GetUserCart/' + this.common.userDetails.userId)
      .subscribe((resdata) => {
        this.spinner.hide();
        this.cartList = resdata;
        this.common.cartCount = resdata.length;
        this.common.cartList = this.cartList;

        this.findTotal();
        if(key == 1){
          if(localStorage.getItem('coupon') != undefined){
            this.couponCode = localStorage.getItem('coupon');
            this.applyCoupon();
         }
        }
      });
  }


}
