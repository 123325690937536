


<section class="bg-light">
    <div class="container">
      <div class="row align-items-center">

        <div class="col-md-6 mt-3 mt-md-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent p-0 m-0">
              <li class="breadcrumb-item"><a class="text-dark" routerLink="/"><i class="fa fa-home mr-1"></i>{{'menu.Home' | translate}}</a>
              </li>
              <li class="breadcrumb-item active text-primary" aria-current="page">{{'menu.PrivacyPolicy' | translate}}</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <!-- / .container -->
    </section>


    <div class="page-content">

    <!--feature start-->

    <section>
    <div class="container">
      <div class="row align-items-end mb-5"> 
        <div class="col-12 col-md-12 col-lg-12">
          <div>

            <h2 class="mb-0">{{'header.PrivacyPolicy' | translate}}</h2>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-12">
            <h4 class="mb-0" style="font-weight: bold;">{{'privacyPolicy.text1' | translate}}</h4>
          <div>
            <p class="lead mb-0">
              {{'privacyPolicy.text2' | translate}}</p>
          </div>
        </div>

        <div class="col-12 col-md-12 col-lg-12">
            <h4 class="head-privacy mb-0" style="font-weight: bold;">{{'privacyPolicy.text3' | translate}}</h4>
          <div>
            <p class="lead mb-0">
              {{'privacyPolicy.text4' | translate}}</p>
                <p class="lead mb-0">
                    • &nbsp;&nbsp; {{'privacyPolicy.text5' | translate}}<br/>
                    • &nbsp;&nbsp; {{'privacyPolicy.text6' | translate}}<br/>
                    • &nbsp;&nbsp; {{'privacyPolicy.text7' | translate}}<br/>
                    • &nbsp;&nbsp; {{'privacyPolicy.text8' | translate}}<br/>
                    • &nbsp;&nbsp; {{'privacyPolicy.text9' | translate}}<br/>
                    • &nbsp;&nbsp; {{'privacyPolicy.text10' | translate}}<br/>



                </p>
                <p class="lead mb-0">
                    <b>{{'privacyPolicy.text11' | translate}} </b> {{'privacyPolicy.text12' | translate}}
                </p>

                <p class="lead mb-0">


                    <b style="font-weight: bold;">{{'privacyPolicy.text13' | translate}} </b> <br/>
                    {{'privacyPolicy.text14' | translate}}

                </p>

                <p class="lead mb-0">


                    <b style="font-weight: bold;">{{'privacyPolicy.text15' | translate}} </b> <br/>
                    {{'privacyPolicy.text16' | translate}}


                </p>
                <p class="lead mb-0">


                    <b style="font-weight: bold;">{{'privacyPolicy.text17' | translate}} </b> <br/>
                    {{'privacyPolicy.text18' | translate}}
                </p>
                <p class="lead mb-0">


                    <b style="font-weight: bold;">{{'privacyPolicy.text19' | translate}}</b> <br/>
                    {{'privacyPolicy.text20' | translate}}
                </p>

                <p class="lead mb-0">


                    <b style="font-weight: bold;">   {{'privacyPolicy.text21' | translate}}</b> <br/>
                    {{'privacyPolicy.text22' | translate}}
                </p>

                <p class="lead mb-0">


                    <b style="font-weight: bold;">{{'privacyPolicy.text23' | translate}}</b> <br/>
                    {{'privacyPolicy.text24' | translate}}
                </p>


          </div>
        </div>

      </div>
      


    </div>
    </section>

    <!--feature end-->





    <!--testimonial end-->


    <!--multi sec start-->




    <!--blog end-->


    <!--instagram start-->



    <!--instagram end-->

    </div>

















      <!-- Optional JavaScript -->
      <!-- jQuery first, then Popper.js, then Bootstrap JS -->


    <!-- <script src='js/jquery.min.js'></script>
    <script src='js/bootstrap.min.js'></script>
    <script src='js/owl.carousel.min.js'></script>
    <script src='js/script.js'></script>


    <script src="assets/js/theme-plugin.js"></script>
    <script src="assets/js/theme-script.js"></script> -->




    <!-- </body> -->

