


<section class="bg-light">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-12 mt-3 mt-md-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item"><a class="text-dark" href="#"><i class="las la-home mr-1"></i>Home</a>
            </li>
            <li class="breadcrumb-item active text-primary" aria-current="page">Coming Soon</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
  </section>


  <div class="page-content"  style="height: px;">

    <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="mb-5">
            <!-- <h6 class="text-primary mb-1">-My Bag , {{cartList?.length}} item (s)</h6> -->

          </div>
        <div class="page" >
         content of this page will coming soon
        </div>




          <!--<div class="d-md-flex align-items-end justify-content-between border-top pt-5">
            <div>
              <label class="text-dark h4" for="coupon">Coupon</label>
              <p>Enter your coupon code if you have one.</p>
              <div class="row form-row">
                <div class="col">
                  <input class="form-control" id="coupon" placeholder="Coupon Code" type="text">
                </div>
                <div class="col col-auto">
                  <button class="btn btn-dark btn-animated">Apply Coupon</button>
                </div>
              </div>
            </div>
            <button class="btn btn-primary btn-animated mt-3 mt-md-0">Update Cart</button>
          </div>-->
        </div>


        </div>

    </div>
    </section>

    </div>

  <!--body content end-->










