import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common-values/common.service';
import { Config } from 'src/app/common-values/config';
import { DbService } from 'src/app/services/db.service';
import { threadId } from 'worker_threads';
declare function showNotification(type : string,titile : string,message :string): any;

@Component({
  selector: 'app-cart-check-out',
  templateUrl: './cart-check-out.component.html',
  styleUrls: ['./cart-check-out.component.scss'],
})
export class CartCheckOutComponent implements OnInit {
  cartList = [];
  lang = Number(Config.lang)
  addNewAddress : boolean = false
  subTotal: number = 0;
  shippingAmount: number = 0;
  netTotal: number = 0;
  loginTabClass = '';
  deliveringToTabClass = '';
  addressTabClass = '';
  checkOutForm: FormGroup;
  emailSubmitted : boolean =false;
  delievryFormSubmitted : boolean = false;
  dataValid: boolean = false;
  submitted : boolean = false;
  addressId  =0;
  addressList =[];
  cityList = []
  deliveryForm : FormGroup
  default_currencyformat = Config.currencyFormat;
  constructor(
    public common: CommonService,
    private db: DbService,
    private fb: FormBuilder,
    private datePipe : DatePipe,
    private router : Router,
  ) {
    this.buildForm();
    this.buildDeliveryForm();
    this.getCities();

  }


  getCities(){
    this.db.methodGet("Configs/GetCities/1").subscribe(resdata =>{
      this.cityList = resdata;
    })
  }


  onCityChange(){

    this.f.cityName.setValue(this.cityList.find(x => x.cityId === Number(this.f.cityId.value)).city1);
  }
  onAddNewAddress(){
    this.addNewAddress = true;
    this.addressId = 0;
  }

  onSavedAddresses(){
    this.addNewAddress = false;
    this.buildDeliveryForm();

  }


  onEmailSubmit(){
    this.db.methodGet("Addresses/CheckUserExists/" + this.checkOutForm.controls.email.value).subscribe(resdata =>{
    this.addressList = resdata;
    if(this.addressList.length > 0){
      this.addNewAddress = false;
    }
    else{
      this.addNewAddress = true;
    }
    })
  }


  getUserAddresses(){
    this.db.methodGet("Addresses/GetUserAddress/" + this.common.userDetails?.userId).subscribe(resdata =>{
      this.addressList = resdata;
    })
  }
  ngOnInit(): void {
    if (this.common.isLoggedIn) {
      this.getCartList();this.getUserAddresses();
    } else {
      this.getCartListFromLocalStorageData();
    }


    if(localStorage.getItem('coupon') != undefined){
      this.couponCode = localStorage.getItem('coupon');
      this.applyCoupon();
   }
    // document.getElementById('tab3').;

    // this.hideEventsClass();
  }

  // hideEventsClass(){
  //   this.
  // }
  buildForm() {
    this.checkOutForm = this.fb.group({
      email: ['', [Validators.required , Validators.email]],
    });
  }
  buildDeliveryForm() {
    this.deliveryForm = this.fb.group({

      name: ['', Validators.required],  //
      address1: ['', Validators.required],  //
      street: [''],
      landmark: ['' ,Validators.required],  //
      pincode: [''],
      cityId: ['', Validators.required], //
      cityName: [''],
      mobileNumber: ['', Validators.required],
      countryCode: [''],
      carrierCode: [''],
      isPrimary: true,
      isHomeAddess: true,
    });
  }

  onDelievryToAddressClick() {
    this.addressId = 0;
    this.delievryFormSubmitted = true;
    if (this.deliveryForm.valid) {
      this.dataValid = true;
    }
  }



  disablePlaceOrder(){
    this.dataValid = false;
  }
  selectedAddressDetails
  onSelectAddress(addressId){
    this.addressId = addressId;
    if(this.addressId > 0){
      this.selectedAddressDetails = this.addressList.find(x => x.addressId === this.addressId);
      this.dataValid = true;
    }

  }
  placeOrder() {
    if (this.common.isLoggedIn) {
     if(this.addressId == 0 && this.deliveryForm.invalid){
       alert('Complete Address Form');
       return;
     }


     if(this.cartList.length == 0){
       alert('please add atlease one item');
       return;
     }
     let body ;
      if(this.addressId == 0){
         body= {
          userId: this.common.userDetails?.userId,
          addressId: this.addressId,
          date: this.datePipe.transform(new Date() , 'yyyy-MM-dd'),
          totalAmount: this.subTotal,
          orderDetails: this.cartList,
          user: {
            Email: this.common.userDetails?.email,
          },
          address: {
            name: this.f.name.value,
            address1: this.f.address1.value,
            street: this.f.street.value,
            landmark: this.f.landmark.value,
            pincode: this.f.pincode.value,
            cityId: this.f.cityId.value,
            cityName: this.f.cityName.value,
            mobileNumber: this.f.mobileNumber.value,
            alternateMobileNumber: null,
            countryCode: null,
            carrierCode: '91',
            isPrimary: true,
            isHomeAddress: true,
          }
        };
      }
      else{
        body= {
          userId: this.common.userDetails?.userId,
          addressId: this.addressId,
          date: this.datePipe.transform(new Date() , 'yyyy-MM-dd'),
          totalAmount: this.subTotal,
          orderDetails: this.cartList,
          }
      }


      this.db.methodPost("order" , body).subscribe(resdata =>{
        if(resdata.orderId > 0){
          localStorage.removeItem('cart');
          this.common.cartList =[]
          this.router.navigate(['/order-success'] ,{
            queryParams: { billNo : resdata.billNo  , orderId : btoa((resdata.orderId).toString())  ,
            }})
        }
      }, error =>{
        showNotification('error','Failed', error.error.message);
        if(error.error.code  == "001" || error.error.code  == "002"){
          localStorage.removeItem('coupon')
          this.router.navigate(['/product-cart'])
        }
      }
      
      )

    } else {

      if(this.deliveryForm.invalid && this.addressId == 0){
        alert('Complete Address Form');
      }
      if(this.checkOutForm.invalid){
        alert('please provide a valid email')
      }
      let cityId = 0
      if(this.f.cityId.value == ""){
        cityId = 0
      }
      else{
        cityId = this.f.cityId.value
      }
      let body= {
        userId: 0,
        addressId: this.addressId,
        date: this.datePipe.transform(new Date() , 'yyyy-MM-dd'),
        totalAmount: this.subTotal,
        orderDetails: this.cartList,
        user: {
          Email: this.e.email.value,
        },
        address: {
          name: this.f.name.value,
          address1: this.f.address1.value,
          street: this.f.street.value,
          landmark: this.f.landmark.value,
          pincode: this.f.pincode.value,
          cityId: cityId,
          cityName: this.f.cityName.value,
          mobileNumber: this.f.mobileNumber.value,
          alternateMobileNumber: null,
          countryCode: null,
          carrierCode: '91',
          isPrimary: true,
          isHomeAddress: true,
        }
      };

      this.db.methodPost("order" , body).subscribe(resdata =>{
        if(resdata.orderId > 0){
          localStorage.removeItem('cart');
          this.common.cartList =[]
          this.router.navigate(['/order-success'] ,{
            queryParams: { billNo : resdata.billNo  , orderId : btoa((resdata.orderId).toString())  ,
            }})
        }
      },
      error =>{
      showNotification('error','Failed', error.error.message);
      if(error.error.code  == "001" || error.error.code  == "002"){
        localStorage.removeItem('coupon')
        this.router.navigate(['/product-cart'])
      }
      }
      )
    }

  }

  get e(){
    return this.checkOutForm.controls;

  }

  get f() {
    return this.deliveryForm.controls;
  }

  couponDetails ;

  couponCode =""
  applyCoupon(){


    let userId = 0;
    if(this.common.userDetails && this.common.userDetails?.userId){
      userId = this.common.userDetails?.userId
    }
    let body = {
      couponCode : this.couponCode,
      userId : userId
    }

     this.db.methodPost("Configs/ApplyCoupon/" , body ).subscribe(resdata =>{
       if(resdata.couponId > 0 && ( this.subTotal  > resdata.minAmount)){
          this.couponDetails = resdata;
          localStorage.setItem('coupon', this.couponCode);
          this.findTotal();
       }
       else{
         localStorage.removeItem('coupon')
         this.couponCode =""
         this.couponDetails = {}
       }
     }
     ,error =>{
      this.couponDetails = {}
      localStorage.removeItem('coupon')
      showNotification('error','Failed', error.error.message);
     }
     )
  }

  couponDiscAmont = 0

  findTotal() {
    this.subTotal = 0;
    this.netTotal = 0;
    this.couponDiscAmont = 0;
    this.cartList.forEach((x) => {
      x.total = x.quantity * x.discountPrice;
      this.subTotal = this.subTotal + x.total;
    });

    if(this.couponDetails && ( this.subTotal) > this.couponDetails.minAmount){
      this.couponDiscAmont = ((this.subTotal) * this.couponDetails.discountPercent)/ 100
    }
    this.netTotal  = this.subTotal + this.shippingAmount - this.couponDiscAmont ;
  }
  getCartListFromLocalStorageData() {
    this.db
      .methodPost('Carts/GetCartDetails', this.common.cartList)
      .subscribe((resdata) => {
        this.cartList = resdata;
        this.cartList.forEach((x) => {
          let element = this.common.cartList.find(
            (y) => y.stockId === x.stockId
          );
          x.quantity = element.quantity;
        });

        this.common.cartList = this.cartList;

        this.findTotal();
      });
  }
  getCartList() {
    this.db
      .methodGet('Carts/GetUserCart/' + this.common.userDetails.userId)
      .subscribe((resdata) => {
        this.cartList = resdata;
        this.common.cartCount = resdata.length;
        this.common.cartList = this.cartList;

        this.findTotal();
      });
  }


}
