<section class="bg-light">
  <div class="container">
    <div class="row align-items-center">
      <!-- <div class="col-md-4 col-4">
      <h1 class="h2 mb-0">Signup</h1>
    </div> -->
      <div class="col-md-12 mt-3 mt-md-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item"><a class="text-dark"><i class="las la-home mr-1"></i>{{'menu.Home' |
                translate}}</a>
            </li>
            <li class="breadcrumb-item active text-primary" aria-current="page">{{'menu.SignUp' | translate}}</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>



<div class="page-content">

  <!--login start-->

  <section class="register">
    <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-lg-8 col-md-12">
          <div class="mb-4">
            <h2 class="text-primary mb-1">
              {{'label.SimpleAndEasyToSignUp' | translate}}
            </h2>
            <!-- <h2>Simple And Easy To Sign Up</h2> -->
            <p class="lead"></p>
          </div>
        </div>
      </div>
      
      <div class="row">
        <div class="col-lg-8 col-md-10 ml-auto mr-auto">
          <div class="register-form text-center">
            <form id="contact-form" [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
              <div class="messages"></div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="signup">{{'label.UserName' | translate}} <span class="text-danger">*</span></label>
                    <input id="form_name" type="text" formControlName="UserName" name="name" class="form-control"
                      placeholder="{{'label.UserName' | translate}}">
                    <div class="help-block with-errors">
                      <div class="form-error"
                        *ngIf="(f.UserName.touched && f.UserName.errors) || (f.UserName.dirty && f.UserName.errors) ||  (submitted && f.UserName.errors)">
                        <div *ngIf="f.UserName.errors.required">{{'errors.UserNameIsRequired' | translate}}</div>
                        <div *ngIf="f.UserName.errors.pattern">{{'errors.SpacesNotAllowed' | translate}}</div>
                        <div *ngIf="f.UserName.errors.minlength && submitted">{{'errors.MinimumOf4Characters' |
                          translate}}</div>


                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-6">
              <div class="form-group">
                <label>Full Name <span class="text-danger">*</span></label>
                <input id="form_name" type="text" formControlName="UserFullName" name="name" class="form-control" placeholder="Full Name">
                <div class="help-block with-errors">
                  <div class="form-error"
                  *ngIf="(f.UserFullName.touched && f.UserFullName.errors) || (f.UserFullName.dirty && f.UserFullName.errors) ||  (submitted && f.UserFullName.errors)">
                  <div *ngIf="f.UserFullName.errors.required">First name is required</div>
                </div>
                </div>
              </div>
            </div> -->
                <!--<div class="col-md-6">
              <div class="form-group">
                <input id="form_lastname" type="text" formControlName="LastName" name="surname" class="form-control" placeholder="Last name">
                <div class="help-block with-errors">
                  <div class="form-error"
                  *ngIf="(f.LastName.touched && f.LastName.errors) || (f.LastName.dirty && f.LastName.errors) ||  (submitted && f.LastName.errors)">
                  <div *ngIf="f.LastName.errors.required">Last name is required</div>
                </div>
                </div>
              </div>
            </div>-->

                <!-- <div class="col-md-6">
              <div class="form-group">
                <label>Email <span class="text-danger">*</span></label>


                <input id="form_email" type="email" name="email" formControlName="Email" class="form-control" placeholder="Email">
                <div class="help-block with-errors">
                  <div class="form-error"
                  *ngIf="(f.Email.touched && f.Email.errors) || (f.Email.dirty && f.Email.errors) ||  (submitted && f.Email.errors)">
                  <div *ngIf="f.Email.errors.required">Valid email is required</div>
                </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Mobile <span class="text-danger">*</span></label>

                <input id="form_phone" type="tel" name="phone" formControlName="Mobile" class="form-control" placeholder="Phone">
                <div class="help-block with-errors">
                  <div class="form-error"
                  *ngIf="(f.Mobile.touched && f.Mobile.errors) || (f.Mobile.dirty && f.Mobile.errors) ||  (submitted && f.Mobile.errors)">
                  <div *ngIf="f.Mobile.errors.required">Mobile no is required</div>
                </div>
                </div>
              </div>
            </div> -->
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="signup">{{'label.Email/Mobile' | translate}} <span
                        class="text-danger">*</span></label>

                    <input id="form_phone" type="text" name="phone" formControlName="EmailOrMobile" class="form-control"
                      placeholder="{{'label.Email/Mobile' | translate}}">
                    <div class="help-block with-errors">
                      <div class="form-error"
                        *ngIf="(f.EmailOrMobile.touched && f.EmailOrMobile.errors) || (f.EmailOrMobile.dirty && f.EmailOrMobile.errors) ||  (submitted && f.EmailOrMobile.errors)">
                        <div *ngIf="f.EmailOrMobile.errors.required">{{'errors.Email/MobileNoIsRequired' | translate}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="col-md-6">
                  <div class="form-group">
                    <label class="signup">{{'label.Password' | translate}} <span class="text-danger">*</span></label>


                    <input id="form_password" type="password" name="password" formControlName="Password"
                      class="form-control" placeholder="{{'label.Password' | translate}}">
                    <div class="help-block with-errors">
                      <div class="form-error"
                        *ngIf="(f.Password.touched && f.Password.errors) || (f.Password.dirty && f.Password.errors) ||  (submitted && f.Password.errors)">
                        <div *ngIf="f.Password.errors.required">{{'errors.PasswordIsRequired' | translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--<div class="col-md-6">
              <div class="form-group" style="display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;">


                <select class="form-control classic" formControlName="GenderId">
                  <option selected>Gender</option>
                  <option *ngFor="let item of genderList" [value]="item.id">{{item.name}}</option>

                </select>
                <div class="help-block with-errors">
                  <div class="form-error"
                  *ngIf="(f.GenderId.touched && f.GenderId.errors) || (f.GenderId.dirty && f.GenderId.errors) ||  (submitted && f.GenderId.errors)">
                  <div *ngIf="f.GenderId.errors.required">Gender is required</div>
                </div>
                </div>
              </div>
            </div>-->


                <div class="col-md-6">
                  <div class="form-group">
                    <label class="signup">{{'label.ConfirmPassword' | translate}} <span
                        class="text-danger">*</span></label>


                    <input id="form_password1" type="password" name="password" formControlName="ConfirmPassword"
                      class="form-control" placeholder="{{'label.ConfirmPassword' | translate}}">
                    <div class="help-block with-errors">
                      <div class="form-error"
                        *ngIf="(f.ConfirmPassword.touched && f.ConfirmPassword.errors) || (f.ConfirmPassword.dirty && f.ConfirmPassword.errors) ||  (submitted && f.ConfirmPassword.errors)">
                        <div *ngIf="f.ConfirmPassword.errors.required">{{'errors.ConfirmPasswordIsRequired' |
                          translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group ">
                    <label class="signup">{{'label.Gender' | translate}} <span class="text-danger">*</span></label>

                    <select formControlName="GenderId" class="form-control" style="padding-right: 30px;" name="GenderId"
                      id="GenderId">
                      <option value="" disabled selected>{{'label.SelectGender' | translate}}</option>
                      <option *ngFor="let item of genderList" [value]="item.id">{{item.name}}</option>

                    </select>
                    <div class="help-block with-errors">
                      <div class="form-error"
                        *ngIf="(f.GenderId.touched && f.GenderId.errors) || (f.GenderId.dirty && f.GenderId.errors) ||  (submitted && f.GenderId.errors)">
                        <div *ngIf="f.GenderId.errors.required">{{'errors.GenderIsRequired' | translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="row" style="margin-top: 25px;">
                <div class="col-md-6">
                  <div class="remember-checkbox">
                    <div class="custom-control custom-checkbox mb-2">
                      <input type="checkbox" class="custom-control-input" id="customCheck1" formControlName="Agree">
                      <label class="custom-control-label " for="customCheck1">
                        <span>
                          {{'desc.SignUpDesc1' | translate}}
                        </span></label>
                    </div>                    
                    <div class="help-block with-errors">
                      <div class="form-error" *ngIf="(submitted && f.Agree.value != true && signUpForm.valid)">
                        <div style="color: red;">{{'desc.SignUpDesc2' | translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top: 25px; margin-bottom: 20px;">
                <div class="col-md-12">
                  <a (click)="onSubmit()" class="btn btn-primary btn-animated"
                    style="color: white;">{{'button.CreateAccount' | translate}}</a>
                  <span class="mt-4 d-block">{{'label.HaveAnAccount' | translate}} <a
                      routerLink="/login"><i>{{'button.SignIn' | translate}}</i></a></span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--login end-->

</div>