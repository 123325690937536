import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common-values/common.service';
import { Config } from 'src/app/common-values/config';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  menuList =[];
  className =""
  subCategoryList =[];
  shopByCategoryList =[]
  showSubMenu:boolean=false;
  showSearchDiv:boolean=false;
  searchQuery:string='';
  isSubMenu:boolean = false;
  timeOut;
  lang= Number(Config.lang);

  searchList =[]

  constructor(private db : DbService, public common : CommonService ,
    private confirmationDialogService  : ConfirmationService,
     private router : Router) {

      this.common.isLoggedIn
    // window.addEventListener('click', function(e){
    //   if (document.getElementById('clickbox').contains(e.target)){
    //     // Clicked in box
    //   } else{
    //     // Clicked outside the box
    //   }
    // });
   }

  ngOnInit(): void {
    this.getAllMenus();
    this.getShopByCategory();
    this.getCartList();

  }


  onCustomerServiceClick(){
    if(this.className.length == 0){
      this.className = "customerClass";
    }
    else {
      this.className = ""
    }
   }
  getCartList(){
    if(this.common.isLoggedIn){
      this.db.methodGet("Carts/GetUserCart/" + this.common.userDetails.userId).subscribe(resdata =>{
        this.common.cartCount = resdata.length;
        this.common.cartList = resdata;
      })
    }

  }
  logOut(){
    this.confirmationDialogService.confirm('Please Confirm ','Are you sure to logout ?')
    .then((confirmed) =>
    {
      if(confirmed){
        localStorage.clear();
        this.common.cartList = []
        this.common.cartCount =0
        this.common.isLoggedIn =false;
        this.router.navigate(['/'])
        this.toggleClose();
      }
    }
    )
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));


  }

  getShopByCategory(){
    this.db.methodOptions("Common/GetShopByCategories").subscribe(resdata =>{
      this.shopByCategoryList = resdata;
    })
  }

  navigate(element){
    let el = 0;
    this.router.navigate(['/product-list'] ,{
      queryParams: { name : element.categoryName  , brandId : btoa('0')  , categoryId : btoa(element.categoryId)
      , subCategoryId: btoa(el.toString())
    }})
  }

  onMouseEnter(){
    const element = <HTMLElement> document.getElementsByClassName('dropdown-content')[0];
    element.style.display = 'block';
  }

  onMouseLeave(){
    const element = <HTMLElement> document.getElementsByClassName('dropdown-content')[0];
    element.style.display = 'none';
  }
  myAccountNavigation(id){
   
     
      this.router.navigate(['/my-account'] ,{
        queryParams: { id : id
        }})
  }

  onMenuItemClick(element, master ?){

    let submenuName=""
    let submenuNameAr=""

    let submenuBrandName=""
    let submenuBrandNameAr=""


    let menuName = ""
    let menuNameAr = ""



    if(master){
     menuName = element.categoryName ;
     menuNameAr = element.categoryNameAr ;
    }


      submenuName = element.subCategoryName
      submenuNameAr = element.subCategoryNameAr
 
      submenuBrandName = element.brandName
      submenuBrandNameAr = element.brandNameAr







    this.router.navigate(['/product-list'] ,{
      queryParams: { subMenuName : submenuName  ,name : menuName , brandName :submenuBrandName,   brandId : btoa(element.brandId)  , categoryId : btoa(element.categoryId)  ,
        subCategoryId : btoa(element.subCategoryId)  , subMenuNameAr :submenuNameAr , brandNameAr : submenuBrandNameAr , nameAr : menuNameAr
      }})
    this.showSubMenu=false;
  }
  onSearchClickMobile(){
    document.getElementById("search-box").style.display = "block";

  }



  toggleClose(){
    var el = document.getElementById("navBar");
    el.classList.remove("min-navigation-add");
    var el = document.getElementById("bg-nav");
    el.classList.remove("menu-nav-bg");
    var el = document.getElementById("body");
    el.classList.remove("body_overflow");

    
  }

  onMenuToggleButtonClickMobile(){
    var el = document.getElementById("navBar");
    el.classList.add("min-navigation-add");

    var el = document.getElementById("bg-nav");
    el.classList.add("menu-nav-bg");

    var el = document.getElementById("body");
    el.classList.add("body_overflow");
  }
  onMenuItemClickMobileView(element){



    this.toggleClose()



    this.router.navigate(['/product-list'] ,{
      queryParams: { subMenuName : element.name  ,    brandId : btoa(element.brandId)  , categoryId : btoa(element.categoryId)  ,
        subCategoryId : btoa(element.subCategoryId)  ,
      }})
    this.showSubMenu=false;
  }
  onMenuClick(element ){

  let  menuname=""
  let  menunameAr=""

 if(element.categoryId >0){
  if(this.lang == 0){
    menuname =element.categoryName
    menunameAr =element.categoryNameAr
  }
 }
 else{
  if(this.lang == 0){
    menuname =element.menuName
    menunameAr =element.menuNameAr
  }
 }


    if(element.menuName !== "Homeaaa"){
      let el =0
      this.router.navigate(['/product-list'] ,{
        queryParams: { name : menuname  ,  brandId : btoa(el.toString())  , categoryId : btoa(element.categoryId)  ,
          subCategoryId : btoa(el.toString()) , nameAr : menunameAr  ,
        }})
      this.showSubMenu=false;
    }
    else{
      this.router.navigate(['/'])
    }

  }


  onSearchButtonClick(){
   let el=0;
    this.router.navigate(['/product-list'] ,{
      queryParams: { name : ''  , brandId : btoa(el.toString())  , categoryId : btoa(el.toString())  ,
        subCategoryId : btoa(el.toString())  , text: this.searchQuery
      }})
  }
  isLoading : boolean = false;
  onKeyUpSearch(){
    if(this.searchQuery.length > 0){
      this.showSearchDiv = true;
      this.searchList =[]
      let body = {
         ProductName : this.searchQuery
      }
      this.isLoading  = true;
      this.db.methodPost("Common/SearchProductByName" , body).subscribe(resdata =>{
        this.isLoading = false;
        this.searchList = resdata;
      })
    }
    else{
      this.showSearchDiv = false;
    }

  }

  navigateFromSearch(element){
    let isDetailsPage : boolean =false
    if(this.router.url.includes('/product-details')){
      isDetailsPage = true;
    }
    if(isDetailsPage){
      this.router.navigate(['/product-detailss'] ,{
        queryParams: { productName : element.productName  , productId : btoa(element.productId)} });
    }
    else{
      this.router.navigate(['/product-details'] ,{
        queryParams: { productName : element.productName  , productId : btoa(element.productId)} });
    }

    this.showSearchDiv=false;
    this.searchQuery = '';
    // if(isDetailsPage){
    //   window.location.reload();
    // }
  }

  navigateToWishList(){
    if(this.common.isLoggedIn){
      this.router.navigate(['/wishlist'])
    }
    else{
      this.router.navigate(['/login'])
    }
  }
  navigateToProfile(){
    if(this.common.isLoggedIn){
      this.router.navigate(['/my-account'])
    }
    else{
      this.router.navigate(['/login'])
    }
  }


  getAllMenus(){
    this.db.methodOptions('Common/GetMenuItems').subscribe(resdata =>{
      
      this.menuList = resdata;
    })
  }


  selectLang(key) {
    if (key == 0) {
      localStorage.setItem("lang", "0");
      window.location.reload();
    } else if (key == 1) {
      localStorage.setItem("lang", "1");
      window.location.reload();

    } else if (key == 2) {
      localStorage.setItem("lang", "2");
      window.location.reload();
    }
  }

  showSearchBox(){
    // if(this.searchQuery.length == 0){
    //   this.showSearchDiv = false;
    // }
    // else{
    //   this.showSearchDiv = true;
    // }
    // console.log(this.searchQuery);
  }

  leaveSearch(){
    var root = this;
    this.timeOut = setTimeout(function(){

      root.searchQuery =""

      root.showSearchDiv=false;
    }, 200);
  }

  clearTimerBox(){
    clearTimeout(this.timeOut);
  }

  subMenus(item){
    this.isSubMenu = true;
    // this.subCategoryList = item;
    this.subCategoryList = item.shopByProduct;

    // this.subCategoryList = ['Shoes','Tops','Kurtha'];
  }


  @HostListener('document:click', ['$event']) clickout(event) {
    // Click outside of the menu was detected
    this.leaveSearch()

  }
  handleAsideClick(event: Event) {
    event.stopPropagation(); // Stop the propagation to prevent reaching document
  }
}
