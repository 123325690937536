import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TouchSequence } from 'selenium-webdriver';
import { CommonService } from 'src/app/common-values/common.service';
import { Config } from 'src/app/common-values/config';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { DbService } from 'src/app/services/db.service';
declare function showNotification(type : string,titile : string,message :string): any;

@Component({
  selector: 'app-product-cart',
  templateUrl: './product-cart.component.html',
  styleUrls: ['./product-cart.component.scss']
})
export class ProductCartComponent implements OnInit {
  couponCode : string =""
  cartList =[];
  lang = Number(Config.lang)  ;
  subTotal : number = 0;
  shippingAmount : number = 0;
  netTotal : number =0;
  default_currencyformat = Config.currencyFormat;
  isMobileView:boolean = false;
  constructor(private db : DbService , private common : CommonService,
    private router :  Router,
    private confirmationDialogService  : ConfirmationService,

    private spinner : NgxSpinnerService,
    private datePipe : DatePipe) { }

  ngOnInit(): void {


    if(this.common.isLoggedIn){
      this.getCartList(1);
    }
    else {
      this.getCartListFromLocalStorageData(1)
    }
  if(window.screen.width<768){ this.isMobileView = true; }


}










  couponDetails ;


  onRemoveCoupon(){
    localStorage.removeItem('coupon')
    this.couponCode =""
    this.couponDetails = {}
    this.findTotal();

  }
  applyCoupon(){


    let userId = 0;
    if(this.common.userDetails && this.common.userDetails?.userId){
      userId = this.common.userDetails?.userId
    }
    let body = {
      couponCode : this.couponCode,
      userId : userId
    }
    console.log(body);

     this.db.methodPost("Configs/ApplyCoupon/" , body ).subscribe(resdata =>{

       if(resdata.couponId > 0 ){
          if( ( this.subTotal  > resdata.minAmount)){
            this.couponDetails = resdata;
            localStorage.setItem('coupon', this.couponCode);
            this.findTotal();
          }
          else{
         showNotification('error','Not Valid !', 'Coupon Only Applicable for Order Above QR.' + resdata.minAmount + '');
         this.onRemoveCoupon();
          }

       }
       else{
         localStorage.removeItem('coupon')
         this.couponCode =""
         this.couponDetails = {}
         this.findTotal();

        }


     }
     ,error =>{
      this.couponDetails = {}
      localStorage.removeItem('coupon')
      this.findTotal();
      showNotification('error','Failed !', error.error.message);
     }
     )
  }



  getCartListFromLocalStorageData(key?){
    this.spinner.show();
     this.db.methodPost("Carts/GetCartDetails" , this.common.cartList).subscribe(resdata =>{


       this.spinner.hide();

       this.cartList = resdata;
       this.cartList.forEach(x =>{

         let element = this.common.cartList.find(y => y.stockId ===  x.stockId);
         x.quantity = element.quantity;
       })



       this.common.cartList = this.cartList;

      this.findTotal();

      if(key == 1){
        if(localStorage.getItem('coupon') != undefined){
          this.couponCode = localStorage.getItem('coupon');
          this.applyCoupon();
       }
      }

     })
  }
  getCartList(key?){
    this.spinner.show();
    this.db.methodGet("Carts/GetUserCart/" + this.common.userDetails.userId).subscribe(resdata =>{

      this.spinner.hide();
      this.cartList = resdata;
      this.common.cartCount = resdata.length;
      this.common.cartList = this.cartList;

      this.findTotal();

      if(key ==1){
        if(localStorage.getItem('coupon') != undefined){
          this.couponCode = localStorage.getItem('coupon');
          this.applyCoupon();
       }
      }

    })
  }

  deleteCartItem(item){
    this.confirmationDialogService.confirm('Please Confirm ','Do you want to remove this item?')
    .then((confirmed) =>
    {
      if(confirmed){

        if(this.common.isLoggedIn){
          this.spinner.show();
          this.db.methodDelete("Carts/" + item.cartId).subscribe(resdata =>{
            this.spinner.hide();
            if(resdata.cartId > 0){
             this.onRemoveCoupon();
              showNotification('success','Success','Successfully deleted !');
              this.getCartList();
            }

          })
       }
       else{

        let indexOf;
        // let element = this.common.cartList.find((x, index) => {
        //   if(x.stockId == item.stockId ){
        //     indexOf = index;
        //   };
        // });
        // this.common.cartList.splice(indexOf, 1);
        let element2 = this.cartList.find((x, index) => {
          if(x.stockId == item.stockId ){
            indexOf = index;
          };
        });
        this.cartList.splice(indexOf, 1);
        this.common.cartList = this.cartList;
        localStorage.setItem('cart', JSON.stringify(this.cartList))
        this.onRemoveCoupon();
        this.findTotal();
      }
        // this.commonService.cartList.splice(index, 1);
      }
    }
    )
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));




  }
  findTotal(){
    this.subTotal = 0;
    this.netTotal = 0;
    this.couponDiscAmont =0;
    this.cartList.forEach(x =>{
       x.total = x.quantity * x.discountPrice;
       this.subTotal = this.subTotal + x.total;

      //  x.availableStock = 0;
      if(x.availableStock < x.quantity && x.availableStock > 0){
        x.outOfStockSelected = true;
      }
      else{
        x.outOfStockSelected = false;

      }
       if(x.availableStock <= 0){
        x.outOfStock = true;
      }
      else{
        x.outOfStock = false;

      }
    })


    if(this.couponDetails && ( this.subTotal) > this.couponDetails.minAmount){
      this.couponDiscAmont = ((this.subTotal) * this.couponDetails.discountPercent)/ 100
    }
    this.netTotal  = this.subTotal + this.shippingAmount - this.couponDiscAmont ;





  }

  couponDiscAmont = 0;
  navigate(){
    let el =0
    this.router.navigate(['/product-list'] ,{
      queryParams: { name : 'View All'  , brandId : btoa(el.toString())  , categoryId : btoa(el.toString())  ,
        subCategoryId : btoa(el.toString())  ,
      }})
  }

  onCheckOut(){
    if(this.cartList.length == 0){
      alert('please add atleast one item to your cart');
      return;
    }
    this.router.navigate(['/cart-check-out'])
    // if(confirm('are you sure to continue')){
    // //   let body = {
    // //     "userId" : 5,
    // //     "addressId" : 2,
    // //     "date" : "2021-02-18",
    // //     "orderDetails" :
    // //     [
    // //         {
    // //             "productId" : 3,
    // //             "stockId" : 9,
    // //             "price" : 100,
    // //             "taxAmount" : 0,
    // //             "discountPercent" : 10,
    // //             "quantity" : 1
    // //         },
    // //         {
    // //             "productId" : 4,
    // //             "stockId" : 10,
    // //             "price" : 94,
    // //             "taxAmount" : 0,
    // //             "discountPercent" : 20,
    // //             "quantity" : 2
    // //         }
    // //     ]
    // // }
    //   let body : any  = {}
    //   body.userId = this.common.userDetails.userId;
    //   body.addressId =2 ;
    //   body.date = this.datePipe.transform(new Date , 'yyyy-MM-dd');
    //   body.totalAmount = this.netTotal;

    //   body.orderDetails = this.cartList;
    //   console.log(body);
    //   this.db.methodPost("order", body).subscribe(resdata =>{
    //
    //     if(resdata.orderId > 0){
    //       this.router.navigate(['/order-success'])
    //     }
    //   })

    // }


  }
  onQtyChange(item, key){
    if(key === 1){

      if(item.quantity != 1){
        item.quantity = item.quantity - 1;
      }
    }
    else{
      if(item.quantity >= item.availableStock ){
        showNotification('error','Failed','Sorry ! only ' + item.availableStock + ' quantity available');
      }
      else{
        item.quantity = item.quantity + 1;
      }
    }


    if(this.common.isLoggedIn){
      let body  ={
        cartId : item.cartId,
        quantity : item.quantity
      }
      this.db.methodPost("Carts/UpdateCartQuantity", body).subscribe(resdata =>{
        //
      })
    }

    localStorage.setItem('cart' , JSON.stringify(this.cartList));
    this.common.cartList = this.cartList

    this.findTotal();

  }

  onChange(evet){
  }
}
