


<section class="bg-light">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-12 mt-3 mt-md-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item"><a class="text-dark" href="#"><i class="fa fa-home mr-1"></i>{{'menu.Home' | translate}}</a>
            </li>
            <li class="breadcrumb-item active text-primary" aria-current="page">{{'menu.ShippingInformation' | translate}}</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
  </section>


  <div class="page-content">

    <section>
      <div class="container">
        <div class="row align-items-end mb-5">
          <div class="col-12 col-md-12 col-lg-12">
            <div>
  
              <h2 class="mb-0">{{'privacyPolicy.text41' | translate}}</h2>
            </div>
        </div>
            <div class="col-12 col-md-12 col-lg-12">
  
              <div>
                <p class="lead mb-0">
                  •	{{'privacyPolicy.text42' | translate}}<br/>
  •	{{'privacyPolicy.text43' | translate}}<br/>
  •	{{'privacyPolicy.text44' | translate}}<br/>
  •	{{'privacyPolicy.text45' | translate}}<br/>
  •	{{'privacyPolicy.text46' | translate}}<br/>
  •	{{'privacyPolicy.text47' | translate}}<br/>
  •	{{'privacyPolicy.text48' | translate}}<br/>
  •	{{'privacyPolicy.text49' | translate}}<br/>
  •	{{'privacyPolicy.text50' | translate}}
  <br/>
  {{'privacyPolicy.text51' | translate}}
  {{'privacyPolicy.text52' | translate}}
  
              </p>
              </div>
              <div>
  
              </div>
            </div>
  
        </div>
      </div>
    </section>

    </div>

  <!--body content end-->










