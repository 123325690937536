import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common-values/common.service';
import { Config } from 'src/app/common-values/config';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
 lang = Number(Config.lang)
  constructor(public common : CommonService) { }

  ngOnInit(): void {
  }

}
