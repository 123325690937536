import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './views/pages/about-us/about-us.component';
import { CartCheckOutComponent } from './views/pages/cart-check-out/cart-check-out.component';
import { CartCheckoutPageComponent } from './views/pages/cart-checkout-page/cart-checkout-page.component';
import { CheckOutComponent } from './views/pages/check-out/check-out.component';
import { ComingSoonComponent } from './views/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './views/pages/contact/contact.component';
import { FaqComponent } from './views/pages/faq/faq.component';
import { ForgotPasswordComponent } from './views/pages/forgot-password/forgot-password.component';
import { LandingComponent } from './views/pages/landing/landing.component';
import { ActivateGuard } from './views/pages/login/activate.guard';
import { LoginComponent } from './views/pages/login/login.component';
import { MyAccountDetComponent } from './views/pages/my-account-det/my-account-det.component';
import { MyAccountComponent } from './views/pages/my-account/my-account.component';
import { NavigatorComponent } from './views/pages/navigator/navigator.component';
import { OrderCompleteComponent } from './views/pages/order-complete/order-complete.component';
import { PrivacyPolicyComponent } from './views/pages/privacy-policy/privacy-policy.component';
import { ProductCartComponent } from './views/pages/product-cart/product-cart.component';
import { ProductDetailsComponent } from './views/pages/product-details/product-details.component';
import { ProductListComponent } from './views/pages/product-list/product-list.component';
import { ProfileComponent } from './views/pages/profile/profile.component';
import { ReturnPolicyComponent } from './views/pages/return-policy/return-policy.component';
import { ShippingInformationComponent } from './views/pages/shipping-information/shipping-information.component';
import { SignUpComponent } from './views/pages/sign-up/sign-up.component';
import { TermsAndConditionsComponent } from './views/pages/terms-and-conditions/terms-and-conditions.component';
import { VendorRegComponent } from './views/pages/vendor-reg/vendor-reg.component';
import { VerifyCustomerComponent } from './views/pages/verify-customer/verify-customer.component';
import { WishListComponent } from './views/pages/wish-list/wish-list.component';

const routes: Routes = [
  { path : '' , component : LandingComponent},
  { path : 'about' , component : AboutUsComponent},
  { path : 'check-out' , component : CheckOutComponent},
  { path : 'contact' , component : ContactComponent},
  { path : 'login' , component : LoginComponent},
  { path : 'order-success' , component : OrderCompleteComponent},
  { path : 'product-detailss' , component : NavigatorComponent},
  { path : 'verify-customer' , component : VerifyCustomerComponent},


  { path : 'product-cart' , component : ProductCartComponent},
  { path : 'product-details' , component : ProductDetailsComponent},
  { path : 'product-list' , component : ProductListComponent},
  { path : 'profile' , component : ProfileComponent ,  canActivate:[ActivateGuard]},
  { path : 'my-account' , component : MyAccountDetComponent},
  { path : 'my-accounts' , component : MyAccountDetComponent},

  { path : 'signup' , component : SignUpComponent},
  { path : 'wishlist' , component : WishListComponent ,  canActivate:[ActivateGuard]},
  { path : 'coming-soon' , component : ComingSoonComponent },
  { path : 'cart-check-outt' , component : CartCheckOutComponent },
  { path : 'cart-check-out' , component : CartCheckoutPageComponent },

  { path : 'forgot-password' , component : ForgotPasswordComponent },
  { path : 'privacy-policy' , component : PrivacyPolicyComponent },
  { path : 'shipping-information' , component : ShippingInformationComponent },
  { path : 'faq' , component : FaqComponent },
  { path : 'return-policy' , component : ReturnPolicyComponent },
  { path : 'terms-and-conditions' , component : TermsAndConditionsComponent },
  { path : 'vendor-registration' , component : VendorRegComponent },

  {
    path: 'success',
    loadChildren: () => import('./views/pages/payment-success/payment-success.module')
      .then(m => m.PaymentSuccessModule)
  },
  {
    path: 'failed',
    loadChildren: () => import('./views/pages/payment-failed/payment-failed.module')
      .then(m => m.PaymentFailedModule)
  },



















];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
