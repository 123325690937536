


<section class="bg-light">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-12 mt-3 mt-md-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item"><a class="text-dark" href="#"><i class="fa fa-home mr-1"></i>{{'menu.Home' | translate}}</a>
            </li>
            <li class="breadcrumb-item active text-primary" aria-current="page">{{'header.AboutUs' | translate}}</li>
          </ol>
        </nav>
      </div>
    </div>
   
  </div>
  
  </section>


  <div class="page-content"  style="height: px;">

    <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
         
        <div class="page" >
          <h3 class=""> {{'header.AboutUs' | translate}}</h3>
          <p class="lead mb-0">
            <span *ngIf="lang ==0">
              ANBAR.NET is an ecommerce registered under MOCI, Shop online for the latest fashion for women, men, and children. ANBAR.NET offers a large selection of shoes, bags, and accessories from the world’s most coveted fashion brands.
                  </span>
<span *ngIf="lang ==1">
  ANBAR.NET هي  شركة تجارة إلكترونية مسجلة تحت وزارة التجارة والصناعه بدولة قطر  ، تسوق عبر الإنترنت للحصول على أحدث صيحات الموضة للنساء والرجال والأطفال. تقدم ANBAR.NET مجموعة كبيرة من الأحذية والحقائب والإكسسوارات من أشهر ماركات الأزياء العالمية.
</span>


          </p>
        </div>




          <!--<div class="d-md-flex align-items-end justify-content-between border-top pt-5">
            <div>
              <label class="text-dark h4" for="coupon">Coupon</label>
              <p>Enter your coupon code if you have one.</p>
              <div class="row form-row">
                <div class="col">
                  <input class="form-control" id="coupon" placeholder="Coupon Code" type="text">
                </div>
                <div class="col col-auto">
                  <button class="btn btn-dark btn-animated">Apply Coupon</button>
                </div>
              </div>
            </div>
            <button class="btn btn-primary btn-animated mt-3 mt-md-0">Update Cart</button>
          </div>-->
        </div>


        </div>

    </div>
    </section>

    </div>

  <!--body content end-->










