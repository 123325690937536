<!-- <button (click)="changeImage()">Change</button>
 
<h1>Image Magnifier</h1>
<p>Move your mouse over the image</p>
<div class="main">
    <img [src]=imageUrl
         id="gfg-img"
        height="300px" width="300px" />
    <div class="zoom-preview"></div>
</div> -->


<section class="bg-light">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-12 mt-3 mt-md-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item"><a class="text-dark" routerLink="/" style="color:rgb(0, 0, 0);">
                <i class="fa fa-home mr-1"></i>{{'menu.Home' | translate}}</a>
            </li>
            <li (click)="onCategBreadCumb()" style="cursor: pointer;" class="breadcrumb-item" style="">
              <span *ngIf="lang == 0">
                {{selectedProductDetails?.categoryName}}
              </span>
              <span *ngIf="lang == 1">
                {{selectedProductDetails?.categoryNameAr}}
              </span>

            </li>
            <li (click)="onSubCategBreadCumb()" style="cursor: pointer;" class="breadcrumb-item" style="">
              <span *ngIf="lang == 0">
                {{selectedProductDetails?.subCategoryName}}
              </span>
              <span *ngIf="lang == 1">
                {{selectedProductDetails?.subCategoryNameAr}}
              </span>

            </li>

            <!-- <li class="breadcrumb-item active text-primary" aria-current="page" style="color:#d12229;">{{'menu.ProductDetail' | translate}}</li> -->
          </ol>
        </nav>

      </div>
      <div class="col-md-6 col-8">

      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>

<div class="page-content">

  <!--product details start-->

  <section>


    <div class="container">
      


    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-5" id="outer_container">




            <div class="quick-view-product-image">
              <img [src]="imagePath"/>

            </div>
            <div class="main">


              <div class="containers flex"  >
                <span *ngFor="let item of selectedProductDetails?.productImages">
                  <div class="item-product-quick-view flex-item" (mouseenter)="imagePath = item.imagePath">
                    <img src="{{item.imagePath}}"/>
                  </div>
                </span>
                <!-- <div class="item flex-item">
                  <img src="https://anbar.clevericsolutions.com/webapi/Images/Products/913b1ca6-2f87-42ea-b133-c5ff70805d41.jpg"/>

                </div>
                <div class="item flex-item">
                  <img src="https://anbar.clevericsolutions.com/webapi/Images/Products/913b1ca6-2f87-42ea-b133-c5ff70805d41.jpg"/>
                </div>
                <div class="item flex-item">
                  <img src="https://anbar.clevericsolutions.com/webapi/Images/Products/913b1ca6-2f87-42ea-b133-c5ff70805d41.jpg"/>
                </div>
                <div class="item flex-item">
                  <img src="https://anbar.clevericsolutions.com/webapi/Images/Products/913b1ca6-2f87-42ea-b133-c5ff70805d41.jpg"/>
                </div>
                <div class="item flex-item">
                  <img src="https://anbar.clevericsolutions.com/webapi/Images/Products/913b1ca6-2f87-42ea-b133-c5ff70805d41.jpg"/>
                </div> -->


              </div>
            </div>



          <!-- <div class="container_zoom" id="inner_container">
            <div class="exzoom hidden" id="exzoom">
              <div class="exzoom_img_box">
                <ul class='exzoom_img_ul'>
                  <li *ngFor="let item of this.selectedProductDetails?.productImages"><img src="{{item.imagePath}}" />
                  </li>
                </ul>
              </div>
              <div class="exzoom_nav"></div>
             
            </div>
          </div> -->
          <!-- <ul id="imageGallery">
            <li data-thumb="../../../../assets/assets/images/product/01.jpg" data-src="../../../../assets/assets/images/product/01.jpg">
              <img class="img-fluid w-100" [src]="imagePath" alt="" />
            </li>
            <li data-thumb="../../../../assets/assets/images/product/03.jpg" data-src="../../../../assets/assets/images/product/03.jpg">
              <img class="img-fluid w-100" [src]="imagePath1" alt="" />
            </li>
            <li data-thumb="../../../../assets/assets/images/product/11.jpg" data-src="../../../../assets/assets/images/product/11.jpg">
              <img class="img-fluid w-100" [src]="imagePath2" alt="" />
            </li>
            <li data-thumb="../../../../assets/assets/images/product/12.jpg" data-src="../../../../assets/assets/images/product/12.jpg">
              <img class="img-fluid w-100" [src]="imagePath3" alt="" />
            </li>
          </ul> -->
          <!-- <ul id="imageGallery">
            <li *ngIf="imagePath" data-thumb="{{imagePath}}" data-src="{{imagePath}}">
              <img class="img-fluid w-100" [src]="imagePath" alt="" />
            </li>
            <li *ngIf="imagePath1" data-thumb="{{imagePath1}}" data-src="{{imagePath1}}">
              <img class="img-fluid w-100" [src]="imagePath1" alt="" />
            </li>
            <li *ngIf="imagePath2" data-thumb="{{imagePath2}}" data-src="{{imagePath2}}">
              <img class="img-fluid w-100" [src]="imagePath2" alt="" />
            </li>
            <li *ngIf="imagePath3" data-thumb="{{imagePath3}}" data-src="{{imagePath3}}">
              <img class="img-fluid w-100" [src]="imagePath3" alt="" />
            </li>
          </ul> -->
          <!-- <ul id="imageGallery">
            <li *ngFor="let item of this.selectedProductDetails?.productImages" data-thumb="../../../../assets/assets/images/product/01.jpg" data-src="../../../../assets/assets/images/product/01.jpg" >
              <img class="img-fluid w-100" src="{{item.imagePath}}" alt="" />
            </li>
          </ul> -->
          <!--   <div class="bzoom_wrap">
        <ul id="bzoom">
            <li *ngFor="let item of this.selectedProductDetails?.productImages">
                <img class="bzoom_thumb_image" src="{{item.imagePath}}" title="first img" />
                <img class="bzoom_big_image" src="{{item.imagePath}}"/>
            </li>
          </ul>-->
        </div>
        <div class="col-lg-7 col-12 mt-5 mt-lg-0">
          <div class="product-details">
            <h3 class="mb-0">
              <span *ngIf="lang == 0">
                {{selectedProductDetails?.productName}}
              </span>
              <span *ngIf="lang == 1">
                {{selectedProductDetails?.productNameAr}}
              </span>
            </h3>
            <p class="mb-1">

              <span *ngIf="lang == 0">
                {{selectedProductDetails?.productDescription}}
              </span>
              <span *ngIf="lang == 1">
                {{selectedProductDetails?.productDescriptionAr}}
              </span>

            </p>

            <ngb-rating [max]="5" [(rate)]="productRating" [readonly]="true" ></ngb-rating>


            <!-- <div class="star-rating mb-1 float-right">
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
            </div> -->

            <div class="clear"></div>
            <div>

              <span class="product-price h6" *ngIf="selectedStock.stockId != undefined">

                <span *ngIf="selectedStock?.discountPercent > 0">
                  QR {{selectedStock?.discountPrice | number:default_currencyformat}}
                  <del class="text-muted h6">QR {{selectedStock?.price | number:default_currencyformat}} </del>
                </span>

                <span *ngIf="selectedStock?.discountPercent == 0">
                  QR {{selectedStock?.price | number:default_currencyformat}}
                </span>

              </span>
              <!-- <span class="product-price h6" *ngIf="selectedStock.stockId == undefined">
                <span *ngIf="selectedProductDetails?.discount > 0">
                  QR {{selectedProductDetails?.discountPrice | number:default_currencyformat}}
                  <del class="text-muted h6">QR {{selectedProductDetails?.price | number:default_currencyformat}}  </del>
                </span>

                <span *ngIf="selectedProductDetails?.discount == 0">
                  QR {{selectedProductDetails?.price | number:default_currencyformat}}
                </span>


              </span> -->
            </div>

            <div class="clear"></div>
            <div class="text-center product-price">
              <span *ngFor="let item of selectedProductDetails?.colors; let i=index;" class="pro_details">
                <label class="radio radio-gradient ">
                  <span class="radio__input ">
                    <input type="radio" name="radio" class="" [value]="item?.colorId" [(ngModel)]="selectedColor"
                      (change)="onRadioColorChange(item,2)">
                    <span class="radio__control" [style.background-color]="item?.colorCode"></span>
                  </span>
                  <span class="radio__label"></span>

                </label>
              </span>

            </div>


            <div class="clear"></div>
            <select *ngIf="sizeOptionsList.length > 0" class="custom-select col-lg-3 col-md-12 " id="inputGroupSelect01" [(ngModel)]="selectedSizeMaster">
              <!-- <option disabled value="0">Size</option> -->
              <option [value]="item?.id" *ngFor="let item of sizeMasterList">{{item.name}}</option>
            </select>


            <select *ngIf="sizeOptionsList.length > 0" class="custom-select col-lg-3 col-md-12" id="inputGroupSelect01" [(ngModel)]="selectedSizeOption"
              (change)="onRadioSizeChange()">
              <option disabled value="0">{{'label.Size' | translate}}</option>
              <option [value]="item?.sizeOptionId" *ngFor="let item of sizeOptionsList">

                <span *ngIf="selectedSizeMaster == 0"> {{item?.eUsizeOption}} </span>
                <span *ngIf="selectedSizeMaster == 1"> {{item?.uKsizeOption}} </span>
                <span *ngIf="selectedSizeMaster == 2"> {{item?.uSsizeOption}} </span>


              </option>
            </select>
            <div style="clear: both;"></div>

            <div class="col-md-12" style="margin-top: 8px;">


              <div class="row">
                <div class="align-items-center">
                  <button class="btn-product btn-product-up" (click)="onQtyChange(1)"> <i class="fa fa-minus"></i>
                  </button>
                  <input class="form-product" type="number" readonly [(ngModel)]="selectedQty">
                  <button class="btn-product btn-product-down" (click)="onQtyChange(2)"> <i class="fa fa-plus"></i>
                  </button>
                </div>

                <!-- <div class="row" style="margin:10px 5px">
                  <div class="align-items-center">
                    <img class="ruler" src="https://anbar.net/assets/images/icons/ruler.svg" alt="">
                    <a style="text-decoration: underline; cursor: pointer; font-size:13px;" data-toggle="modal"
                      data-target="#sizechart">
                      {{'label.SizeGuide' | translate}}</a>
                  </div>
                </div> -->

              </div>

            </div>







            <div style="clear: both;"></div>

            <div class="align-items-center quickviewsize">
              <ul class="list-unstyled ">
                <li><span class="label">{{'label.Availability' | translate}}:</span>
                  <span class="text-muted" *ngIf="outOfStock == false;"> {{'label.InStock' | translate}}</span>
                  <span class="text-muted" style="color: red !important" *ngIf="outOfStock == true;">
                    {{'label.OutOfStock' | translate}}</span>
                </li>
                <li>
                  <a _ngcontent-vuo-c42="" *ngIf="outOfStock && common.isLoggedIn == true"
                    style="color: #ff0000; cursor: pointer; text-decoration: underline; margin-top: -10px;"
                    (click)="notifyMe()"> {{'desc.NotifyMe' | translate}}</a>

                  <a _ngcontent-vuo-c42="" *ngIf="outOfStock && common.isLoggedIn != true" data-toggle="modal"
                    data-target="#notifyMe"
                    style="color: #ff0000; cursor: pointer; text-decoration: underline; margin-top: -10px;"> {{'desc.NotifyMe' | translate}}</a>
                </li>
                <li><span class="label">{{'label.Categories' | translate}} :</span><span class="text-muted">
                    {{'label.Women' | translate}}

                  </span>
                </li>
                <li *ngIf="selectedStock?.skuCode"><span class="label">SKU :</span><span class="text-muted">
                    {{selectedStock?.skuCode}}
                  </span>
                </li>
                <li>
                  <p class="mb-4">


                    <span *ngIf="lang ==0">
                      {{selectedProductDetails?.productDescription}}

                    </span>
                    <span *ngIf="lang ==1">
                      {{selectedProductDetails?.productDescriptionAr}}

                    </span>

                  </p>

                </li>
              </ul>
            </div>

            <div class="d-sm-flex align-items-center quickviewsizebutton">
              <button class="btn btn-primary btn-animated  btn-align" (click)="addToCart()">
                <i class="fa fa-shopping-cart mr-1"></i>&nbsp; {{'button.AddToCart' | translate}}</button>
              <a class="btn btn-animated btn-dark btn-align" (click)="addWishList()"
                style="color: rgb(255, 255, 255) !important;">
                <i class="fa fa-heart mr-1" style="color: rgb(255, 255, 255) !important;"></i> {{'button.AddToWishList'
                | translate}}
              </a>
            </div>
            <!-- <div class="d-flex align-items-center border-top border-bottom py-4 mt-5 quickviewsize">
              <h6 class="mb-0 mr-4">{{'label.ShareIt' | translate}}:</h6>
              <ul class="list-inline">
                <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2"><i
                      class="fa fa-facebook"></i></a>
                </li>
                <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2"><i
                      class="fa fa-dribbble"></i></a>
                </li>
                <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2"><i
                      class="fa fa-instagram"></i></a>
                </li>
                <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2"><i class="fa fa-twitter"></i></a>
                </li>
                <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2"><i
                      class="fa fa-linkedin"></i></a>
                </li>
              </ul>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="clear"></div>
  <div class="col-md-12">
    <section style="margin: 15px 0px 0px 0px;">
      <div class="container">
        <div class="row" style="position: relative; margin: auto; height: auto; clear: inherit;">
          <div class="col-md-12">
            <div class="tab">
              <!-- Nav tabs -->
              <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist"> <a class="nav-item nav-link active" id="nav-tab1"
                    data-toggle="tab" href="#tab3-1" role="tab" aria-selected="true">{{'label.Description' |
                    translate}}</a>
                  <a class="nav-item nav-link" id="nav-tab2" data-toggle="tab" href="#tab3-2" role="tab"
                    aria-selected="false">{{'label.Specification' | translate}}</a>
                  <a class="nav-item nav-link" (click)="onRatingClick()" id="nav-tab3" data-toggle="tab" href="#tab3-3" role="tab"
                    aria-selected="false">{{'label.RatingsAndReviews' | translate}}</a>
                </div>
              </nav>
              <!-- Tab panes -->

              <div class="tab-content pt-0 p-2">

                <div role="tabpanel" class="tab-pane fade show active" id="tab3-1">

                  <div class="col-md-12">
                    <div class="row align-items-center">
                      <div class="col-md-4">
                        <img class="img-fluid w-100" [src]="selectedProductDetails?.productImages[0]?.imagePath" alt="">
                      </div>

                      <div class="col-md-8 mt-5 mt-lg-0">
                        <h3 class="mb-3">
                          <span *ngIf="lang == 0">
                            {{selectedProductDetails?.productName}}
                          </span>
                          <span *ngIf="lang == 1">
                            {{selectedProductDetails?.productNameAr}}
                          </span>

                        </h3>
                        <p>
                          <span *ngIf="lang == 0">
                            {{selectedProductDetails?.productLongDesc}}
                          </span>
                          <span *ngIf="lang == 1">
                            {{selectedProductDetails?.productLongDescAr}}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>



                <div role="tabpanel" class="tab-pane fade" id="tab3-2">


                  <img [src]="selectedProductDetails?.attributeUrl" alt="">
                  <table class="table table-bordered mb-0">
                    <tbody>
                      <tr *ngFor="let item of selectedProductDetails?.productAttributes">
                        <td>{{item?.attributeName}}</td>
                        <td>{{item?.value}}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
                <div role="tabpanel" class="tab-pane fade" id="tab3-3">
                  <div class="row align-items-center">

                    <div class="col-md-3">
                      <div class="text-center p-5">
                        <h4>Based on {{rating[0]?.reviewCount}} Reviews</h4>
                        <h5>Average</h5>
                        <h4>{{productRating}}</h4>
                        <h6>({{rating[0]?.reviewCount}} Reviews)</h6>
                      </div>
                    </div>

                    <div class="col-md-3 mt-3 mt-lg-0">
                      <div class="rating-list">
                        <div class="d-flex align-items-center mb-2">
                          <div class="text-nowrap mr-3">5 Star</div>
                          <div class="w-100">
                            <div class="progress" style="height: 5px;">
                              <div class="progress-bar bg-success" role="progressbar" [style.width.%]="rating[0]?.fiveStar"
                               [attr.aria-valuenow]="rating[0]?.fiveStar" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div><span class="text-muted ml-3">{{rating[0]?.fiveStar}}%</span>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                          <div class="text-nowrap mr-3">4 Star</div>
                          <div class="w-100">
                            <div class="progress" style="height: 5px;">
                              <div class="progress-bar bg-success" role="progressbar" [style.width.%]="rating[0]?.fourStar"
                                aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div><span class="text-muted ml-3">{{rating[0]?.fourStar}}%</span>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                          <div class="text-nowrap mr-3">3 Star</div>
                          <div class="w-100">
                            <div class="progress" style="height: 5px;">
                              <div class="progress-bar bg-success" role="progressbar" [style.width.%]="rating[0]?.threeStar"
                                aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div><span class="text-muted ml-3">{{rating[0]?.threeStar}}%</span>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                          <div class="text-nowrap mr-3">2 Star</div>
                          <div class="w-100">
                            <div class="progress" style="height: 5px;">
                              <div class="progress-bar bg-warning" role="progressbar" [style.width.%]="rating[0]?.twoStar"
                                aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div><span class="text-muted ml-3">{{rating[0]?.twoStar}}%</span>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                          <div class="text-nowrap mr-3">1 Star</div>
                          <div class="w-100">
                            <div class="progress" style="height: 5px;">
                              <div class="progress-bar bg-danger" role="progressbar" [style.width.%]="rating[0]?.oneStar"
                                aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                          </div><span class="text-muted ml-3">{{rating[0]?.oneStar}}%</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">

                      <main>
                        <div class="block h-60" *ngFor="let item of rating[0]?.customerratings">
                          <div class="line w-100">
                            <div class="line w-60 scroll-txt">{{item.customerName}}</div>
                            <div class="line w-40">
                              <div class="star-rating mb-1 float-right">
                                <ngb-rating [max]="5" [(rate)]="item.starRating" [readonly]="true" ></ngb-rating>

                              </div>
                            </div>


                          </div>
                          <div class="clear"></div>
                          <div class="line w-100 scroll-review">{{item.review}} </div>
                          <div class="clear"></div>
                          <div class="line w-100 scroll-date" >{{item.submittedOn | date :'dd-MM-yyyy'}} </div>

                        </div>





                      </main>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!--tab end-->


  <div class="clear"></div>
  <!--recent product start-->
  <div class="col-12">
    <div class="row">

      <section dir="ltr">

        <div class="container" *ngIf="relatableProductsList.length > 0">
          <div class="row justify-content-center text-center">
            <div class="col-lg-8 col-md-10">
              <div class="mb-5">
                <h6 class="text-primary mb-1">
                  — {{'desc.YouMayAlsoLike' |translate}}
                </h6>
                <h2 class="mb-0">{{'label.RelatedProducts' | translate}}</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="owl-carousel no-pb owl-2 " data-dots="false" data-nav="true" data-items="3" data-md-items="2"
                data-sm-items="1">
                <div class="item" *ngFor="let item of relatableProductsList">
                  <div class="card product-card">
                    <button class="btn-wishlist btn-sm" style="cursor: pointer;" (click)="addRelatableToWishList(item)"
                      type="button" data-toggle="tooltip" data-placement="left" title="Add to wishlist"><i
                        class="fa fa-heart"></i>
                    </button>
                    <a class="card-img-hover d-block" (click)="navigateToProductDetails(item)"
                      *ngIf="item?.images.length == 1">
                      <img class="card-img-top card-img-back" [src]="item?.images[0]?.imagePath" alt="...">
                      <img class="card-img-top card-img-front" [src]="item?.images[0]?.imagePath" alt="...">
                    </a>
                    <a class="card-img-hover d-block" (click)="navigateToProductDetails(item)"
                      *ngIf="item?.images.length > 1">
                      <img class="card-img-top card-img-back" [src]="item?.images[0]?.imagePath" alt="...">
                      <img class="card-img-top card-img-front" [src]="item?.images[1]?.imagePath" alt="...">
                    </a>
                    <div class="card-info">
                      <div class="card-body">
                        <div class="product-title"><a class="link-title" (click)="navigateToProductDetails(item)">

                            <span *ngIf="lang == 0">
                              {{item?.productName}}
                            </span>
                            <span *ngIf="lang == 1">
                              {{item?.productNameAr}}
                            </span>





                          </a>
                        </div>
                        <div class="mt-1"> <span class="product-price">

                          <span *ngIf="item?.discountPercent > 0">

                            <del class="text-muted">
                              QR {{item?.price |
                                  number:default_currencyformat}}</del> QR {{item?.discountPrice |
                                number:default_currencyformat}}

                          </span>

                          <span *ngIf="item?.discountPercent == 0">

                            QR {{item?.price |
                                number:default_currencyformat}}

                          </span>


                          </span>
                            <div class="star-rating">
                              <ngb-rating [max]="5" [(rate)]="item.starRating" [readonly]="true" ></ngb-rating>

                            </div>
                        </div>
                      </div>
                      <div class="card-footer bg-transparent border-0">
                        <div class="product-link d-flex align-items-center justify-content-center">

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>


  <div id="sizechart" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <img class="ruler" src="https://anbar.net/assets/images/chart.png" class="img-responsive">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary btn-md" data-dismiss="modal">OK</button>
        </div>

      </div>
    </div>
  </div>

  <div id="notifyMe" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="notify-div">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <h3 class="text-center mb-3 text-uppercase">{{'button.NotifyMe' | translate}}</h3>
            <div class="notify-close">
              <span (click)="popUpClose()" _ngcontent-ins-c62="" aria-hidden="true">×</span>
            </div>


            <form id="contact-form" [formGroup]="notifyForm" (ngSubmit)="onNotifySubmit()">
              <div class="messages"></div>
              <div class="form-group">
                <label>{{'label.Name' | translate}} <span class="text-danger">*</span></label>
                <input id="form_password" type="text" name="password" formControlName="Name" class="form-control"
                  placeholder="{{'label.Name' | translate}}" data-error="password is required.">
                <div class="help-block with-errors">
                  <div class="form-error"
                    *ngIf="(f.Name.touched && f.Name.errors) || (f.Name.dirty && f.Name.errors) ||  (submitted && f.Name.errors)">
                    <div *ngIf="f.Name.errors.required">{{'errors.Required' | translate}}</div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>{{'label.EmailorPhone' | translate}} <span class="text-danger">*</span></label>
                <input id="form_name" type="text" name="name" formControlName="EmailOrPhone" class="form-control"
                  placeholder="{{'label.EmailorPhone' | translate}}" data-error="Username is required.">
                <div class="help-block with-errors">
                  <div class="form-error"
                    *ngIf="(f.EmailOrPhone.touched && f.EmailOrPhone.errors) || (f.EmailOrPhone.dirty && f.EmailOrPhone.errors) ||  (submitted && f.EmailOrPhone.errors)">
                    <div *ngIf="f.EmailOrPhone.errors.required">{{'errors.Required' | translate}} </div>
                  </div>
                </div>
              </div>




            </form>
            <!-- <span>You will be notified when its avaiable.</span> -->
          </div>
          <div class="modal-footer">
            <a (click)="onNotifySubmit()" class="btn btn-primary btn-animated" style="color: white;">
              {{'button.NotifyMe' | translate}}</a>

            <button type="button" id="close-button" style="display: none;" class="btn btn-primary btn-md"
              data-dismiss="modal">OK</button>
          </div>

        </div>
      </div>

    </div>
  </div>


</div>















<!-- <app-product-quick-view [selectedProductDetails]="selectedProductDetails"  [selectedStockId]="seectedStockIdForRelatableStock"></app-product-quick-view> -->
