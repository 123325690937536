import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { Constants } from "../common-values/constants";

@Injectable({
  providedIn: "root",
})
export class DbService {
  BaseURL: string;
  TokenUrl: string;
  constructor(private http: HttpClient) {
    this.BaseURL = Constants.HOME_URL;
    this.TokenUrl = Constants.TOKEN_URL;
  }

  methodGet(url): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "bearer " + localStorage.getItem("userToken")
      }),
    };
    return this.http.get(this.BaseURL + url, httpOptions);
  }
  getApi(url): Observable<any> {
    let httpOptions = {
      // headers: new HttpHeaders({
      //   "Content-Type": "application/json",
      //   'Authorization': "bearer " + localStorage.getItem("userToken")
      // }),
    };
    return this.http.get(  url, httpOptions);
  }
  methodOptions(url): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        'Authorization': "bearer " + localStorage.getItem("userToken")
      }),
    };
    return this.http.get(this.BaseURL + url, httpOptions);
  }
  methodDelete(url): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http.delete(this.BaseURL + url, httpOptions);
  }
  methodPost(url, postData): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .post(this.BaseURL + url, postData, httpOptions)
      // .pipe(retry(1), catchError(this.handleError));
  }
  psotApi(url, postData): Observable<any> {
    let httpOptions = {
      // headers: new HttpHeaders({
      //   "Content-Type": "application/json",
      //   Authorization: "bearer " + localStorage.getItem("userToken"),
      // }),
    };
    return this.http
      .post(url, postData, httpOptions)
      // .pipe(retry(1), catchError(this.handleError));
  }
  methodPostFormData(url, postData): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .post(this.BaseURL + url, postData, httpOptions)
      // .pipe(retry(1), catchError(this.handleError));
  }
  methodPut(url, postData): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .put(this.BaseURL + url, postData, httpOptions)
      // .pipe(retry(1), catchError(this.handleError));
  }
  methodPutFormData(url, postData): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/json",
        Authorization: "bearer " + localStorage.getItem("userToken"),
      }),
    };
    return this.http
      .put(this.BaseURL + url, postData, httpOptions)
      // .pipe(retry(1), catchError(this.handleError));
  }

  // handleError(error) {
  //   console.log(error);
  //   let errorMessage =
  //     "Failed !  url :" + error.url + "  Message : " + error.error.Message;
  //   window.alert(errorMessage);
  //   return throwError(errorMessage);
  // }
}
