import { DatePipe } from '@angular/common';
import { Route } from '@angular/compiler/src/core';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common-values/common.service';
import { Config } from 'src/app/common-values/config';
import { DbService } from 'src/app/services/db.service';
declare function zoomPic(): any;

declare function showNotification(type : string,titile : string,message :string): any
@Component({
  selector: 'app-product-quick-view',
  templateUrl: './product-quick-view.component.html',
  styleUrls: ['./product-quick-view.component.scss']
})
export class ProductQuickViewComponent implements OnInit  , OnChanges{
  @Input() selectedProductDetails ;
  submitted : boolean = false;
  @Input()  selectedStockId ;
  notifyForm : FormGroup;

  lang = Number(Config.lang)
  default_currencyformat = Config.currencyFormat;
  imagePath :string ="assets/assets/images/product/02.jpg";
  element ='#3cb371';
  selectedSizeOption : number =0;
  selectedColor : number =0;
  selectedStock : any = {}
  outOfStock : boolean = false;
  selectedQty : number = 1;

  selectedSizeMaster : number = 0;

  sizeMasterList = [
    {
      id: 0  , name : 'EU'
    },
    {
      id : 1 , name : 'UK'
    },
    {
      id : 2 , name :'US'
    }
  ];
  constructor(public common : CommonService,
     private router : Router ,
     private db : DbService,
     private spinner : NgxSpinnerService,
     private fb : FormBuilder,
     private datePipe : DatePipe) { }

  ngOnInit(): void {
    this.buildForm();
  }

  notifyMe(){
    let body = {
      UserId : this.common.userDetails.userId,
      ProductId : this.selectedProductDetails?.productId ,
      ColorId :this.selectedColor ,
      SizeOptionId : this.selectedSizeOption
    }
    this.spinner.show();
    this.db.methodPost("Common/PostNotification",body).subscribe(resdata =>{
      this.spinner.hide();
      if(resdata.notifyId > 0){
        showNotification('success','Success','You will be notified when its avaiable. !');
      }
    },
    error =>{
      this.spinner.hide();
      showNotification('error','Failed','already added to notified list !');
    })
  }

  popUpClose(){

    document.getElementById('close-button').click();
}
  onClosePopup(){
    this.selectedQty = 1;
  }
  onQtyChange(key){

    if(this.selectedColor == 0){
      showNotification('error','Failed','Please Select Color!');
      return;
    }
    if(this.selectedSizeOption == 0){
      showNotification('error','Failed','Please Select Size!');
      return;
    }
    if(key === 1){
      if(this.selectedQty != 1){
        this.selectedQty = this.selectedQty - 1;
      }
    }
    else{
      this.selectedQty = this.selectedQty + 1;
    }



    this.checkStock();
  }

  sizeOptionsList = []
  isLoading : boolean = false;
  onRadioColorChange(color , key){
    // this.selectedColor = value;


    this.isLoading = true;
    let images : Array<any>=[];
    if(color.productColorImages?.imageName)
    images.push( { id : 1, imagePath : color.productColorImages?.imageName})
    if(color.productColorImages?.imageName1)
    images.push( { id : 1, imagePath : color.productColorImages?.imageName1})
    if(color.productColorImages?.imageName2)
    images.push( { id : 2, imagePath : color.productColorImages?.imageName2})
    if(color.productColorImages?.imageName3)
    images.push( { id : 2, imagePath : color.productColorImages?.imageName3})
    this.selectedProductDetails.productImages = images;



    setTimeout(() => this.isLoading = false, 1000); //



    this.selectedSizeOption = 0;
    let body  = {
      ProductId : this.selectedProductDetails?.productId,
      ColorId : this.selectedColor
    }
this.db.methodPost("Common/GetStockSizeList" , body).subscribe(resdata =>{
  
  this.sizeOptionsList = resdata;

  if(this.sizeOptionsList.length > 0){
    this.selectedSizeOption = this.sizeOptionsList[0].sizeOptionId;
    this.onRadioSizeChange();
  }
})
  }

  onRadioSizeChange(){
    // this.selectedSizeOption = value;
    // this.checkStock();

    let body = {
      ProductId : this.selectedProductDetails?.productId,
      ColorId: this.selectedColor ,
      SizeOptionId : this.selectedSizeOption
    }
    this.db.methodPost("Common/GetProductStock", body).subscribe(resdata =>{
      
      this.selectedStock=  resdata.stock;
      this.checkStock();
    })
  }


  checkStock(){
    if(this.selectedStock.availableStock > 0){
      this.outOfStock = false;
      if(this.selectedQty > this.selectedStock.availableStock){
        showNotification('error','Failed','sorry available stock is ' + this.selectedStock.availableStock);
        this.outOfStock = true;
      }
      else{
        this.outOfStock = false;
      }
    }
    else{
      this.outOfStock = true;
    }
  
  }

  addWishList(){
    if(this.selectedColor == 0){
      showNotification('error','Failed','Select a Color !');
      return;
    }
    if(this.selectedSizeOption == 0){
      showNotification('error','Failed','Select a Size !');
      return;
    }
    if(this.common.isLoggedIn == true){
      let body ={
        productId : this.selectedStock.productId,
        userId : this.common.userDetails?.userId,
        stockId : this.selectedStock.stockId,
        quantity : this.selectedQty,
        date : this.datePipe.transform(new Date , 'yyyy-MM-dd'),
        isActive : true
      }
      this.spinner.show();
      this.db.methodPost("Wishlists" , body).subscribe(resdata =>{

        this.spinner.hide();
        if(resdata.wishlistId > 0){
          showNotification('success','Success','Successfully added to wishlist !');

          // alert('added to wishlist');
          // document.getElementById('closeId').click();

          // this.router.navigate(['/wishlist'])
        }

      } , error =>{
        this.spinner.hide();
        showNotification('error','Failed','Already in wishlist !');
      })
    }
    else{
      document.getElementById('closeId').click();

      this.router.navigate(['/login'])
    }
  }
  // addToCart(){
  //   console.log(this.selectedColor , this.selectedSizeOption);
  //   if(this.common.isLoggedIn == true){
  //     let body ={
  //       productId : this.selectedStock.productId,
  //       userId : this.common.userDetails?.userId,
  //       stockId : this.selectedStock.stockId,
  //       quantity : this.selectedQty,
  //       date : this.datePipe.transform(new Date , 'yyyy-MM-dd'),
  //       isActive : true
  //     }
  //     this.db.methodPost("Carts" , body).subscribe(resdata =>{

  //       if(resdata.cartId > 0){
  //         alert('added to cart');
  //         document.getElementById('closeId').click();
  //         this.router.navigate(['/product-cart'])
  //       }
  //     })
  //   }
  //   else{
  //     document.getElementById('closeId').click();

  //     this.router.navigate(['/'])
  //   }

  // }
  getCartList(){
    this.db.methodGet("Carts/GetUserCart/" + this.common.userDetails.userId).subscribe(resdata =>{
      this.common.cartCount = resdata.length;
      this.common.cartList = resdata;
    })
  }
  addToCart(){


    if(this.selectedColor == 0){
      showNotification('error','Failed','Select a Color !');
      return;
    }
    if(this.selectedSizeOption == 0){
      showNotification('error','Failed','Select a Size !');
      return;
    }
    if(this.outOfStock){
      showNotification('error','Failed','Sorry Item Not Available !');




    }
    else{
      if(this.common.isLoggedIn == true){
        let body ={
          productId : this.selectedStock.productId,
          userId : this.common.userDetails?.userId,
          stockId : this.selectedStock.stockId,
          quantity : this.selectedQty,
          date : this.datePipe.transform(new Date , 'yyyy-MM-dd'),
          isActive : true
        }



       this.spinner.show();
        this.db.methodPost("Carts" , body).subscribe(resdata =>{

          this.spinner.hide();
          if(resdata.cartId > 0){
            showNotification('success','Success','Successfully added to cart !');
            this.getCartList();
            // this.router.navigate(['/product-cart'])
        //  document.getElementById('closeId').click();

          }
        })
      }
      else{

        if(this.common.cartList.find(x => x.stockId === this.selectedStock.stockId)){
          showNotification('success','Success','Successfully added to cart !');

          // alert('already exists in cart')
          // let element = this.common.cartList.find(
          //   (x) => x.stockId == this.selectedStock.stockId
          // );
          // element.quantity = element.quantity + 1;
        }
        else{
          let item = {
            stockId : this.selectedStock.stockId,
            quantity : this.selectedQty
          } ;

          this.common.cartList.push(item);
          showNotification('success','Success','Successfully added to cart !');

        }
        localStorage.setItem('cart' , JSON.stringify(this.common.cartList))

        // this.router.navigate(['/login'])
      }
    }



  }

  productRating : number = 0;

  ngOnChanges(){
    if(this.selectedProductDetails?.productImages?.length > 0){
       this.imagePath = this.selectedProductDetails.productImages[0].imagePath;
    };
    this.productRating = this.selectedProductDetails.starRating;
    this.selectedColor = 0;
    
    if(this.selectedProductDetails?.colors?.length > 0 ){ 
      this.selectedColor = this.selectedProductDetails?.colors[0]?.colorId;
      this.onRadioColorChange(this.selectedProductDetails?.colors[0] , 1);
    }
    this.sizeOptionsList = []
    this.selectedSizeOption =0;
    // if(this.selectedProductDetails?.stocks?.length > 0){
    //   this.selectedStock = this.selectedProductDetails.stocks.find(x => x.stockId ==  this.selectedStockId);
    //   // this.selectedStock = this.selectedProductDetails.stocks[0];
    //   if(this.selectedStock){
    //     this.selectedColor = this.selectedStock.colorId;
    //     this.selectedSizeOption = this.selectedStock.sizeOptionId;
    //   }

    // }
    // else{
    //  this.selectedColor = 0;
    //  this.selectedSizeOption = 0;
    // }
    // this.checkStock();
    // zoomPic();
  }



  onNotifySubmit(){
    this.submitted = true;
    if(this.notifyForm.invalid){
      return;
    }
    let email ="0"
    let phone ="0"
    if(this.notifyForm.controls.EmailOrPhone.value.includes('@')){
      email = this.notifyForm.controls.EmailOrPhone.value
    }
    else{
      phone = this.notifyForm.controls.EmailOrPhone.value
    }
    let body = {
      UserId : 0 ,
      ProductId : this.selectedProductDetails?.productId ,
      ColorId :this.selectedColor ,
      SizeOptionId : this.selectedSizeOption,
      UserName : this.notifyForm.controls.Name.value,
      Email : email ,
      Phone : phone
    }

    console.log(body)
    this.spinner.show();
    this.db.methodPost("Common/PostNotification",body).subscribe(resdata =>{
      this.spinner.hide();
      if(resdata.notifyId > 0){
        showNotification('success','Success','You will be notified when its avaiable. !');
        document.getElementById('close-button').click();
      }
      else{
        alert('Failed')
      }
    },
    error =>{
      this.spinner.hide();
      showNotification('error','Failed','already added to notified list !');
    }

    )
  }
  get f() { return this.notifyForm.controls}


  buildForm(){
    this.notifyForm=this.fb.group({
      Name :["" , Validators.required],
      EmailOrPhone :["",Validators.required]

    })
  }
}
