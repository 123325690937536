import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common-values/common.service';
import { Config } from 'src/app/common-values/config';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { DbService } from 'src/app/services/db.service';
declare function showNotification(type : string,titile : string,message :string): any;
@Component({
  selector: 'app-wish-list',
  templateUrl: './wish-list.component.html',
  styleUrls: ['./wish-list.component.scss']
})
export class WishListComponent implements OnInit {
  wishList =[];
  lang = Number(Config.lang) ;
  default_currencyformat = Config.currencyFormat;
  constructor(private db : DbService ,
    private common : CommonService ,

     private spinner : NgxSpinnerService,
     private datePipe : DatePipe,
     private confirmationDialogService  : ConfirmationService,
     private router: Router
     ) { }

  ngOnInit(): void {

    this.getWishist();
  }



  deleteWishListItem(item){

    this.confirmationDialogService.confirm('Please Confirm ','Do you want to remove this item?')
    .then((confirmed) =>
    {
      if(confirmed){
        this.spinner.show();
        this.db.methodDelete("Wishlists/" + item.wishlistId).subscribe(resdata =>{
          this.spinner.hide();
          if(resdata.wishlistId > 0){
           showNotification('success','Success','Successfully deleted from wishlist !');
            this.getWishist();
          }

        })
      }
    }
      )
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));



   }
   getCartList(){
     this.spinner.show();
    this.db.methodGet("Carts/GetUserCart/" + this.common.userDetails.userId).subscribe(resdata =>{
      this.spinner.hide();
      this.common.cartCount = resdata.length;
      this.common.cartList = resdata;
    })
  }
  addToCart(item){
    if(this.common.isLoggedIn == true){
      let body ={
        productId : item.productId,
        userId : this.common.userDetails?.userId,
        stockId : item.stockId,
        quantity : 1,
        date : this.datePipe.transform(new Date , 'yyyy-MM-dd'),
        isActive : true
      }

      this.spinner.show();
      this.db.methodPost("Carts" , body).subscribe(resdata =>{
        this.spinner.hide();
        if(resdata.cartId > 0){
         showNotification('success','Success','Successfully added to cart !');
         this.getCartList();
          // this.router.navigate(['/product-cart'])
        }
      })
    }
    else{
      this.router.navigate(['/'])
    }
  }

  navigateToProductDetails(product){
    this.router.navigate(['/product-details'] ,{
     queryParams: { productName : product.productName  , productId : btoa(product.productId) , stockId: btoa(product.stockId)} })
 }
  getWishist(){

     this.spinner.show();
    this.db.methodGet("Wishlists/GetUserWishlist/" + this.common.userDetails.userId).subscribe(resdata =>{
      this.spinner.hide();
   this.wishList = resdata;
    })

  }

}
