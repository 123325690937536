import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { CommonService } from "src/app/common-values/common.service";
import { Config } from "src/app/common-values/config";
import { ConfirmationService } from "src/app/services/confirmation.service";
import { DbService } from "src/app/services/db.service";
declare function showNotification(
  type: string,
  titile: string,
  message: string
): any;
@Component({
  selector: "app-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.scss"],
})
export class ProductListComponent implements OnInit {
  paramsData: any = {};
  sortList = [
    // { id : 1 , name :'Newest Item'},
    // { id : 2 , name :'Populer'},
    // { id : 3 , name :'Best Match'},
    { id: 1, name: "Price High to Low", nameAr: "السعر الاعلى الى الادنى" },
    { id: 2, name: "Price Low to High", nameAr: "السعر من الارخص للاعلى" },
    { id: 3, name: "Popular", nameAr: "شائع" },
    { id: 4, name: "New Arrivals", nameAr: "القادمون الجدد" },
    {
      id: 5,
      name: "Discount Low to High",
      nameAr: "الخصم من الأقل إلى الأعلى",
    },
    {
      id: 6,
      name: "Discount High to Low",
      nameAr: "الخصم من الأعلى إلى الأقل",
    },
  ];
  itemPerPage: number = 40;
  lang = Number(Config.lang);
  p: number = 1;
  selectedStockId: number = 0;
  searchText: string = "";
  selectedPriceSection = { id: 0, name: "All", min: "0", max: "10000" };

  priceList = [
    { id: 0, name: "All", min: "0", max: "10000" },

    { id: 1, name: "QR10 - QR100", min: "10", max: "100" },
    { id: 2, name: "QR100 - QR200", min: "100", max: "200" },
    { id: 3, name: "QR200 - QR300", min: "200", max: "300" },
    { id: 4, name: "QR300 - QR400", min: "300", max: "400" },
    { id: 5, name: "QR400 - QR500", min: "400", max: "500" },
  ];
  default_currencyformat = Config.currencyFormat;
  filterBrandList = [];
  productList = [];
  selectedProductDetails: any = {};
  selectedCategoryId = [];
  colorsList: number = 0;
  selectedBrandId = [];
  selectedSubCategoryId = [];
  filterColorsList = [];

  categoryList = [];

  //
  selectedSortOption: number = 0;
  selectedFilterSizeOption;

  filterSizeOptions = [];
  menuName: string = "";
  menuNameAr: string = "";

  subMenuName: string = "";
  subMenuNameAr: string = "";

  subMenuBrandName: string = "";
  subMenuBrandNameAr: string = "";

  selectedColors = [];
  filterStyleList = [];
  filterCategoryList = [];
  constructor(
    private db: DbService,
    private router: Router,

    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private confirmationDialogService: ConfirmationService,
    private common: CommonService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      this.paramsData = queryParams;
      if (queryParams.name) {
        this.menuName = queryParams.name;
        this.menuNameAr = queryParams.nameAr;

      } else {
        this.menuName = "";
        this.menuNameAr = "";

      }
      if (queryParams.subMenuName) {
        this.subMenuName = queryParams.subMenuName;
        this.subMenuNameAr = queryParams.subMenuNameAr;

      } else {
        this.subMenuName = "";
        this.subMenuNameAr = "";

      }
      if (queryParams.brandName) {
        this.subMenuBrandName = queryParams.brandName;
        this.subMenuBrandNameAr = queryParams.brandNameAr;

      } else {
        this.subMenuBrandName = "";
        this.subMenuBrandNameAr = "";

      }
      if (queryParams.text) {
        this.searchText = queryParams.text;
      } else {
        this.searchText = "";
      }

      if (queryParams.brandId) {
        if (Number(atob(queryParams.brandId)) > 0) {
          this.selectedBrandId = [];
          this.selectedBrandId.push(atob(queryParams.brandId));
        } else {
          this.selectedBrandId = [];
        }
      }
      if (queryParams.subCategoryId) {
        if (Number(atob(queryParams.subCategoryId)) > 0) {
          this.selectedSubCategoryId = [];
          this.selectedSubCategoryId.push(atob(queryParams.subCategoryId));
        } else {
          this.selectedSubCategoryId = [];
        }
      }
      if (queryParams.categoryId) {
        if (Number(atob(queryParams.categoryId)) > 0) {
          this.selectedCategoryId = [];
          this.selectedCategoryId.push(atob(queryParams.categoryId));
        } else {
          this.selectedCategoryId = [];
        }
      }

      this.GetFilterSizeOptions(
        atob(queryParams.categoryId),
        atob(queryParams.subCategoryId),
        atob(queryParams.brandId)
      );
      this.getFilterColorsList(
        atob(queryParams.categoryId),
        atob(queryParams.subCategoryId),
        atob(queryParams.brandId)
      );
      this.getFilterBrandList(
        atob(queryParams.categoryId),
        atob(queryParams.subCategoryId),
        atob(queryParams.brandId)
      );
      this.getFilterStyleList(
        atob(queryParams.categoryId),
        atob(queryParams.subCategoryId),
        atob(queryParams.brandId)
      );
      this.getFilterCategoryList(
        atob(queryParams.categoryId),
        atob(queryParams.subCategoryId),
        atob(queryParams.brandId)
      );

      // this.getCategoriesList();

      this.getProductList();
    });
  }

  onCategClick() {
    this.subMenuBrandName = null;
    this.subMenuName = null;
    this.selectedSubCategoryId = [];
    this.selectedCategoryId = [];
    this.selectedBrandId = [];
    this.selectedCategoryId.push(atob(this.paramsData.categoryId));
    this.getProductList();
  }

  onSubCategClick() {
    this.selectedSubCategoryId = [];
    this.selectedCategoryId = [];
    this.selectedBrandId = [];
    this.selectedCategoryId.push(atob(this.paramsData.categoryId));
    this.selectedSubCategoryId.push(atob(this.paramsData.subCategoryId));
    this.getProductList();
  }

  onBrandClick() {
    this.selectedSubCategoryId = [];
    this.selectedCategoryId = [];
    this.selectedBrandId = [];
    this.selectedCategoryId.push(atob(this.paramsData.categoryId));
    // this.selectedSubCategoryId.push((atob(this.paramsData.subCategoryId)));
    this.selectedBrandId.push(atob(this.paramsData.brandId));

    this.getProductList();
  }

  clearFilter() {
    this.selectedColors = [];
    this.selectedFilterSizeOption = 0;

    this.selectedSortOption = 0;
    this.selectedPriceSection = { id: 0, name: "All", min: "0", max: "10000" };
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.name) {
        this.menuName = queryParams.name;
      } else {
        this.menuName = "";
      }
      if (queryParams.subMenuName) {
        this.subMenuName = queryParams.subMenuName;
      } else {
        this.subMenuName = "";
      }

      if (queryParams.brandId) {
        if (Number(atob(queryParams.brandId)) > 0) {
          this.selectedBrandId = [];
          this.selectedBrandId.push(atob(queryParams.brandId));
        } else {
          this.selectedBrandId = [];
        }
      }
      if (queryParams.subCategoryId) {
        if (Number(atob(queryParams.subCategoryId)) > 0) {
          this.selectedSubCategoryId = [];
          this.selectedSubCategoryId.push(atob(queryParams.subCategoryId));
        } else {
          this.selectedSubCategoryId = [];
        }
      }
      if (queryParams.categoryId) {
        if (Number(atob(queryParams.categoryId)) > 0) {
          this.selectedCategoryId = [];
          this.selectedCategoryId.push(atob(queryParams.categoryId));
        } else {
          this.selectedCategoryId = [];
        }
      }
      this.GetFilterSizeOptions(
        atob(queryParams.categoryId),
        atob(queryParams.subCategoryId),
        atob(queryParams.brandId)
      );
      this.getFilterColorsList(
        atob(queryParams.categoryId),
        atob(queryParams.subCategoryId),
        atob(queryParams.brandId)
      );
      this.getFilterBrandList(
        atob(queryParams.categoryId),
        atob(queryParams.subCategoryId),
        atob(queryParams.brandId)
      );
      this.getFilterStyleList(
        atob(queryParams.categoryId),
        atob(queryParams.subCategoryId),
        atob(queryParams.brandId)
      );
      this.getFilterCategoryList(
        atob(queryParams.categoryId),
        atob(queryParams.subCategoryId),
        atob(queryParams.brandId)
      );
      this.getProductList();
    });
  }

  getFilterColorsList(categoryId, subCategoryId, brandId) {
    let body = {
      CategoryId: categoryId,
      SubCategoryId: subCategoryId,
      BrandId: brandId,
    };
    this.db.methodPost("Configs/GetFilterColors", body).subscribe((resdata) => {
      this.filterColorsList = resdata;
    });
  }

  getFilterBrandList(categoryId, subCategoryId, brandId) {
    let body = {
      CategoryId: categoryId,
      SubCategoryId: subCategoryId,
      BrandId: brandId,
    };
    this.db.methodPost("Configs/GetFilterBrands", body).subscribe((resdata) => {
      this.filterBrandList = resdata;
    });
  }
  getFilterStyleList(categoryId, subCategoryId, brandId) {
    let body = {
      CategoryId: categoryId,
      SubCategoryId: subCategoryId,
      BrandId: brandId,
    };
    this.db.methodPost("Configs/GetFilterStyles", body).subscribe((resdata) => {
      this.filterStyleList = resdata;
    });
  }
  getFilterCategoryList(categoryId, subCategoryId, brandId) {
    let body = {
      CategoryId: categoryId,
      SubCategoryId: subCategoryId,
      BrandId: brandId,
    };
    this.db
      .methodPost("Configs/GetFilterCategories", body)
      .subscribe((resdata) => {
        this.filterCategoryList = resdata;
        setTimeout(() => {
          document.getElementById('collapse0').click();
       }, 500);
        
      });
  }

  GetFilterSizeOptions(categoryId, subCategoryId, brandId) {
    let body = {
      CategoryId: categoryId,
      SubCategoryId: subCategoryId,
      BrandId: brandId,
    };
    this.db
      .methodPost("Configs/GetFilterSizeOptions", body)
      .subscribe((resdata) => {
        this.filterSizeOptions = resdata;
      });
  } 

  selectedColor = 0;
  onQuickView(product) {
    this.selectedColor = 0;
    this.selectedSizeOption = 0;
    this.selectedStockId = product.stockId;
    this.sizeOptionsList = [];
    this.db
      .methodGet("Common/GetProductStockList/" + product.productId)
      .subscribe((resdata) => {
        this.selectedProductDetails = resdata[0];
      });
  }

  onRadioSizeChange() {
    // this.selectedSizeOption = value;
    // this.checkStock();

    let body = {
      ProductId: this.selectedProductDetails?.productId,
      ColorId: this.selectedColor,
      SizeOptionId: this.selectedSizeOption,
    };
    this.db.methodPost("Common/GetProductStock", body).subscribe((resdata) => {
      this.selectedStockId = resdata.stock.stockId;
    });
  }
  sizeMasterList = [
    {
      id: 0,
      name: "EU",
    },
    {
      id: 1,
      name: "UK",
    },
    {
      id: 2,
      name: "US",
    },
  ];
  selectedSizeOption = 0;
  selectedSizeMaster = 0;
  onRadioColorChange() {
    // this.selectedColor = value;

    this.selectedSizeOption = 0;
    let body = {
      ProductId: this.selectedProductDetails?.productId,
      ColorId: this.selectedColor,
    };
    this.db.methodPost("Common/GetStockSizeList", body).subscribe((resdata) => {
      this.sizeOptionsList = resdata;
    });
  }
  sizeOptionsList = [];

  onSubCategChecked(subCateg, event) {
    if (event.target.checked) {
      this.selectedSubCategoryId.push(subCateg.subCategoryId.toString());
    } else {
      var index = this.selectedSubCategoryId.indexOf(
        subCateg.subCategoryId.toString()
      );
      if (index !== -1) {
        this.selectedSubCategoryId.splice(index, 1);
      }
    }

    this.getProductList();
  }
  onBrandChecked(brand, event) {
    if (event.target.checked) {
      this.selectedBrandId.push(brand.brandId.toString());
    } else {
      var index = this.selectedBrandId.indexOf(brand.brandId.toString());
      if (index !== -1) {
        this.selectedBrandId.splice(index, 1);
      }
    }

    this.getProductList();
  }


  selectedFilterColor : number = 0;
  
  onColorChecked() {

this.selectedColors = [];

if(Number(this.selectedFilterColor) != 0){
  this.selectedColors.push(Number(this.selectedFilterColor));
}
    this.getProductList();

  
  }
  // onColorChecked(color, event) {
  //   if (event.target.checked) {
  //     this.selectedColors.push(color.colorId.toString());
  //   } else {
  //     var index = this.selectedColors.indexOf(color.colorId.toString());
  //     if (index !== -1) {
  //       this.selectedColors.splice(index, 1);
  //     }
  //   }

  //   this.getProductList();
  // }
  totalCount: number = 0;



  scrollToTo(){
    window.scroll(0,0)
  //   let scrollToTop = window.setInterval(() => {
  //     let pos = window.pageYOffset;
  //     if (pos > 0) {
  //         window.scrollTo(0, pos - 20); // how far to scroll on each step
  //     } else {
  //         window.clearInterval(scrollToTop);
  //     }
  // }, 16);
  }
  getProductList() {
    // let body  = JSON.stringify(this.filerData)
    this.p = 1;
    let sizeOptionArray = [];
    if (
      this.selectedFilterSizeOption != 0 &&
      this.selectedFilterSizeOption != undefined
    ) {
      sizeOptionArray.push(this.selectedFilterSizeOption);
    }

    this.productList = [];
    this.totalCount = 0;
    let body = JSON.stringify({
      PageNumber: 1,
      PageSize: this.itemPerPage,
      CategoryId: this.selectedCategoryId,
      BrandId: this.selectedBrandId,
      SubCategoryId: this.selectedSubCategoryId,
      ColourId: this.selectedColors,
      SortBy: this.selectedSortOption.toString(),
      PriceMin: this.selectedPriceSection.min.toString(),
      PriceMax: this.selectedPriceSection.max.toString(),
      SizeOptionId: sizeOptionArray,
      SearchText: this.searchText,
    });

    this.spinner.show();
    this.db.methodPost("Common/GetProductList", body).subscribe((resdata) => {
      this.spinner.hide();
      if (resdata.products) {
        this.productList = resdata.products;
      }
      if (resdata.totalCount) {
        this.totalCount = resdata.totalCount;
      }
    });
  }

  getProductListPage() {
    // let body  = JSON.stringify(this.filerData)
    let sizeOptionArray = [];
    if (
      this.selectedFilterSizeOption != 0 &&
      this.selectedFilterSizeOption != undefined
    ) {
      sizeOptionArray.push(this.selectedFilterSizeOption);
    }

    this.productList = [];
    this.totalCount = 0;
    let body = JSON.stringify({
      PageNumber: this.p,
      PageSize: this.itemPerPage,
      CategoryId: this.selectedCategoryId,
      BrandId: this.selectedBrandId,
      SubCategoryId: this.selectedSubCategoryId,
      ColourId: this.selectedColors,
      SortBy: this.selectedSortOption.toString(),
      PriceMin: this.selectedPriceSection.min.toString(),
      PriceMax: this.selectedPriceSection.max.toString(),
      SizeOptionId: sizeOptionArray,
    });

    this.spinner.show();
    this.db.methodPost("Common/GetProductList", body).subscribe((resdata) => {
      console.log(resdata);
      this.spinner.hide();

      if (resdata.products) {
        this.productList = resdata.products;
      }

      if (resdata.totalCount) {
        this.totalCount = resdata.totalCount;
      }
    });
  }

  toWishList() {
    if (this.selectedColor == 0) {
      showNotification("error", "Failed", "Select a Color !");
      return;
    }
    if (this.selectedSizeOption == 0) {
      showNotification("error", "Failed", "Select a Size !");
      return;
    }

    let body = {
      productId: this.selectedProductDetails.productId,
      userId: this.common.userDetails?.userId,
      stockId: this.selectedStockId,
      quantity: 1,
      date: this.datePipe.transform(new Date(), "yyyy-MM-dd"),
      isActive: true,
    };

    this.spinner.show();
    this.db.methodPost("Wishlists", body).subscribe(
      (resdata) => {
        this.spinner.hide();
        if (resdata.wishlistId > 0) {
          showNotification(
            "success",
            "Success",
            "Successfully added to wishlist !"
          );
          // this.router.navigate(['/wishlist'])
          document.getElementById("closeIdd").click();
        }
      },
      (error) => {
        this.spinner.hide();
        showNotification("error", "Failed", error.error.message);
      }
    );
  }

  addWishList(item) {
    //  if(confirm('are you sure to add to wishlist ?')){
    if (this.common.isLoggedIn == true) {
      let body = {
        productId: item.productId,
        userId: this.common.userDetails?.userId,
        stockId: item.stockId,
        quantity: 1,
        date: this.datePipe.transform(new Date(), "yyyy-MM-dd"),
        isActive: true,
      };

      this.spinner.show();
      this.db.methodPost("Wishlists", body).subscribe(
        (resdata) => {
          this.spinner.hide();
          if (resdata.wishlistId > 0) {
            showNotification(
              "success",
              "Success",
              "Successfully added to wishlist !"
            );
            // this.router.navigate(['/wishlist'])
          }
        },
        (error) => {
          this.spinner.hide();
          showNotification("error", "Failed", error.error.message);
        }
      );
    } else {
      this.router.navigate(["/login"]);
    }
    //  }
  }
  // getProductList(){
  //  this.db.methodOptions("Common/GetProductList").subscribe(resdata =>{
  //
  //    this.productList = resdata;
  //  })
  // }
  // getCategoriesList(){
  //   this.db.methodOptions("Common/GetShopCategories").subscribe(resdata =>{
  //     this.categoryList = resdata;
  //   })
  // }

  navigateToProductDetails(product) {
    this.router.navigate(["/product-details"], {
      queryParams: {
        productName: product.productName,
        productId: btoa(product.productId),
        stockId: btoa(product.stockId),
      },
    });
  }
}
