import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common-values/common.service';
import { DbService } from 'src/app/services/db.service';

declare function showNotification(type : string,titile : string,message :string): any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit , OnDestroy {

  userId
  isSendVerification : boolean = false
  signInForm : FormGroup;
  submitted : boolean = false;
  constructor(private fb : FormBuilder , private db : DbService ,
    private spinner : NgxSpinnerService,
    private route : ActivatedRoute,
    private router : Router , private common : CommonService) { }
    decrypted
  ngOnInit(): void {
    this.buildForm();
    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.uid != undefined) {
        let user = (atob(queryParams.uid))
        this.userId=(user.split('-'))[1]
        this.signInForm.get('Email').clearValidators();
        this.signInForm.get('Email').updateValueAndValidity();
        this.isSendVerification = true;
        
      }
     
      
    })
        // this.f.UserName.setValue(this.common.forgotUserName);
  }


  buildForm(){
    this.signInForm=this.fb.group({
      Email :["" , Validators.required],
      Password :["",Validators.required],
      ConfirmPassword:["",Validators.required],

    })
  }
 ngOnDestroy(){
  //  this.common.forgotUserName ="";
 }


  get f(){ return this.signInForm.controls;}


  sendVerification(){
    if(!this.f.Email.value){
      showNotification('error','Failed','please enter a valid email');;
      return;

    }


    this.db.methodGet("Common/SendVerificationlink/"+ this.f.Email.value).subscribe(resdata =>{
      if(resdata.userId > 0){
      //  this.isSendVerification = true;
        showNotification('success','Updated','Verification mail send ! please check your email');
      }
    },
    error =>{
      showNotification('error','Failed',error.error.message);;

    }
    )
  }

  onForgotPassword(){
    // if(this.f.UserName.value === ''){
    // showNotification('error','Failed','please enter username');
    // }
    // else{

    // }



  }

  onSubmit(){
    this.submitted = true;
    if(this.signInForm.invalid){
      return;
    }

    let body =
    {
      userId : this.userId,
      Password : this.f.Password.value,
    }
    if(this.f.Password.value !== this.f.ConfirmPassword.value){
      showNotification('error','Failed','passwords not match !');
      return;
    }



    
    this.spinner.show();
    this.db.methodPost("Common/ForgotPassword" , body).subscribe(resdata =>{
      this.spinner.hide();
      if(resdata.userId > 0){
            showNotification('success','Updated','Your Passwords are updated successfully');
            this.router.navigate(['/login'])
      }
    },


    error =>{
      showNotification('error','Failed',error.error.message);

    })
  }


  getCartList(){
    this.spinner.show();
    this.db.methodGet("Carts/GetUserCart/" + this.common.userDetails.userId).subscribe(resdata =>{

      this.spinner.hide();
      this.common.cartCount = resdata.length;
    })
  }
}
