<section class="banner_section">

<div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">
<div class="carousel-inner">
  <div class="carousel-item active" *ngFor="let item of sectionImages?.section_1 ; let i =index" [ngClass]="{'active': i === 0}">
    <img [src]="item.imageUrl" alt="" style="cursor: initial;"/>
  </div>
  <!-- <div class="carousel-item active">
    <img src="../../../../assets/images/banner/min-banner.jpg" alt=""/>
  </div>
  <div class="carousel-item">
    <img src="../../../../assets/images/banner/min-banner.jpg" alt=""/>
  </div>
  <div class="carousel-item">
    <img src="../../../../assets/images/banner/min-banner.jpg" alt=""/>
  </div> -->
</div>
<!-- <a class="carousel-control-prev" href="productlist.htmlcarouselExampleFade" role="button" data-slide="prev">
  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
  <span class="sr-only">Previous</span>
</a>
<a class="carousel-control-next" href="productlist.htmlcarouselExampleFade" role="button" data-slide="next">
  <span class="carousel-control-next-icon" aria-hidden="true"></span>
  <span class="sr-only">Next</span>
</a> -->
<a class="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>


</section>
<!--end banner section-->


<!--start section-->
<section class="main_section">
<!--start container-->
<div class="container container_paddings">
  <!--start row-->

    <!--start col md 6-->
    
      <div class="row ">
        <div class="panel_col">
        <img (click)="navigate(sectionImages?.section_2[0])"  [src]="sectionImages?.section_2[0]?.imageUrl" style="width:100%;" alt=""/>
</div>
      </div>
    
    <!--end col md 6-->

    <!--start col md 6
    <div class="col-md-6 col-sm-6 panel_wrapp col_padd">
      <div class="row">
        <h4>Glam for the 'Gram</h4>
        <h5><span>Bag & Accessories</span></h5>
        <span>Shop Now</span>

      </div>
     </div>
    end col md 6-->


  <!--end row-->
</div>
<!--end container-->
</section>
<!--end section-->


<!--start section-->
<section class="main_section" style="margin: 10px 0px;">
<!--start container-->
<div class="container padd_control">
  <!--start row-->
  <div class="row">
    <!--start col md 3-->



 <!--start col md 3-->
    <div class="col-md-3 col-sm-3 col-lg-3 col-6 col_padd" *ngFor="let item of sectionImages?.section_3">
      <div class="panel_col">
        <img (click)="navigate(item)" [src]="item.imageUrl"  alt=""/>
      </div>
    </div>
    <!--end col md 3-->

    <!-- <div class="col-md-3 col-sm-3 col-6 col_padd">
      <div class="panel_col">
        <ul>
          <li><img src="../../../../assets/images/0011.jpg" alt=""/></li> -->
          <!--<li></li>
          <li>
            <h5>Winter Wardrobe</h5>
            <h6>Sweaters, boots & more</h6>
            <a routerLink="/product-list">Shop Now</a>
          </li>-->
        <!-- </ul>
      </div>
    </div> -->
    <!--end col md 3-->




    <!--start col md 3-->
    <!-- <div class="col-md-3 col-sm-3 col-6 col_padd">
      <div class="panel_col">
        <ul>
          <li><img src="../../../../assets/images/0011.jpg" alt=""/></li>

        </ul>
      </div>
    </div> -->
    <!--end col md 3-->



    <!--start col md 3-->
    <!-- <div class="col-md-3 col-sm-3 col-6 col_padd">
      <div class="panel_col">
        <ul>
          <li><img src="../../../../assets/images/0011.jpg" alt=""/></li>

        </ul>
      </div>
    </div> -->
    <!--end col md 3-->



    <!--start col md 3-->
    <!-- <div class="col-md-3 col-sm-3 col-6 col_padd">
      <div class="panel_col">
        <ul>
          <li><img src="../../../../assets/images/0011.jpg" alt=""/></li>

        </ul>
      </div>
    </div> -->
    <!--end col md 3-->




  </div>
  <!--end row-->
</div>
<!--end container-->
</section>
<!--end section-->



<!--start section-->
<section class="main_section" style="margin: 10px 0px;">
<!--start container-->
<div class="container padd_control">
  <!--start row-->
<div class="row">
<div class="panel_col col-sm-6 col-12 col_padd" *ngFor="let item of sectionImages?.section_4">
      <div (click)="navigate(item)"  style="cursor: pointer;">
        <img  [src]="item.imageUrl" alt=""/>
      </div>
    </div>
  </div>


  <!--end row-->
</div>
<!--end container-->
</section>
<!--end section-->



<!--start section-->
<section class="main_section">
<!--start container-->
<div class="container container_paddings">
  <!--start row-->
<div class="row">
    <!--start col md 6-->
    <div class="panel_col" style="margin: 10px 0px;">
      <div>
        <img (click)="navigate(sectionImages?.section_5[0])" [src]="sectionImages?.section_5[0]?.imageUrl" alt=""/>
      </div>
    </div>
  </div>
    <!-- <div class="col-md-12 col-sm-12 col_padd media_col">
      <div class="img-cover row">
        <img src="../../../../assets/images/0005.jpg" alt=""/>
      </div>
    </div> -->
    <!--end col md 6-->

    <!--end col md 6-->


  <!--end row-->
</div>
<!--end container-->
</section>
<!--end section-->



<!--start section-->
<section class="main_section">
<!--start container-->
<div class="container padd_control">
  <!--start row-->
  <div class="row">


    <div class="col-md-3 col-sm-3 col-6 col_padd" *ngFor="let item of sectionImages?.section_6">
      <div class="panel_col" >
        <img (click)="navigate(item)" [src]="item.imageUrl"  alt=""/>
      </div>
    </div>



    <!--start col md 3-->
    <!-- <div class="col-md-3 col-sm-3 col-6 col_padd">
      <div class="panel_col">
        <ul>
          <li><img src="../../../../assets/images/111.jpg" alt=""/></li>

        </ul>
      </div>
    </div> -->
    <!--end col md 3-->




    <!--start col md 3-->
    <!-- <div class="col-md-3 col-sm-3 col-6 col_padd">
      <div class="panel_col">
        <ul>
          <li><img src="../../../../assets/images/111.jpg" alt=""/></li>

        </ul>
      </div>
    </div> -->
    <!--end col md 3-->



    <!--start col md 3-->
    <!-- <div class="col-md-3 col-sm-3 col-6 col_padd">
      <div class="panel_col">
        <ul>
          <li><img src="../../../../assets/images/0006.jpg" alt=""/></li>

        </ul>
      </div>
    </div> -->
    <!--end col md 3-->



    <!--start col md 3-->
    <!-- <div class="col-md-3 col-sm-3 col-6 col_padd">
      <div class="panel_col">
        <ul>
          <li><img src="../../../../assets/images/111.jpg" alt=""/></li>
        </ul>
      </div>
    </div> -->
    <!--end col md 3-->




  </div>
  <!--end row-->
</div>
<!--end container-->
</section>
<!--end section-->



<!--start section-->
<section class="main_section">
<!--start container-->
<div class="container container_padd">
  <!--start row-->
  <div class="row">
    <div class="col-panel">
      <h4 style="color: black;">{{'header.ShopByCategory' | translate }}</h4>
    </div>
  </div>
  <!--end row-->
</div>
<!--end container-->
</section>
<!--end section-->

<!--start section-->
<section class="main_section" *ngIf="shopByCategoryList.length > 0" dir="ltr">
  <!--start container-->
  <div class="container left-and-right-padd">
    <!--start row-->
    <div class="row">
<!-- TESTIMONIALS -->
<section class="testimonials" style="width:100%;">
  <div class="padd_control_wrapp">
        <div class="col-sm-12 padd_control_768">
          <div id="customers-testimonials" class="owl-carousel category-panels">
            <!--TESTIMONIAL 1 -->
            <div  (click)="navigate(item)" class="item col-panel-wrapper"  *ngFor="let item of shopByCategoryList">
              <aside>
                <span><a (click)="navigate(item)">{{item?.categoryName}}</a></span>
              </aside>
              <img (click)="navigate(item)" [src]="item?.categoryImages[0]?.imagePath" alt="">
            </div>
            <!--END OF TESTIMONIAL 1 -->

          </div>
        </div>

      </div>
    </section>
    <!-- END OF TESTIMONIALS -->

    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<!--end section-->

<!--start section-->
<!-- <section class="main_section">
<div class="container container_padd">
  <div class="row">
    <div class="col-panel">
      <h4 style="color: black;">{{'header.ShopByBrand' | translate}}</h4>
    </div>
  </div>
</div>
</section> -->


<!--start section-->
<!-- <section class="main_section">
<div class="container padd_control" >
  <div class="row">
    <div class="col-md-3 col-sm-6 col-6 col_padd padd_none_768" *ngFor="let item of shopByBrandsList">
      <div class="col-min-wrapp-panel" class="brightness">
        <img (click)="navigate(item)" [src]="item?.brandImages[0]?.imagePath" alt=""/>
      </div>
    </div>
  </div>
</div>
</section> -->
<!--end section-->


<!--start section-->
<section class="main_section" class="brightness">
<!--start container-->
<div class="container" style="cursor: pointer;">
  <!--start row-->
  <div class="no_margin_col">
    <!--start col md 6-->
    <div class="col-md-12 col-sm-12 padd_sm-cols">
      <div class="top_brand">
        <img (click)="navigate(sectionImages?.section_7[0])" [src]="sectionImages?.section_7[0]?.imageUrl" alt=""/>
      </div>
    </div>
    <!--end col md 6-->

    <!--start col md 6
    <div class="col-md-6 col-sm-6 allBrand">

        <h5><a routerLink="/product-list"> Shop All Brands </a></h5>

    </div>
    end col md 6-->

  </div>
  <!--end row-->
</div>
<!--end container-->
</section>
<!--end section-->


<!--start footer-->

<!--end footer-->


  <!-- Optional JavaScript -->
  <!-- jQuery first, then Popper.js, then Bootstrap JS -->


<script src='js/jquery.min.js'></script>
<script src='js/bootstrap.min.js'></script>
<script src='js/owl.carousel.min.js'></script>
<script src='js/script.js'></script>






