<section class="bg-light">
  <div class="container">
    <div class="row align-items-center">
      <!-- <div class="col-md-6 col-5">
        <h1 class="h2 mb-0">Wish list</h1>
      </div> -->
      <div class="col-md-12 mt-3 mt-md-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item">
              <a class="text-dark" ><i class="fa fa-home mr-1"></i>{{'menu.Home' | translate}}</a>
            </li>
            <li class="breadcrumb-item active text-primary" aria-current="page">{{'header.MyAccount' | translate}}</li>
          </ol>

        </nav>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>




<div class="container" style="margin-top: 2%; margin-bottom: 2%;">


  <div class="row">

    <div class="col-md-3">
      <div class="tab">
        <button class="tablinks" (click)="openCity($event, 'my-account')"><i class="fa fa-user" id="defaultOpen1"
            style="margin:0px 5px ;"></i>{{'header.MyAccount' | translate}}</button>
        <button class="tablinks" (click)="openCity($event, 'order-history'); showOrderDetails =false;" id="defaultOpen2"><i
            class="fa fa-address-card" style="margin:0px 5px ;"></i>{{'header.OrderHistory' | translate}}</button>
        <button class="tablinks"
          (click)="openCity($event, 'delivery-address'); this.isUpdateMode = false; this.addNewAddress =false;" id="defaultOpen3"><i
            class="fa fa-shopping-basket" style="margin:0px 5px ;"></i>{{'label.DeliveryAddress' | translate}}</button>
        <button class="tablinks" (click)="onLogOut()"><i class="fa fa-close"
            style="margin:0px 5px ;"></i>{{'label.LogOut' | translate}}</button>
      </div>
    </div>


    <div class="col-md-9">

      <div id="my-account" class="tabcontent">
        <h4>{{'header.MyAccount' | translate}}</h4>
        <form class="row" [formGroup]="profileForm">
          <div class="col-md-6">
            <div class="form-group">
              <label>{{'label.UserName' | translate}} <span class="validator">*</span> </label>
              <input type="text" formControlName="userName" id="fname" class="form-control"
                placeholder="{{'label.UserName' | translate}}">
                <div class="help-block with-errors">
                  <div class="form-error"
                  *ngIf="(pf.userName.touched && pf.userName.errors) || (pf.userName.dirty && pf.userName.errors) ||  (pfsubmitted && pf.userName.errors)">
                  <div *ngIf="pf.userName.errors.required">{{'errors.Required' | translate}} </div>
                </div>
            </div>
          </div>
</div> 
          <div class="col-md-6">
            <div class="form-group">
              <label>{{'label.Gender' | translate}} <span class="validator">*</span></label>
              <select formControlName="genderId" class="select-css form-control " name="GenderId" id="GenderId">
                <option value="" disabled selected>{{'label.SelectGender' | translate}}</option>
                <option *ngFor="let item of genderList" [value]="item.id">{{item.name}}</option>
              </select>
              <div class="help-block with-errors">
                <div class="form-error"
                *ngIf="(pf.genderId.touched && pf.genderId.errors) || (pf.genderId.dirty && pf.genderId.errors) ||  (pfsubmitted && pf.genderId.errors)">
                <div *ngIf="pf.genderId.errors.required">{{'errors.Required' | translate}} </div>
              </div>
            </div>
          </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>{{'label.Name' | translate}} <span class="validator">*</span> </label>
              <input type="text" formControlName="userFullName" id="fname" class="form-control"
                placeholder="{{'label.Name' | translate}}">
                <div class="help-block with-errors">
                  <div class="form-error"
                  *ngIf="(pf.userFullName.touched && pf.userFullName.errors) || (pf.userFullName.dirty && pf.userFullName.errors) ||  (pfsubmitted && pf.userFullName.errors)">
                  <div *ngIf="pf.userFullName.errors.required">{{'errors.Required' | translate}} </div>
                </div>
            </div>
          </div>
</div>

          <div class="col-md-6">
            <div class="form-group">
              <label>{{'label.Email' | translate}} <span class="validator">*</span></label>
              <input type="text" id="address" formControlName="userEmail" class="form-control"
                placeholder="{{'label.Email' | translate}}">
                <div class="help-block with-errors">
                  <div class="form-error"
                  *ngIf="(pf.userEmail.touched && pf.userEmail.errors) || (pf.userEmail.dirty && pf.userEmail.errors) ||  (pfsubmitted && pf.userEmail.errors)">
                  <div *ngIf="pf.userEmail.errors.required">{{'errors.Required' | translate}} </div>
                  <div *ngIf="pf.userEmail.errors.email">{{'errors.InvalidEmail' | translate}} </div>

                </div>
            </div>
          </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>{{'label.Phone' | translate}} <span class="validator">*</span></label>
              <input type="text" id="address" formControlName="userMobile" class="form-control"
                placeholder="{{'label.Phone' | translate}}">
                <div class="help-block with-errors">
                  <div class="form-error"
                  *ngIf="(pf.userMobile.touched && pf.userMobile.errors) || (pf.userMobile.dirty && pf.userMobile.errors) ||  (pfsubmitted && pf.userMobile.errors)">
                  <div *ngIf="pf.userMobile.errors.required">{{'errors.Required' | translate}} </div>
                </div>
            </div>
          </div> 
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>{{'label.Password' | translate}} <span class="validator">*</span></label>
              <input type="password" id="email" formControlName="password" class="form-control"
                placeholder="{{'label.Password' | translate}}">
                  <div class="help-block with-errors">
              <div class="form-error"
              *ngIf="(pf.password.touched && pf.password.errors) || (pf.password.dirty && pf.password.errors) ||  (pfsubmitted && pf.password.errors)">
              <div *ngIf="pf.password.errors.required">{{'errors.Required' | translate}} </div>
            </div>
            </div>
            </div>
          </div>
          <br />
          <div class="col-md-2">
            <div style="margin-right: 10px; color: #fff;">
              <a class="btn btn-primary btn-animated btn-block" (click)="updateProfileDetails()">{{'button.Update' |
                translate}}</a>
            </div>
          </div>
        </form>
      </div>

      <div id="order-history" class="tabcontent">
        <h4 style="margin: 0px 4px;">
          <span *ngIf="!showOrderDetails"> {{'header.OrderHistory' | translate}} </span>
        </h4>

        <div *ngIf="showOrderDetails" style="margin:5px 0px;" class="order-details-back"><b><a
              (click)="showOrderDetails = false;" style="cursor: pointer; color: red;">{{'label.Back' |
              translate}}</a></b></div>

        <span *ngFor="let item of historyList">
          <div class="col-md-12" *ngIf="!showOrderDetails"
            style="border: 1px solid #ccc; padding: 10px;margin-bottom:10px">
            <div class="row">
              <div class="col-md-2">
                <img
                  [src]="item?.productImages[0]?.imagePath"
                  class="product-imgae-arabic" />
              </div> 

              <div class="col-md-6">
                <h4>{{'label.OrderID' | translate}} : {{item?.billNo}}</h4>
                <ul class="list-unstyled">
                  <li class="mb-p" *ngIf="item?.discountAmount > 0"> QR | {{item?.discounttotalAmount | number : '1.2-2'}}</li>
                  <li class="mb-p" *ngIf="item?.discountAmount == 0"> QR | {{item?.totalAmount | number : '1.2-2'}}</li>

                  <li class="mb-p"> {{'label.Item' | translate}} | {{item?.orderCount}}</li>
                  <li *ngIf="item.isCancelled == false" class="mb-p"><a style="color: red;" >{{'label.Status' | translate}} :
                      {{item?.orderStatus}}</a> 
                    
                  
                    </li>
                  <li *ngIf="item.isCancelled == true" class="mb-p"><a style="color: red;"  >{{'label.Status' | translate}} :
                      {{'label.Cancelled' | translate}}</a> </li>

                      <li *ngIf="item.trackingCode" class="mb-p"><a  >{{'Tracking Code' | translate}} :
                        
                        <span style="cursor: pointer; text-decoration: underline; font-weight: bold;" (click)="getTrackingCodeDetails(item.trackingCode)">
                          {{item?.trackingCode}}

                        </span>
                      

                      </a> 
                      
                    
                      </li>
                </ul>
              </div>


              <div class="col-md-4">
                <h4>{{'label.Date' | translate}} : {{item?.date | date :'dd-MM-yyyy'}}</h4>
                <div data-toggle="modal" data-target="#notifyMe" class="order"
                  *ngIf="item.orderStatusId < 4 && item.isCancelled == false" (click)="createBody(item.orderId)"><a
                    style="width: 50%; color: red;cursor: pointer; text-decoration: underline;">{{'button.CancelOrder' |
                    translate}}</a></div>

                <div class="clear"></div>
                <div class="btn btn-primary btn-animated btn-block" style="width: 50%; margin-top: 15px;" (click)="onOrderSelect(item)"><a
                    style="color: #fff;">{{'label.OrderDetails' | translate}} </a></div>
              </div>
            </div>
          </div>
        </span>


        <div class="row" *ngIf="showOrderDetails">
          <div class="col-md-12" *ngFor="let item of selectedOrderDetails" style="margin-bottom: 10px;">
            <div class="col-md-2 col-lg-2 col-sm-2 col-12 my-account">
              <img
                [src]="item?.productImages[0]?.imagePath"
                style=" border: 1px solid rgb(230, 230, 230); margin-top: 10px;" />
            </div>
            <div class="col-md-10 col-lg-10 col-sm-10 col-12" style="float: left;">
              <div class="cart-checkout">
                <div class="row">
                  <div class="col-md-12">
                    <h4 class="order-head">
                      <span *ngIf="lang ==0">{{item?.productName}}</span>
                      <span *ngIf="lang ==1">{{item?.productNameAr}}</span>
    
                      
                    
                    
                    </h4>
                    <ul class="list-unstyled  quick-view">
                      <li class="mb-p"><span> {{'label.Color' | translate}} : 
                        <span *ngIf="lang ==0">{{item?.colorName}}</span>
                        <span *ngIf="lang ==1">{{item?.colorNameAr}}</span>
                      </span></li>
                      <li class="mb-p"> <span> {{'label.Size' | translate}} : {{item?.sizeOptionName}} </span></li>
                      <li class="mb-p"> <span> {{'label.Quantity' | translate}} : {{item?.quantity}} </span></li>
                      <li class="mb-p"> <span> {{'label.Price' | translate}} : QR {{item?.price}} </span></li>
                    </ul>
                    
                    <button *ngIf="item.productRating == 0" class="btn btn-view my-account" (click)="onClickViewClick(item , 0)" data-toggle="tooltip" data-placement="top" title="Write review" >
                      <span data-target="#quick-view" data-toggle="modal" class="write-reviews"> {{'label.WriteReviews' | translate}}</span>
                    </button>


                    <ngb-rating *ngIf="item.productRating  > 0" [max]="5" [(rate)]="item.productRating" style="font-size: 20px;" [readonly]="true" class="star-margin" ></ngb-rating>

                    <button *ngIf="item.productRating  > 0" style="margin-left: 10px;" class="btn btn-view my-account" (click)="onClickViewClick(item ,1)" data-toggle="tooltip" data-placement="top" title="Update review" >
                      <span data-target="#quick-view" data-toggle="modal" class="write-reviews"> {{'UpdateReviews' | translate}}</span>
                    </button>
                 <span >
                   <!-- <div class="star-rating mb-1" >
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div> -->
              </span>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="padding-bottom: 10px;" *ngIf="showOrderDetails">
          <div class="col-md-12 cart-checkout-margin">
            <div class="col-md-6">
              <div class="cart-checkout">
                <div class="row">
                  <div class="col-md-12">
                    <h4>{{'label.DeliveringTo' | translate}}</h4>
                  </div>
                </div>
                <ul class="list-unstyled">
                  <li class="mb-p"><span> {{'label.Name' | translate}} : {{selectedMainData?.address.name}} </span></li>
                  <li class="mb-p"> <span> {{'label.Address' | translate}} :
                      {{selectedMainData?.address.address1}}</span></li>
                  <li class="mb-p"> <span> {{'label.Landmark' | translate}} :
                      {{selectedMainData?.address.landmark}}</span></li>
                  <li class="mb-p"> <span> {{'label.Phone' | translate}} : {{selectedMainData?.address.mobileNumber}}
                    </span></li>
                </ul>
              </div>
            </div>
            <div class="col-md-6">
              <div class="cart-checkout">
                <div class="row">
                  <div class="col-md-12">
                    <h4>{{'label.Payments' | translate}}</h4>
                  </div>
                </div>
                <ul class="list-unstyled">
                  <li class="mb-p" style="font-size: 17px;"><b><span> {{'label.Total' | translate}} : QR
                        {{selectedMainData?.totalAmount | number : '1.2-2'}}</span></b></li>
                  <li class="mb-p" style="font-size: 17px;" *ngIf="selectedMainData?.discountAmount > 0"><b><span> {{'label.CouponDiscount' | translate}} : QR
                        {{selectedMainData?.discountAmount | number : '1.2-2'}}</span></b></li>
                  <li class="mb-p" style="font-size: 17px;" *ngIf="selectedMainData?.discountAmount > 0"><b><span> {{'label.NetTotal' | translate}} : QR
                        {{selectedMainData?.discounttotalAmount | number : '1.2-2'}}</span></b></li>
                  <li class="mb-p"><span> {{'label.CashOnDelivery' | translate}}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="delivery-address" class="tabcontent">
        <h4>{{'label.DeliveryAddress' | translate}}</h4>
        <div>
          <div *ngIf="addressList.length ===0" style="margin-bottom: 5px">{{'desc.note1' | translate}} </div>
          <div class="clear"></div>

          <span class="row" *ngIf="addNewAddress == false">

            <div class="col-12">
              <div class="row tab-bg" *ngFor="let item of addressList" style="border-bottom: solid 2px #fff;">

                <div class="col-md-8" style="margin:10px 0px 10px 0px; ">
                  <ul>
                    <li><b>{{'label.Name' | translate}} : {{item?.name}}</b></li>
                    <li>{{'label.City' | translate}} : {{item.cityName}}</li>
                    <li>{{'label.Address' | translate}} : {{item.address1}}</li>
                    <li>{{'label.Phone' | translate}} : {{item?.mobileNumber}}</li>
                  </ul>
                </div>
                <div class="col-md-4">
                  <a style="cursor: pointer;text-decoration: underline;" (click)="onEdit(item)" class="ed-button"><i
                      class="fa fa-pencil mr-1"></i>{{'button.Edit' | translate}}</a>
                  <a style="cursor: pointer;text-decoration: underline;" (click)="deleteAddress(item)"
                    class="ed-button"><i class="fa fa-trash mr-1"></i>{{'button.Delete' | translate}}</a>
                </div>
              </div>
            </div>

            <div class="clear"></div>

            <div class="row">
              <div class="col-md-12" style="margin-top:5px; color: #fff;">
                <a class="btn btn-primary btn-animated btn-block"
                  (click)="addNewAddress = true;">{{'button.AddNewAddress' | translate}}</a>
              </div>
            </div>
          </span>

          <form *ngIf="addNewAddress == true" class="row" [formGroup]="deliveryForm">
            <div class="col-md-6">
              <div class="form-group">
                <label>{{'label.Name' | translate}} <span class="validator">*</span> </label>
                <input type="text" formControlName="name" id="fname" class="form-control"
                  placeholder="{{'label.Name' | translate}}">
                  <div class="help-block with-errors">
                    <div class="form-error"
                    *ngIf="(f.name.touched && f.name.errors) || (f.name.dirty && f.name.errors) ||  (submitted && f.name.errors)">
                    <div *ngIf="f.name.errors.required">{{'errors.Required' | translate}} </div>
  
                  </div>
              </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>{{'label.City' | translate}} <span class="validator">*</span></label>
                <select formControlName="cityId" class="select-css form-control " name="GenderId" id="GenderId">
                  <option value="" disabled selected>{{'label.SelectCity' | translate}}</option>
                  <option *ngFor="let item of cityList" [value]="item.cityId">{{item.city1}}</option>

                </select>
                <div class="help-block with-errors">
                  <div class="form-error"
                  *ngIf="(f.cityId.touched && f.cityId.errors) || (f.cityId.dirty && f.cityId.errors) ||  (submitted && f.cityId.errors)">
                  <div *ngIf="f.cityId.errors.required">{{'errors.Required' | translate}} </div>

                </div>
            </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>{{'label.Address' | translate}} <span class="validator">*</span></label>
                <input type="text" id="address" formControlName="address1" class="form-control"
                  placeholder="{{'label.Address' | translate}}">
                  <div class="help-block with-errors">
                    <div class="form-error"
                    *ngIf="(f.address1.touched && f.address1.errors) || (f.address1.dirty && f.address1.errors) ||  (submitted && f.address1.errors)">
                    <div *ngIf="f.address1.errors.required">{{'errors.Required' | translate}} </div>
  
                  </div>
              </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>{{'label.Street' | translate}} <span class="validator">*</span></label>
                <input type="text" id="email" formControlName="street" class="form-control"
                  placeholder="{{'label.Street' | translate}}">
                  <div class="help-block with-errors">
                    <div class="form-error"
                    *ngIf="(f.street.touched && f.street.errors) || (f.street.dirty && f.street.errors) ||  (submitted && f.street.errors)">
                    <div *ngIf="f.street.errors.required">{{'errors.Required' | translate}} </div>
  
                  </div>
              </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>{{'label.Landmark' | translate}} <span class="validator">*</span></label>
                <input type="text" id="email" formControlName="landmark" class="form-control"
                  placeholder="{{'label.Landmark' | translate}}">
                  <div class="help-block with-errors">
                    <div class="form-error"
                    *ngIf="(f.landmark.touched && f.landmark.errors) || (f.landmark.dirty && f.landmark.errors) ||  (submitted && f.landmark.errors)">
                    <div *ngIf="f.landmark.errors.required">{{'errors.Required' | translate}} </div>
  
                  </div>
              </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group mb-md-0">
                <label>{{'label.Phone' | translate}} <span class="validator">*</span></label>
                <input type="text" formControlName="mobileNumber" id="zippostalcode" class="form-control"
                  placeholder="Phone">
                  <div class="help-block with-errors">
                    <div class="form-error"
                    *ngIf="(f.mobileNumber.touched && f.mobileNumber.errors) || (f.mobileNumber.dirty && f.mobileNumber.errors) ||  (submitted && f.mobileNumber.errors)">
                    <div *ngIf="f.mobileNumber.errors.required">{{'errors.Required' | translate}} </div>
  
                  </div>
              </div>
              </div>
            </div><br />
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-3">
                  <div style="width: 99%; color: #fff;" class="order-address-edit">
                    <a class="btn btn-primary btn-animated btn-block" (click)="formSubmit()">
                      <span *ngIf="isUpdateMode">{{'button.Update' | translate}}</span>
                      <span *ngIf="!isUpdateMode">{{'button.Add' | translate}}</span>
                    </a>
                  </div>
                </div>                
                <div class="col-md-3">
                  <div style="width: 99%; color: #fff;" class="order-address-edit">
                    <a class="btn btn-primary btn-animated btn-block" (click)="onFormCancel()">{{'button.Cancel' |
                      translate}}</a>
                  </div>
                </div>
              </div>           
              <div class="col-md-6"></div>
              
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="clear" style="margin-bottom: 20px;"></div>

<div id="notifyMe" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="notify-div">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <h3 class="text-center mb-3 text-uppercase">{{'button.CancelOrder' | translate}}</h3>
          <div class="notify-close">
            <span (click)="popUpClose()" _ngcontent-ins-c62="" aria-hidden="true">×</span>
          </div>


          <form id="contact-form" [formGroup]="cancelForm" (ngSubmit)="cancelOrder()">
            <div class="messages"></div>
            <div class="form-group">
              <label>{{'label.Reason' | translate}} <span class="text-danger">*</span></label>
              <input id="form_password" type="text" name="password" formControlName="Reason" class="form-control"
                placeholder="{{'label.Reason' | translate}}" data-error="password is required.">
              <div class="help-block with-errors">
                <div class="form-error"
                  *ngIf="(rf.Reason.touched && rf.Reason.errors) || (rf.Reason.dirty && rf.Reason.errors) ||  (rsubmitted && rf.Reason.errors)">
                  <div *ngIf="rf.Reason.errors.required">{{'errors.Required' | translate}}</div>
                </div>
              </div>
            </div>

          </form>
          <!-- <span>You will be notified when its avaiable.</span> -->
        </div>
        <div class="modal-footer">
          <a class="btn btn-primary btn-animated" style="color: white;" (click)="cancelOrder()">
            {{'button.CancelOrder' | translate}}</a>

          <button type="button" id="close-button" style="display: none;" class="btn btn-primary btn-md"
            data-dismiss="modal">OK</button>
        </div>

      </div>
    </div>

  </div>
</div>



















<div class="modal fade view-modal" id="quick-view" tabindex="-1" role="dialog" aria-hidden="true" style="margin-bottom: 90px;">
  <div class="modal-dialog modal-lg" style="width: 500px;" role="document">
    <div class="modal-content">
      <div class="modal-header border-bottom-0 pb-0">
        <button type="button" id="closeId" class="close" data-dismiss="modal" aria-label="Close" > <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <h2 class="popup-text-margin">Rate </h2>
          </div>
          <div class="row">
            <ngb-rating [max]="5" [(rate)]="productRating" style="font-size: 30px;" [readonly]="false" class="popup-text-margin" ></ngb-rating>
          </div>
         <div class="row">
            
            <span class="popup-text-margin">{{'label.Writereviews' | translate}}</span>
            <textarea cols="80" rows="5" [(ngModel)]="review" style="margin: 10px 15px 10px 15px; border: dashed 1px rgb(102, 102, 102);" class="popup-text-feild" >
            </textarea>
           
         <div class="col-4">
          <a class="btn btn-primary btn-animated btn-block" style="color: #fff;" (click)="onReviewSubmit()">
          <span>Submit</span>
          </a></div>

          
        </div>
</div>
</div>
</div>
</div>
</div>