



<section class="bg-light">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-12 mt-3 mt-md-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item"><a class="text-dark" ><i class="fa fa-home mr-1"></i>{{'menu.Home' | translate}}</a>
            </li>
            <li class="breadcrumb-item active text-primary" aria-current="page">{{'header.ContactUs' | translate}}</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
  </section>

  <div class="page-content">

  <section>
  <div class="container">
    <div class="row mb-5">
      <div class="col-lg-8">
        <div class="mb-5">
          <!-- <h6 class="text-primary mb-1">— {{'header.ContactUs' | translate}}</h6> -->
          <h2 class="mb-0 contacts">{{'desc.Wedlovetohearfromyou' | translate}}</h2>
        </div>
        <form id="contact-form" class="row" method="post" action="http://themeht.com/ekocart/html/php/contact.php">
            <div class="messages"></div>
            <div class="form-group col-md-6">
              <label>{{'label.FirstName' | translate}} <span class="text-danger">*</span></label>
              <input id="form_name" type="text" name="name" class="form-control" placeholder="{{'label.FirstName' | translate}} " required="required" data-error="Name is required.">
              <div class="help-block with-errors"></div>
            </div>
            <div class="form-group col-md-6">
              <label>{{'label.LastName' | translate}} <span class="text-danger">*</span></label>
              <input id="form_name1" type="text" name="name" class="form-control" placeholder="{{'label.LastName' | translate}}" required="required" data-error="Name is required.">
              <div class="help-block with-errors"></div>
            </div>
            <div class="form-group col-md-6">
              <label>{{'label.Email' | translate}} <span class="text-danger">*</span></label>
              <input id="form_email" type="email" name="email" class="form-control" placeholder="{{'label.Email' | translate}}" required="required" data-error="Valid email is required.">
              <div class="help-block with-errors"></div>
            </div>
            <div class="form-group col-md-6">
              <label>{{'label.Phone' | translate}} <span class="text-danger">*</span></label>
              <input id="form_phone" type="tel" name="phone" class="form-control" placeholder="{{'label.Phone' | translate}}" required="required" data-error="Phone is required">
              <div class="help-block with-errors"></div>
            </div>
            <div class="form-group col-md-12">
              <label>{{'label.Message' | translate}} <span class="text-danger">*</span></label>
              <textarea id="form_message" name="message" class="form-control" placeholder="{{'label.Message' | translate}}" rows="4" required="required" data-error="Please,leave us a message."></textarea>
              <div class="help-block with-errors"></div>
            </div>
            <div class="col-md-12 mt-4">
              <button class="btn btn-primary btn-animated"><span>{{'button.SendMessage' | translate}}</span>
              </button>
            </div>
          </form>
      </div>
      <div class="col-lg-4 mt-6 mt-lg-0">
        <div class="shadow-sm rounded p-5">
          <div class="mb-5">
          <h6 class="text-primary mb-1">— {{'label.ContactInfo' | translate}}</h6>
          <h4 class="mb-0 contacts">{{'desc.WeArehereTohelpYou' | translate}}</h4>
        </div>
        <div class="d-flex mb-3">
          <div class="mr-2"> <i class="fa fa-map ic-2x text-primary"></i>
          </div>
          <div>
            <h6 class="mb-1 text-dark contacts"> {{'label.StoreAddress' | translate}}</h6>
            <p class="mb-0 text-muted">{{common?.configDetails?.address}}</p>
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="mr-2"> <i class="fa fa-envelope ic-2x text-primary"></i>
          </div>
          <div>
            <h6 class="mb-1 text-dark contacts"> {{'label.EmailUs' | translate}}</h6>
            <a class="text-muted" href="mailto:themeht23@gmail.com"> {{common?.configDetails?.email}}</a>
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="mr-2"> <i class="fa fa-mobile ic-2x text-primary"></i>
          </div>
          <div>
            <h6 class="mb-1 text-dark contacts"> {{'label.Phone' | translate}}</h6>
            <a class="text-muted" href="tel:+912345678900">{{common?.configDetails?.phone}}</a>
          </div>
        </div>
        <div class="d-flex mb-5">
          <div class="mr-2"> <i class="fa fa-clock ic-2x text-primary"></i>
          </div>
          <div>
            <h6 class="mb-1 text-dark contacts" contacts> {{'label.WorkingHours' | translate}}</h6>
            <span class="text-muted">Mon - Fri: 10AM - 7PM</span>
          </div>
        </div>
        <ul class="list-inline contacts">
          <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2" href={{common?.configDetails?.facebookUrl}}>
            <i class="fa fa-facebook"></i></a>
          </li>
          <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2" href={{common?.configDetails?.driblleUrl}}>
            <i class="fa fa-dribbble"></i></a>
          </li>
          <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2" href={{common?.configDetails?.instagramUrl}}>
            <i class="fa fa-instagram"></i></a>
          </li>
          <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2" href={{common?.configDetails?.twitterUrl}}>
            <i class="fa fa-twitter"></i></a>
          </li>
          <li class="list-inline-item"><a class="bg-white shadow-sm rounded p-2" href={{common?.configDetails?.linkedInUrl}}>
            <i class="fa fa-linkedin"></i></a>
          </li>
        </ul>
        </div>
      </div>
    </div>

  </div>
  </section>

  <!-- <section class="pt-0">
  <div class="container">
    <hr class="mt-0 mb-10">
    <div class="row justify-content-center text-center mb-5">
      <div class="col-lg-8">
        <div>
          <h6 class="text-primary mb-1">— Easy to Find</h6>
          <h2 class="mb-0">Our Store Location</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="map" style="height: 500px;">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.840108181602!2d144.95373631539215!3d-37.8172139797516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4c2b349649%3A0xb6899234e561db11!2sEnvato!5e0!3m2!1sen!2sin!4v1497005461921" allowfullscreen="" class="w-100 h-100 border-0"></iframe>
        </div>
      </div>
    </div>
  </div>
  </section> -->

  <!--instagram start-->

  <section class="p-0">
  <div class="container-fluid p-0">
    <div class="row no-gutters">
      <div class="col-md-8 col-lg-4 order-lg-1 align-items-center d-flex justify-content-center bg-dark p-5">
        <div class="d-flex align-items-center">
          <img class="img-fluid" src="../../../../assets/assets/images/insta/instagram-logo.png" alt="">
          <div class="ml-3">
            <h6 class="text-white mb-0">{{'label.FollowUsOn' | translate}}</h6>
            <h3 class="text-white mb-0">{{'label.Instagram' | translate}}</h3>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4 col-lg-2">
        <a class="insta-item" >
          <img class="img-fluid" src="../../../../assets/assets/images/insta/01.jpg" alt="">
          <div class="insta-icon"><i class="lab la-instagram"></i>
          </div>
        </a>
      </div>
      <div class="col-6 col-md-4 col-lg-2">
        <a class="insta-item" >
          <img class="img-fluid" src="../../../../assets/assets/images/insta/02.jpg" alt="">
          <div class="insta-icon"><i class="lab la-instagram"></i>
          </div>
        </a>
      </div>
      <div class="col-6 col-md-4 col-lg-2 order-lg-1">
        <a class="insta-item" >
          <img class="img-fluid" src="../../../../assets/assets/images/insta/03.jpg" alt="">
          <div class="insta-icon"><i class="lab la-instagram"></i>
          </div>
        </a>
      </div>
      <div class="col-6 col-md-4 col-lg-2 order-lg-1">
        <a class="insta-item" >
          <img class="img-fluid" src="../../../../assets/assets/images/insta/04.jpg" alt="">
          <div class="insta-icon"><i class="lab la-instagram"></i>
          </div>
        </a>
      </div>
    </div>
  </div>
  </section>

  <!--instagram end-->

  </div>


















