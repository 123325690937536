


<footer>

 

  <!-- <div class="media_controls1">
    <div class="col-cover" style=" height: 100%;">
      <ul>
        <li style="border-left: none;"><i class="fa fa-home" aria-hidden="true" style=" font-size: 25px;"></i> <br>Home
        </li>
        <li><i class="fa fa-user" aria-hidden="true" style=" font-size: 25px;"></i> <br> Account</li>
        <li><i class="fa fa-heart" aria-hidden="true" style=" font-size: 25px;"></i><br>Wishlist</li>
        <li><i class="fa fa-shopping-basket" aria-hidden="true" style=" font-size: 25px;"></i> <br> Cart</li>

      </ul>

    </div>
  </div> -->
  <div class="media_controls1">
    <div class="col-cover" style=" height: 100%;">
      <ul>
        <li routerLink="/" style=" font-size: 14px;"><i class="fa fa-home" aria-hidden="true"></i> <br>{{'menu.Home' |
          translate}}</li>
        <li style=" font-size: 14px;"><i (click)="footerNavigation(1)" class="fa fa-user-o" aria-hidden="true"></i> <br>
          {{'label.Account' | translate}}</li>
        <li style=" font-size: 14px;"><i (click)="footerNavigation(2)" class="fa fa-heart" aria-hidden="true"></i><br>{{'menu.Wishlist' | translate}}</li>
        <li routerLink="/product-cart" style=" font-size: 14px;"><aside>{{common.cartList.length}}</aside><i  class="fa fa-shopping-basket" aria-hidden="true" ></i> <br>
          {{'menu.Cart' | translate}}</li>

      </ul>

    </div>
  </div>
  <!--start container-->
  <div class="container">
    <!--start row-->
    <div class="row">
      <!--start col md 9-->
      <div class="col-md-9 col-sm-12">
        <!--start row-->
        <div class="row">
          <!--start col md 4-->
          <div class="col-md-4 col-sm-6">
            <div class="footer_panel">
              <h4>{{'label.CustomerSupport' | translate}}</h4>
              <h5>{{'desc.WeAreAlwaysReadyToSupportYou' | translate}}</h5>
              <ul>
                <li><span><i class="fa fa-map-marker" aria-hidden="true"></i></span> {{common?.configDetails?.address}}
                </li>
                <li dir="ltr"><span><i class="fa fa-phone" aria-hidden="true"></i></span>
                  <!-- +1-541-754-3010 -->
                  {{common?.configDetails?.phone}}
                </li>
                <li><span><i class="fa fa-envelope" aria-hidden="true"></i></span> {{common?.configDetails?.email}}</li>
              </ul>
            </div>
          </div>
          <!--end col md 4-->


          <!--start col md 4-->
          <div class="col-md-4 col-sm-6">
            <div class="footer_panel">
              <h4>{{'menu.About' | translate}}</h4>
              <h5></h5>
              <ul>
                <li class="padding_none"><a routerLink="/about">{{'menu.AboutUs' | translate}}</a></li>
                <li class="padding_none"><a routerLink="/privacy-policy">{{'menu.PrivacyPolicy' | translate}}</a></li>
                <li class="padding_none"><a routerLink="/terms-and-conditions">{{'menu.Terms&Conditions' |
                    translate}}</a></li>
                    <li class="padding_none"><a routerLink="/vendor-registration">{{'menu.VendorRegistration' |
                      translate}}</a></li>
                <li class="padding_none"><a routerLink="/">{{'menu.Home' | translate}}</a></li>
                <li class="padding_none"><a routerLink="/contact">{{'menu.ContactUs' | translate}}</a></li>


              </ul>
            </div>
          </div>
          <!--end col md 4-->



          <!--start col md 4-->
          <div class="col-md-4 col-sm-6">
            <div class="footer_panel">
              <h4>{{'menu.CustomerServices' | translate}}</h4>
              <h5></h5>
              <ul>
                <li class="padding_none"><a routerLink="/shipping-information">{{'menu.ShippingInformation' |
                    translate}}</a></li>
                <li class="padding_none"><a routerLink="/return-policy">{{'menu.ReturnPolicy' | translate}}</a></li>
                <li class="padding_none"><a routerLink="/faq">{{'menu.FAQ' | translate}}</a></li>
                <li *ngIf="common?.isLoggedIn" class="padding_none"><a routerLink="/my-account">{{'menu.MyProfile' |
                    translate}}</a></li>
              </ul>
            </div>
          </div>
          <!--end col md 4-->



        </div>
        <!--end row-->
      </div>
      <!--end col md 9-->

      <!--start col md 3-->
      <div class="col-md-3 col-sm-12">

        <div class="side_menu">
          <div class="footer_panel ">
         
            <h4>{{'menu.DOWNLOADTHEAPP' | translate}}</h4>
  
          </div>
  
          <div class="row margin-control sm_padd padding_footer_app">
            <div class="col-md-6 col-sm-6 padd-5">
              <div class="app">
                <a target="blank" href="{{common?.configDetails?.appStoreLink}}"><img
                    src="../../../../assets/images/icons/app1.png" alt=""></a>
              </div>
            </div>
  
            <div class="col-md-6 col-sm-6 padd-5">
              <div class="app">
                <a target="blank" href="{{common?.configDetails?.playStoreLink}}"><img
                    src="../../../../assets/images/icons/app2.png" alt=""></a>
              </div>
            </div>
  
          </div>

        </div>
        



    <div>

      <div class="footer_panel ">
        <h4>{{'menu.FOLLOWTHELATESTTRENDS' | translate}}</h4> </div>

        <div class="socila_media">
          <ul>

            <li><a href="{{common?.configDetails?.facebookUrl}}" target="blank"><i class="fa fa-facebook-official"
                  aria-hidden="true"></i></a></li>
            <li><a href="{{common?.configDetails?.twitterUrl}}" target="blank"><i class="fa fa-twitter-square"
                  aria-hidden="true"></i></a></li>
            <li><a href="{{common?.configDetails?.instagramUrl}}" target="blank"><i class="fa fa-instagram"
                  aria-hidden="true"></i></a></li>
            <li><a href="{{common?.configDetails?.linkedInUrl}}" target="blank"><i class="fa fa-linkedin-square"
                  aria-hidden="true"></i></a></li>
          </ul>
        </div>


    </div>>
        



      </div>
      <!--end col md 3-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->


  <!--start container-->
  <div class="container">
    <!--start row-->
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-3 col-sm-3">
            <a routerLink="/" class="footer_logo">
              <img *ngIf="lang ===1" src="../../../.././assets/images/logo/footerlogo1.jpg" alt="" />
              <img *ngIf="lang ===0" src="../../../.././assets/images/logo/footerlogo2.jpg" alt="" />

            </a>
          </div>

          <div class="col-md-9 col-sm-9 copy-right">
            <p>Copyright @ 2023 Anbar All rights Reserved</p>
          </div>
        </div>
      </div>



      <div class="col-md-6">
        <div class="row footer_right_padd">
          <div class="col-md-6 col-sm-7"></div>
          <div class="col-md-5 col-sm-8 btm_icons">
            <img src="../../../../assets/images/icons/icons.jpg" alt="" />
          </div>

        </div>
      </div>

    </div>
    <!--end row-->
  </div>
  <!--end container-->  
</footer>