import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './views/pages/landing/landing.component';
import { ProductListComponent } from './views/pages/product-list/product-list.component';
import { HeaderComponent } from './views/shared/header/header.component';
import { FooterComponent } from './views/shared/footer/footer.component';
import { SearchBoxComponent } from './views/shared/search-box/search-box.component';
import { AboutUsComponent } from './views/pages/about-us/about-us.component';
import { CheckOutComponent } from './views/pages/check-out/check-out.component';
import { ContactComponent } from './views/pages/contact/contact.component';
import { LoginComponent } from './views/pages/login/login.component';
import { OrderCompleteComponent } from './views/pages/order-complete/order-complete.component';
import { ProductCartComponent } from './views/pages/product-cart/product-cart.component';
import { ProductDetailsComponent } from './views/pages/product-details/product-details.component';
import { ProfileComponent } from './views/pages/profile/profile.component';
import { SignUpComponent } from './views/pages/sign-up/sign-up.component';
import { WishListComponent } from './views/pages/wish-list/wish-list.component';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ProductQuickViewComponent } from './views/popups/product-quick-view/product-quick-view.component';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgImageSliderModule } from 'ng-image-slider';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComingSoonComponent } from './views/pages/coming-soon/coming-soon.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { CartCheckOutComponent } from './views/pages/cart-check-out/cart-check-out.component';
import { ForgotPasswordComponent } from './views/pages/forgot-password/forgot-password.component';
import { PrivacyPolicyComponent } from './views/pages/privacy-policy/privacy-policy.component';
import { MyAccountComponent } from './views/pages/my-account/my-account.component';
import { ShippingInformationComponent } from './views/pages/shipping-information/shipping-information.component';
import { ReturnPolicyComponent } from './views/pages/return-policy/return-policy.component';
import { FaqComponent } from './views/pages/faq/faq.component';
import { TermsAndConditionsComponent } from './views/pages/terms-and-conditions/terms-and-conditions.component';
import { MyAccountDetComponent } from './views/pages/my-account-det/my-account-det.component';
import { ConfirmationDialogComponent } from './views/shared/confirmation-dialog/confirmation-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavigatorComponent } from './views/pages/navigator/navigator.component'; // <-- import the module
// import {MatExpansionModule} from '@angular/material/expansion';
import { FacebookModule } from 'ngx-facebook';
import { CartCheckoutPageComponent } from './views/pages/cart-checkout-page/cart-checkout-page.component';
import { VerifyCustomerComponent } from './views/pages/verify-customer/verify-customer.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { VendorRegComponent } from './views/pages/vendor-reg/vendor-reg.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    ProductListComponent,
    HeaderComponent,
    FooterComponent,
    SearchBoxComponent,
    AboutUsComponent,
    CheckOutComponent,
    ContactComponent,
    LoginComponent,
    OrderCompleteComponent,
    ProductCartComponent,
    ProductDetailsComponent,
    ProfileComponent,
    SignUpComponent,
    WishListComponent,
    ProductQuickViewComponent,
    ComingSoonComponent,
    CartCheckOutComponent,
    ForgotPasswordComponent,
    PrivacyPolicyComponent,
    MyAccountComponent,
    ShippingInformationComponent,
    ReturnPolicyComponent,
    FaqComponent,
    TermsAndConditionsComponent,
    MyAccountDetComponent,
    ConfirmationDialogComponent,
    NavigatorComponent,
    CartCheckoutPageComponent,
    VerifyCustomerComponent,
    VendorRegComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    NgImageSliderModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    FacebookModule.forRoot(),
    NgbModule,
    // MatExpansionModule, 
    CarouselModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
  }),


  ],
  providers: [FormBuilder , DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
