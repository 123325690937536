import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DbService } from 'src/app/services/db.service';
declare function showNotification(type : string,titile : string,message :string): any;

@Component({
  selector: 'app-vendor-reg',
  templateUrl: './vendor-reg.component.html',
  styleUrls: ['./vendor-reg.component.scss']
})
export class VendorRegComponent implements OnInit {

  regForm : FormGroup;
  submitted : boolean = false;
  mode : number = 0;  // 0 for email and 1 for mobile
  genderList =[
    {id : 1 , name :'Male'},
    {id : 2 , name :'Female'},
    {id : 3 , name :'Prefer not to say'},


  ]
  constructor(private fb : FormBuilder , private db : DbService ,
    private spinner : NgxSpinnerService,
    private router: Router) { }

  ngOnInit(): void {
    this.buildForm();
  }


  buildForm(){
    this.regForm=this.fb.group({
      vendorId :[0,Validators.required],
      vendorName :["",Validators.required],
      vendorNameAr :[""],
      contactPerson :["",Validators.required],
      phone:["",Validators.required],
      email :["",[Validators.email , Validators.required]],
      address :["",Validators.required],
      isActive :[true,Validators.required], 
    })
  }

 get f() { return this.regForm.controls };
  onSubmit(){
    console.log(this.regForm.value)

    this.submitted = true;


    if(this.regForm.invalid){
      return;
    }


    this.spinner.show();
    console.log(this.regForm.value)
    this.db.methodPost("Vendors", this.regForm.value).subscribe(resdata =>{
      console.log(resdata);
      this.spinner.hide();
      if(resdata.vendorId > 0){
        this.buildForm();
        this.submitted = false;
          showNotification('success','Success','Successfully registered !. Our team will contact you soon');
      }
      else{
        showNotification('error','Failed','Failed !');
      }
    },
     error => {
       this.spinner.hide();
    showNotification('error','Failed',error.error.message);
     }
    )
  }

}
