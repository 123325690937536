<section class="bg-light">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-12 mt-3 mt-md-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item"><a class="text-dark" href="#"><i class="las la-home mr-1"></i>{{'menu.Home' | translate}}</a>
            </li>
            <li class="breadcrumb-item active text-primary" aria-current="page">{{'label.ForgotPassword' | translate}}</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
  </section>



  <div class="page-content">

  <!--login start-->

  <section>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5">
        <div class="shadow p-3">

          <h3 class="text-center mb-3 text-uppercase">{{'label.ForgotPassword' | translate}}</h3>
          <form id="contact-form" [formGroup]="signInForm" (ngSubmit)="onSubmit()">
            <div class="messages"></div>
            <div *ngIf="isSendVerification == false">
              <div class="form-group">
                <label>{{'desc.Pleaseenteryouemailtosendverificationlink' | translate}} <span class="text-danger">*</span></label>
                <input id="form_password" type="text" name="password"  formControlName="Email" class="form-control" placeholder="email" data-error="password is required.">
                <div class="help-block with-errors">
                  <div class="form-error"
                  *ngIf="(f.Email.touched && f.Email.errors) || (f.Email.dirty && f.Email.errors) ||  (submitted && f.Email.errors)">
                  <div *ngIf="f.Email.errors.required">{{'errors.EmailIsRequired' | translate}}</div>
                </div>
                </div>
              </div>
              <a (click)="sendVerification()" class="btn btn-primary btn-animated" style="color: white;">{{'desc.SendVerificationLink' | translate}}</a>
            </div>


            <div *ngIf="isSendVerification">
              <div class="form-group" >
                <label>{{'label.Password'  | translate}} <span class="text-danger">*</span></label>
                <input id="form_password" type="password" name="password"  formControlName="Password" class="form-control" placeholder="Password" data-error="password is required.">
                <div class="help-block with-errors">
                  <div class="form-error"
                  *ngIf="(f.Password.touched && f.Password.errors) || (f.Password.dirty && f.Password.errors) ||  (submitted && f.Password.errors)">
                  <div *ngIf="f.Password.errors.required">{{'errors.PasswordIsRequired' | translate}}</div>
                </div>
                </div>
              </div>
              <div class="form-group">
                <label>Confirm Password <span class="text-danger">*</span></label>
                <input id="form_password" type="password" name="password"  formControlName="ConfirmPassword" class="form-control" placeholder="Password" data-error="password is required.">
                <div class="help-block with-errors">
                  <div class="form-error"
                  *ngIf="(f.ConfirmPassword.touched && f.ConfirmPassword.errors) || (f.ConfirmPassword.dirty && f.ConfirmPassword.errors) ||  (submitted && f.ConfirmPassword.errors)">
                  <div *ngIf="f.ConfirmPassword.errors.required">{{'errors.ConfirmPasswordIsRequired' | translate}}</div>
                </div>
                </div>
              </div>
              <div class="form-group mt-4 mb-3">
                <div class="remember-checkbox d-flex justify-content-between">
                  <!-- <div class="checkbox">
                    <input type="checkbox" id="check2" name="check2">
                    <label for="check2">Remember me</label>
                  </div> -->
                   <!-- <a (click)="onForgotPassword()">Forgot Password?</a> -->
                </div>
              </div>
               <a (click)="onSubmit()" class="btn btn-primary btn-animated" style="color: white;">{{'button.Confirm' |translate}}</a>
            </div>






          </form>
          <!-- <div class="d-flex align-items-center text-center justify-content-center mt-4">
                  <span class="text-muted mr-1">Don't have an account?</span>
                   <a routerLink="/signup">Sign Up</a>
                </div> -->
        </div>
      </div>
    </div>
  </div>
  </section>

  <!--login end-->

  </div>


