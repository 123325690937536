<section class="bg-light">
  <div class="container">
    <div class="row align-items-center">
      <!-- <div class="col-md-6">
        <h1 class="h2 mb-0">Product list</h1>
      </div> -->
      <div class="col-md-12 mt-3 mt-md-0">
        <nav>
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item"><a class="text-dark" routerLink="/"><i class="fa fa-home mr-1"></i>{{'menu.Home'
                | translate}}</a>
            </li>
            <li class="breadcrumb-item" *ngIf="menuName" (click)="onCategClick()">
              <span *ngIf="lang == 0">{{menuName}}</span>
              <span *ngIf="lang ==1">{{menuNameAr}}</span>
            </li>
            <li class="breadcrumb-item active text-primary" aria-current="page" *ngIf="subMenuName">
              <span *ngIf="lang == 0">{{subMenuName}}</span>
              <span *ngIf="lang ==1">{{subMenuNameAr}}</span>
            </li>
            <li class="breadcrumb-item active text-primary" aria-current="page"
              *ngIf="subMenuBrandName && !subMenuName">
              <span *ngIf="lang == 0">{{subMenuBrandName}}</span>
              <span *ngIf="lang ==1">{{subMenuBrandNameAr}}</span>
            
            </li>

          </ol>
        </nav>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>


<div class="page-content">


  <div class="modal left fade " id="left_modal_sm" tabindex="-1" role="dialog" aria-labelledby="left_modal_sm">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content">
        <div class="modal-header ">
          <h5 class="modal-title">Refine</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="p-5">
            
            <div class="widget widget-categories product-list-price">
              <h4 class="widget-title mb-3">{{'label.Categories' | translate}}</h4>
              <div id="accordion" class="accordion">
                <div class="card border-0 mb-3" *ngFor="let item of filterCategoryList">
                  <div class="card-header">
                    <h6 class="mb-0">
                      <a class="link-title" data-toggle="collapse" data-parent="#accordion"
                        href="#collapse{{item.categoryId}}">
                        <span *ngIf="lang == 0">
                          {{item.categoryName}}
                        </span>
                        <span *ngIf="lang == 1">
                          {{item.categoryNameAr}}
                        </span>
                      </a>
                    </h6>
                  </div>
                  <div id="collapse{{item.categoryId}}" class="collapse" data-parent="#accordion">
                    <div class="card-body text-muted">
                      <ul class="list-unstyled">
                        <li *ngFor="let innerItem of item?.subCategories"><a href="#">
                            <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input"
                                id="innerItem{{innerItem?.subCategoryId}}" [(ngModel)]="innerItem.checked"
                                (change)="onSubCategChecked(innerItem , $event)">
                              <label class="custom-control-label innerCat" for="innerItem{{innerItem?.subCategoryId}}">

                                <span *ngIf="lang ==0">
                                  {{innerItem?.subCategoryName}}

                                </span>
                                <span *ngIf="lang ==1">
                                  {{innerItem?.subCategoryNameAr}}

                                </span>


                              </label>
                            </div>
                          </a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="widget widget-price product-list-price">
              <h4 class="widget-title mb-3">{{'label.Price'| translate}}</h4>
              <div *ngFor="let item of priceList">

                <label class="radio radio-gradients">
                  <span class="radio__input">
                    <input type="radio" name="radio111" [value]="item" [(ngModel)]="selectedPriceSection"
                      (change)="getProductList()">
                    <span class="radio__controls color1"></span>
                  </span>
                  <span class="radio__label pricelable" style="">{{item.name}} </span>

                </label>
              </div>


            </div>
            <!-- <div class="widget widget-brand mb-4 pb-4 border-bottom brand-prodcut-anbar">
              <h4 class="widget-title mb-3">{{'label.Brand' | translate}}</h4>
              <div class="custom-control custom-checkbox mb-2" *ngFor="let item of filterBrandList">
                <input type="checkbox" class="custom-control-input" [id]="item?.brandId" [(ngModel)]="item.checked"
                  (change)="onBrandChecked(item , $event)">
                <label class="custom-control-label" [for]="item?.brandId">
                  <span *ngIf="lang == 0">
                    {{item.brandName}}
                  </span>
                  <span *ngIf="lang == 1">
                    {{item.brandNameAr}}
                  </span>

                </label>
              </div>

            </div> -->









            <div class="widget widget-color mb-4 pb-4 border-bottom brand-prodcut-anbar">
              <h4 class="widget-title mb-3">{{'label.Color' | translate}}</h4>
              <!-- <ul class="list-inline color-select">
                <li *ngFor="let item of filterColorsList">
                  <a *ngIf="lang == 0" href="#" [attr.aria-label]="item.color1">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="color{{item.colorId}}" name="Radios"
                        [(ngModel)]="item.checked" (change)="onColorChecked(item , $event)">
                      <label class="form-check-label" for="color{{item.colorId}}"
                        [style.background-color]="item?.colorCode"></label>
                    </div>
                  </a>
                  <a  *ngIf="lang == 1" href="#" [attr.aria-label]="item.colorAr">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" id="color{{item.colorId}}" name="Radios"
                        [(ngModel)]="item.checked" (change)="onColorChecked(item , $event)">
                      <label class="form-check-label" for="color{{item.colorId}}"
                        [style.background-color]="item?.colorCode"></label>
                    </div>
                  </a>
                 
                </li>

              </ul> -->
              <!-- <div class="select-1">
                <select>
                  <option value="1">Select Color</option>
                  <option value="2">Black</option>
                  <option value="3">White</option>
                </select>
              </div> -->
            </div>

            <div class="widget widget-size brand-prodcut-anbar">
              <h4 class="widget-title mb-3">{{'label.Size' | translate}}</h4>
              <ul class="list-inline clearfix">
                <li *ngFor="let item of filterSizeOptions">
                  <input [name]="item?.sizeOptionName" [id]="item?.sizeOptionId*33" [value]="item?.sizeOptionId"
                    type="radio" [(ngModel)]="selectedFilterSizeOption">
                  <label [for]="item?.sizeOptionId*33">{{item?.eU_SizeOption}}</label>
                </li>
                <!-- <li>
                      <input name="sc" id="xs-size" type="radio">
                      <label for="xs-size">XS</label>
                    </li>
                    <li>
                      <input name="sc" id="s-size" type="radio">
                      <label for="s-size">S</label>
                    </li>
                    <li>
                      <input name="sc" id="m-size" checked="" type="radio">
                      <label for="m-size">M</label>
                    </li>
                    <li>
                      <input name="sc" id="l-size" type="radio">
                      <label for="l-size">L</label>
                    </li>
                    <li>
                      <input name="sc" id="xl-size" type="radio">
                      <label for="xl-size">XL</label>
                    </li>
                    <li>
                      <input name="sc" id="xxl-size" type="radio">
                      <label for="xxl-size">XXL</label>
                    </li>
                    <li>
                      <input name="sc" id="3xl-size" type="radio">
                      <label for="3xl-size">3XL</label>
                    </li>
                    <li>
                      <input name="sc" id="4xl-size" type="radio">
                      <label for="4xl-size">4XL</label>
                    </li>
                    <li>
                      <input name="sc" id="5xl-size" type="radio">
                      <label for="5xl-size">5XL</label>
                    </li>
                    <li>
                      <input name="sc" id="36-size" type="radio">
                      <label for="36-size">36</label>
                    </li>
                    <li>
                      <input name="sc" id="38-size" type="radio">
                      <label for="38-size">38</label>
                    </li>
                    <li>
                      <input name="sc" id="39-size" type="radio">
                      <label for="39-size">39</label>
                    </li>
                    <li>
                      <input name="sc" id="40-size" type="radio">
                      <label for="40-size">40</label>
                    </li>
                    <li>
                      <input name="sc" id="42-size" type="radio">
                      <label for="42-size">42</label>
                    </li>
                    <li>
                      <input name="sc" id="44-size" type="radio">
                      <label for="44-size">44</label>
                    </li> -->
              </ul>
            </div>
          </div>

        </div>
        <div class="modal-footer modal-footer-fixed">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <section>
    <div class="container">
      <div class="row">

        <div class="col-lg-9 col-md-12 order-lg-1">
          <div class="row align-items-center">
            <!-- <div class="col-md-5 mb-3 mb-md-0"> <span class="text-muted">Showing {{((p-1) * p) + 1}} to {{((p-1) * p) + itemPerPage }} of {{productList?.length}} total -->
            <div class="col-md-5 mb-3 mb-md-0 text_right"> <span class="text-muted">
                <!-- <button type="button" class="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Tooltip on top">
                      Tooltip on top
                    </button> -->

                {{'label.Showing' | translate}} {{totalCount}} {{'label.Products' | translate}}
              </span>
            </div>
            <div class="col-md-7 d-flex align-items-center justify-content-md-end">
              <div class="view-filter">
                <a class="d-none d-xl-block" href="#"><i class="fa fa-list"></i></a>
                <a class="d-block d-sm-none" href="#" data-toggle="modal" data-target="#left_modal_sm">
                  <i class="fa fa-filter"></i>
                </a>
              </div>
              <div class="sort-filter ml-2 d-flex align-items-center">
                <select class="custom-select" id="inputGroupSelect02" (change)="getProductList()"
                  [(ngModel)]="selectedSortOption">
                  <option selected disabled value="0">{{'label.SortBy' | translate}}</option>
                  <option [value]="item.id" *ngFor="let item of sortList">
                    <span *ngIf="lang ==0">{{item.name}}</span>
                    <span *ngIf="lang ==1">{{item.nameAr}}</span>
                  </option>

                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <ng-container
              *ngFor="let item of productList | paginate: { itemsPerPage: itemPerPage, currentPage: p ,  totalItems: totalCount }">
              <div class="col-lg-3 col-md-6 col-6 mt-md-8 mt-lg-0">
                <div class="card product-card">
                  <button style="cursor: pointer;" *ngIf="!common.isLoggedIn" (click)="addWishList(item)"
                    class="btn-wishlist btn-sm" type="button" data-toggle="tooltip" data-placement="left"
                    title="Add to wishlist"><i class="fa fa-heart"></i>
                  </button>
                  <button style="cursor: pointer;" *ngIf="common.isLoggedIn" (click)="onQuickView(item)"
                    data-target="#wishlist" data-toggle="modal" class="btn-wishlist btn-sm" type="button"
                    data-placement="left" title="Add to wishlist"><i class="fa fa-heart"></i>
                  </button>
                  <a *ngIf="item?.images.length == 1" class="card-img-hover d-block"
                    (click)="navigateToProductDetails(item)">
                    <img class="card-img-top card-img-back" [src]="item?.images[0]?.imagePath" alt="...">
                    <img class="card-img-top card-img-front" [src]="item?.images[0]?.imagePath" alt="...">
                  </a>
                  <a *ngIf="item?.images.length > 1" class="card-img-hover d-block"
                    (click)="navigateToProductDetails(item)">
                    <img class="card-img-top card-img-back" [src]="item?.images[0]?.imagePath" alt="...">
                    <img class="card-img-top card-img-front" [src]="item?.images[1]?.imagePath" alt="...">
                  </a>
                  <div class="card-info">
                    <div class="card-body">
                      <div class="product-title"><a class="link-title" (click)="navigateToProductDetails(item)">


                          <span *ngIf="lang==0">
                            {{item?.productName}}
                          </span>
                          <span *ngIf="lang==1">
                            {{item?.productNameAr}}
                          </span>
                        </a>
                        <!-- <p class="mb-4 description" title="{{item?.productDescription}}">{{(item?.productDescription.length>20)? (item?.productDescription | slice:0:25)+'...':(item?.productDescription) }}</p> -->
                        <p class="mb-2 description" title="{{item?.productDescription}}">
                          <span *ngIf="lang==0">
                            {{item?.productDescription}}
                          </span>
                          <span *ngIf="lang==1" style="float: right;">
                            {{item?.productDescription_Ar}}
                          </span>
                        </p>
                      </div>
                      <div class="mt-1">
                        <span class="product-price">
                          <div class="d-flex">
                            <span *ngIf="item?.discountPercent > 0">
                              QR {{item?.discountPrice | number:default_currencyformat}}
                            </span>
                            <del class="text-muted  align-items-center justify-content-center"
                              *ngIf="item?.discountPercent > 0">QR{{item?.price | number:default_currencyformat}}</del>
                          </div>
                          <span *ngIf="item?.discountPercent == 0">
                            QR {{item?.price | number:default_currencyformat}}
                          </span>
                        </span>


                        <div class="star-rating">
                          <ngb-rating [max]="5" [(rate)]="item.starRating" [readonly]="true"></ngb-rating>

                        </div>


                        <!-- <div class="star-rating"><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                          </div> -->
                      </div>
                    </div>
                    <div class="card-footer bg-transparent border-0">
                      <div class="product-link d-flex align-items-center justify-content-center">

                        <!-- <button class="btn-cart btn btn-primary btn-animated mx-3" type="button"><i class="las la-shopping-cart mr-1"></i>
                    </button> -->
                        <button (click)="onQuickView(item)" class="btn btn-view" data-toggle="tooltip"
                          data-placement="top" title="Quick View"><span data-target="#quick-view" data-toggle="modal">
                            <i class="fa fa-eye"></i></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </ng-container>
          </div>

          <nav class="mt-8">
            <ul class="pagination">
              <!-- <li class="page-item"><a class="page-link" href="#">Previous</a>
          </li>
          <li class="page-item"><a class="page-link" href="#">1</a>
          </li>
          <li class="page-item"><a class="page-link" href="#">2</a>
          </li>
          <li class="page-item"><a class="page-link" href="#">3</a>
          </li>
          <li class="page-item"><a class="page-link" href="#">Next</a>
          </li> -->
              <pagination-controls (pageChange)="p = $event; getProductListPage();scrollToTo();"></pagination-controls>


            </ul>
          </nav>
        </div>
        <div class="col-lg-3 col-md-12 sidebar mt-8 mt-lg-0 d-none d-sm-block ">
          <div class="shadow-sm p-5">
            <a (click)="clearFilter()" style="cursor: pointer; color: #fff;"
              class="clear-filter">{{'label.ClearAllFilters' | translate}}</a>
            <div class="widget widget-categories mb-4 pb-4 border-bottom">
              <h4 class="widget-title mb-3">{{'label.Categories' | translate}}</h4>
              <div id="accordion" class="accordion">
                <div class="card border-0 mb-3" *ngFor="let item of filterCategoryList; let i =index;">
                  <div class="card-header">
                    <h6 class="mb-0">
                      <a class="link-title" id="collapse{{i}}" data-toggle="collapse" data-parent="#accordion"
                        href="#collapse{{item.categoryId}}">

                        <span *ngIf="lang ==0">
                          {{item.categoryName}}

                        </span>
                        <span *ngIf="lang ==1">
                          {{item.categoryNameAr}}

                        </span>


                      </a>
                    </h6>
                  </div>
                  <div id="collapse{{item.categoryId}}" class="collapse" data-parent="#accordion">

                    <div class="card-body text-muted">
                      <ul class="list-unstyled">
                        <li *ngFor="let innerItem of item?.subCategories">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input"
                              id="innerItem{{innerItem?.subCategoryId}}" [(ngModel)]="innerItem.checked"
                              (change)="onSubCategChecked(innerItem , $event)">
                            <label class="custom-control-label innerCat" for="innerItem{{innerItem?.subCategoryId}}">

                              <span *ngIf="lang ==0">
                                {{innerItem?.subCategoryName}}

                              </span>
                              <span *ngIf="lang ==1">
                                {{innerItem?.subCategoryNameAr}}

                              </span>


                            </label>
                          </div>
                          <!-- <a style="cursor: pointer;" >{{innerItem.subCategoryName}}
                        <input type="checkbox" [(ngModel)]="innerItem.checked" (change)="onSubCategChecked(innerItem , $event)">
                      </a> -->
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>





            <div class="widget widget-price mb-4 pb-4 border-bottom">
              <h4 class="widget-title mb-3">{{'label.Price' | translate}}</h4>
              <!-- <ng-container *ngFor="let item of priceList">
            <input [(ngModel)]="selectedPriceSection" (change)="getProductList()" type="radio"  [id]="item.name" name="gender" [value]="item">
            <label for="male">{{item?.name}}</label><br>



          </ng-container>-->
              <div class="radiobuttons">

                <!-- <ng-container > -->

                <div class="rdio-primary radio-inline" *ngFor="let item of priceList">
                  <label class="radio radio-gradients">
                    <span class="radio__input">
                      <input type="radio" class="product-list-radio" name="radio" [value]="item"
                        [(ngModel)]="selectedPriceSection" (change)="getProductList()">
                      <span class="radio__controls color1"></span>
                    </span>
                    <span class="radio__label pricelable">{{item?.name}}</span>

                  </label>

                  <!--  <input [(ngModel)]="selectedPriceSection" (change)="getProductList()" class="arabic-radio" type="radio"  id="priceCheck{{item.name}}" name="radio" [value]="item" >

               <label for="{{item.name}}">{{item?.name}}</label><br>-->
                </div>
                <!-- </ng-container> -->

              </div>


            </div>
            <!-- <div class="mb-4 pb-4 border-bottom">
              <h4 class="widget-title mb-3">{{'label.Brand' | translate}}</h4>
              <div class="custom-control custom-checkbox mb-2" *ngFor="let item of filterBrandList">
                <input type="checkbox" class="custom-control-input" id="brand{{item?.brandId}}"
                  [(ngModel)]="item.checked" (change)="onBrandChecked(item , $event)">
                <label class="custom-control-label" for="brand{{item?.brandId}}">


                  <span *ngIf="lang == 0"> {{item?.brandName}}</span>
                  <span *ngIf="lang == 1"> {{item?.brandNameAr}}</span>


                </label>
              </div>

            </div> -->



            <div class="widget widget-color mb-4 pb-4 border-bottom">
              <h4 class="widget-title mb-3">{{'label.Color' | translate}}</h4>
              <!-- <ul class="list-inline color-select">
                <li *ngFor="let item of filterColorsList">
                  <a *ngIf="lang == 0" href="#" [attr.aria-label]="item.color1">
                    <div class="form-check box-div">
                      <input type="checkbox" class="form-check-input" id="color{{item.colorId}}" name="Radios"
                        [(ngModel)]="item.checked" (change)="onColorChecked(item , $event)">
                      <label class="form-check-label" for="color{{item.colorId}}"
                        [style.background-color]="item?.colorCode"></label>
                    </div>
                  </a>
                  <a *ngIf="lang == 1" href="#" [attr.aria-label]="item.colorAr">
                    <div class="form-check box-div">
                      <input type="checkbox" class="form-check-input" id="color{{item.colorId}}" name="Radios"
                        [(ngModel)]="item.checked" (change)="onColorChecked(item , $event)">
                      <label class="form-check-label" for="color{{item.colorId}}"
                        [style.background-color]="item?.colorCode"></label>
                    </div>
                  </a>
                 
                </li>

              </ul> -->


              <div class="select-1">
                <select (change)="onColorChecked()" [(ngModel)]="selectedFilterColor"  >
                  <option value="0">All Colors</option>
                  <option [value]="item.colorId" *ngFor="let item of filterColorsList">{{item.color}}</option>
                </select>
              </div>























            </div>



            
            <div class="widget widget-size">
              <h4 class="widget-title mb-3">{{'label.Size' | translate}}</h4>
              <ul class="list-inline clearfix">
                <li>
                  <input name="sc" id="size0" [value]="0" type="radio" (change)="getProductList()"
                    [(ngModel)]="selectedFilterSizeOption">
                  <label class="clearall" for="size0">{{'label.All' | translate}}</label>
                </li>
              
                <li *ngFor="let item of filterSizeOptions">
                  <input name="sc" id="size{{item?.sizeOptionId}}" (change)="getProductList()"
                    [value]="item?.sizeOptionId" type="radio" [(ngModel)]="selectedFilterSizeOption">
                  <label for="size{{item?.sizeOptionId}}">{{item?.euSizeOption}}</label>
                </li>
                <!-- <li>
              <input name="sc" id="xs-size" type="radio">
              <label for="xs-size">XS</label>
            </li> -->
                <!-- <li>
              <input name="sc" id="s-size" type="radio">
              <label for="s-size">S</label>
            </li>
            <li>
              <input name="sc" id="m-size" checked="" type="radio">
              <label for="m-size">M</label>
            </li>
            <li>
              <input name="sc" id="l-size" type="radio">
              <label for="l-size">L</label>
            </li>
            <li>
              <input name="sc" id="xl-size" type="radio">
              <label for="xl-size">XL</label>
            </li>
            <li>
              <input name="sc" id="xxl-size" type="radio">
              <label for="xxl-size">XXL</label>
            </li>
            <li>
              <input name="sc" id="3xl-size" type="radio">
              <label for="3xl-size">3XL</label>
            </li>
            <li>
              <input name="sc" id="4xl-size" type="radio">
              <label for="4xl-size">4XL</label>
            </li>
            <li>
              <input name="sc" id="5xl-size" type="radio">
              <label for="5xl-size">5XL</label>
            </li>
            <li>
              <input name="sc" id="36-size" type="radio">
              <label for="36-size">36</label>
            </li>
            <li>
              <input name="sc" id="38-size" type="radio">
              <label for="38-size">38</label>
            </li>
            <li>
              <input name="sc" id="39-size" type="radio">
              <label for="39-size">39</label>
            </li>
            <li>
              <input name="sc" id="40-size" type="radio">
              <label for="40-size">40</label>
            </li>
            <li>
              <input name="sc" id="42-size" type="radio">
              <label for="42-size">42</label>
            </li>
            <li>
              <input name="sc" id="44-size" type="radio">
              <label for="44-size">44</label>
            </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>



<!-- Cart Modal -->
<!-- <div class="modal fade cart-modal" id="cartModal" tabindex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ModalLabel">Your Cart (2)</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <div class="row align-items-center">
            <div class="col-5 d-flex align-items-center">
              <div class="mr-4">
                <button type="submit" class="btn btn-primary btn-sm"><i class="las la-times"></i>
                </button>
              </div>
              <a href="product-left-image.html">
                <img class="img-fluid" src="assets/images/product/01.jpg" alt="...">
              </a>
            </div>
            <div class="col-7">
              <h6><a class="link-title" href="product-left-image.html">Women Lather Jacket</a></h6>
              <div class="product-meta"><span class="mr-2 text-primary">$25.00</span><span class="text-muted">x 1</span>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-5">
        <div>
          <div class="row align-items-center">
            <div class="col-5 d-flex align-items-center">
              <div class="mr-4">
                <button type="submit" class="btn btn-primary btn-sm"><i class="las la-times"></i>
                </button>
              </div>
              <a href="product-left-image.html">
                <img class="img-fluid" src="assets/images/product/13.jpg" alt="...">
              </a>
            </div>
            <div class="col-7">
              <h6><a class="link-title" href="product-left-image.html">Men's Brand Tshirts</a></h6>
              <div class="product-meta"><span class="mr-2 text-primary">$27.00</span><span class="text-muted">x 1</span>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-5">
        <div class="d-flex justify-content-between align-items-center mb-8"> <span class="text-muted">Subtotal:</span>  <span class="text-dark">$52.00</span>
        </div> <a href="product-cart.html" class="btn btn-primary btn-animated mr-2"><i class="las la-shopping-cart mr-1"></i>View Cart</a>
        <a href="product-checkout.html" class="btn btn-dark"><i class="las la-money-check mr-1"></i>Continue To Checkout</a>
      </div>
    </div>
  </div>
</div> -->
<app-product-quick-view [selectedProductDetails]="selectedProductDetails" [selectedStockId]="selectedStockId">
</app-product-quick-view>









<!-- 

<button class="btn btn-view" id="wishlist-button" data-toggle="tooltip" data-placement="top" title="Quick View"><span
    data-target="#wishlist" data-toggle="modal">
    <i class="fa fa-eye"></i></span>
</button> -->



<div class="modal fade view-modal" id="wishlist" tabindex="-1" role="dialog" aria-hidden="true"
  style="margin-bottom: 90px;">
  <div class="modal-dialog modal-lg" style="width: 500px;" role="document">
    <div class="modal-content">
      <div class="modal-header border-bottom-0 pb-0">
        <button type="button" id="closeIdd" class="close" data-dismiss="modal"> <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">


          <div class="row">
            <div class="col-12 whish-hd" style="margin-top: -30px; margin-bottom: 15px;">
              <h5> {{'desc.PleaseChooseColorAndSize' | translate}}</h5>
            </div>
            <div class="col-12">
              <div class=" col-4 whish-dir">
                <label style="color: red;">{{'label.Color' | translate}} *</label>
              </div>


              <div class=" col-8 whish-dir-1">
                <span *ngFor="let item of selectedProductDetails?.colors; let i=index;" class="pro_details">

                  <label class="radio radio-gradient ">

                    <span class="radio__input ">
                      <input type="radio" name="radio1" class="" [value]="item?.colorId" [(ngModel)]="selectedColor"
                        (change)="onRadioColorChange()">
                      <span class="radio__control" [style.background-color]="item?.colorCode" ></span>
                    </span>
                    <span class="radio__label"></span>

                  </label>
                </span>
              </div>

            </div>
            <!--<select  class="custom-select col-lg-3 col-md-12 " id="inputGroupSelect01" [(ngModel)]="selectedColor"   (change)="onRadioColorChange()">
                <option disabled value="0">Color</option>
                <option *ngFor="let item of selectedProductDetails?.colors; let i=index;" [value]="item?.colorId" >{{item.colorName}}</option>
              </select> -->

          </div>


          <div class="row">
            <!-- <label>{{'label.Size' | translate}} *</label> -->

            <select *ngIf="sizeOptionsList.length > 0" class="custom-select col-lg-3 col-md-12 " id="inputGroupSelect01"
              [(ngModel)]="selectedSizeMaster">
              <!-- <option disabled value="0">Size</option> -->
              <option [value]="item?.id" *ngFor="let item of sizeMasterList">{{item.name}}</option>
            </select>


            <select *ngIf="sizeOptionsList.length > 0" class="custom-select col-lg-3 col-md-12" id="inputGroupSelect01"
              [(ngModel)]="selectedSizeOption" (change)="onRadioSizeChange()">
              <option disabled value="0">{{'label.Size' | translate}}</option>
              <option [value]="item?.sizeOptionId" *ngFor="let item of sizeOptionsList">

                <span *ngIf="selectedSizeMaster == 0"> {{item?.eUsizeOption}} </span>
                <span *ngIf="selectedSizeMaster == 1"> {{item?.uKsizeOption}} </span>
                <span *ngIf="selectedSizeMaster == 2"> {{item?.uSsizeOption}} </span>


              </option>
            </select>
          </div>
          <div class="row">
            <div class=" col-12" style="margin-top: 20px;">
              <a class="btn btn-animated btn-dark whish-bt" (click)="toWishList()"
                style="color: rgb(255, 255, 255) !important;">
                <i class="fa fa-heart mr-1" style="color: rgb(255, 255, 255) !important;"></i> {{'button.AddToWishList'
                | translate}}
              </a>

            </div>
          </div>



          <!-- <div class="row">
            <h2 class="popup-text-margin">Rate </h2>
          </div>
          <div class="row">
          </div>
         <div class="row">
            
            <span class="popup-text-margin">Write reviews</span>
            <textarea cols="80" rows="5"  style="margin: 10px 15px 10px 15px; border: dashed 1px rgb(102, 102, 102);" class="popup-text-feild" >
            </textarea>
           
         <div class="col-4">
          <a class="btn btn-primary btn-animated btn-block" style="color: #fff;">
          <span>Submit</span>
          </a></div>

          
        </div> -->
        </div>
      </div>
    </div>
  </div>
</div>