


<section class="bg-light">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-12 mt-3 mt-md-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item"><a class="text-dark" href="#"><i class="fa fa-home mr-1"></i>Home</a>
            </li>
            <li class="breadcrumb-item active text-primary" aria-current="page">return policy</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
  </section>


  <div class="page-content">

    <section>
    <div class="container">
      <div class="row align-items-end mb-5">
        <div class="col-12 col-md-12 col-lg-12">
          <div>
  
            <h2 class="mb-0">{{'privacyPolicy.text25' | translate}}</h2>
          </div>
      </div>
          <div class="col-12 col-md-12 col-lg-12">
              <h4 class="mb-0">{{'privacyPolicy.text26' | translate}}</h4>
            <div>
              <p class="lead mb-0">
                 <b> {{'privacyPolicy.text27' | translate}} </b>
  
                 {{'privacyPolicy.text28' | translate}}
                  </p>
            </div>
            <div>
              <p class="lead mb-0">
                {{'privacyPolicy.text29' | translate}}
                  <br/>
                  {{'privacyPolicy.text30' | translate}}<br/>
                  • {{'privacyPolicy.text31' | translate}}<br/>
  •	{{'privacyPolicy.text32' | translate}}<br/>
  •	{{'privacyPolicy.text33' | translate}}
                  <br/>
                  {{'privacyPolicy.text34' | translate}}
                  <br/>
                  {{'privacyPolicy.text35' | translate}}
  
              </p>
              <h4 class="mb-0">{{'privacyPolicy.text36' | translate}}</h4>
              <p class="lead mb-0">
  
  
                {{'privacyPolicy.text37' | translate}}
              </p>
              <p class="lead mb-0">
  
  
                {{'privacyPolicy.text38' | translate}}
              </p>
              <h4 class="mb-0"> {{'privacyPolicy.text39' | translate}}</h4>
              <p class="lead mb-0">
  
  
                {{'privacyPolicy.text40' | translate}}
              </p>
            </div>
          </div>
  
      </div>  

    </div>
    </section>

    </div>

  <!--body content end-->










