import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common-values/common.service';
import { Config } from 'src/app/common-values/config';
import { ConfirmationService } from 'src/app/services/confirmation.service';
import { DbService } from 'src/app/services/db.service';
declare function showNotification(type : string,titile : string,message :string): any;

@Component({
  selector: 'app-my-account-det',
  templateUrl: './my-account-det.component.html',
  styleUrls: ['./my-account-det.component.scss']
})
export class MyAccountDetComponent implements OnInit {
  addressList = [];
  pfsubmitted : boolean  = false;
  lang = Number(Config.lang)  ;

  rsubmitted : boolean = false;
  resData ;
  selectedMainData
  historyList = []
  selectedOrderDetails ;
  showOrderDetails :  boolean = false;
  cityList =[]
  isUpdateMode : boolean  = false;
  addNewAddress : boolean = false;
  deliveryForm : FormGroup;
  profileForm : FormGroup;

  constructor(private db : DbService , private common : CommonService,
    private datePipe : DatePipe,
  
    private router : Router,

    private spinner : NgxSpinnerService,
    private route : ActivatedRoute,
    private confirmationDialogService : ConfirmationService,
    private fb : FormBuilder) { 


      this.route.queryParams.subscribe((queryParams) => {
        if (queryParams.id != undefined) {
          
          if(Number(queryParams.id) == 1){
            // document.getElementById("defaultOpen1").click();
            // document.getElementById("my-account").classList.add("show-tab");
            // document.getElementById("order-history").classList.add("hide-tab");
            // document.getElementById("delivery-address").classList.add("hide-tab");
          }
          if(Number(queryParams.id) == 2){
            // document.getElementById("defaultOpen2").click();
            // document.getElementById("order-history").classList.add("show-tab");
            // document.getElementById("my-account").classList.add("hide-tab");
            // document.getElementById("delivery-address").classList.add("hide-tab");

          }
          if(Number(queryParams.id) == 3){
            // document.getElementById("defaultOpen3").click();

          }
        }
        else{
        //  document.getElementById("defaultOpen1").click();
        }
      })
    }


    getTrackingCodeDetails(trackingCode: string){
      this.db.methodGet("Common/TrackOrder/"+ trackingCode).subscribe(resdata =>{
        showNotification('warn','Status', resdata.message);
      })
     
    }

  ngOnInit(): void {
    this.buildDeliveryForm();
    this.buildProfileForm();
    this.buildCancelForm();

    this.getProfileDetails();
    this.getUserAddresses();
    this.getCities();
    this.getCustomerOrderHistoryList();




    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams.id != undefined) {
        
        if(Number(queryParams.id) == 1){
          document.getElementById("defaultOpen1").click();
          // document.getElementById("my-account").classList.add("show-tab");
          // document.getElementById("order-history").classList.add("hide-tab");
          // document.getElementById("delivery-address").classList.add("hide-tab");
        }
        if(Number(queryParams.id) == 2){
          document.getElementById("defaultOpen2").click();
          // document.getElementById("order-history").classList.add("show-tab");
          // document.getElementById("my-account").classList.add("hide-tab");
          // document.getElementById("delivery-address").classList.add("hide-tab");

        }
        if(Number(queryParams.id) == 3){
          document.getElementById("defaultOpen3").click();

        }
      }
      else{
       document.getElementById("defaultOpen1").click();
      }
    })

  }



  getProfileDetails(){
    this.spinner.show();
    this.db.methodGet("User/" + this.common.userDetails.userId).subscribe(resdata =>{
      this.spinner.hide();
      
      this.profileForm.patchValue(resdata);
      this.resData = resdata;
    })
  }
  getCustomerOrderHistoryList(){
    this.spinner.show();
    this.db.methodGet("order/GetOrderHistoryByUser/" + this.common.userDetails.userId).subscribe(resdata =>{
      
      this.spinner.hide();
      this.historyList = resdata;
    })
 }
 onLogOut(){
  this.confirmationDialogService.confirm('Please Confirm ','Are you sure to logout ?')
  .then((confirmed) =>
  {
    if(confirmed){
      localStorage.clear();
      this.common.isLoggedIn =false;
      this.router.navigate(['/'])
    }

    }
    )
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));

 }

 onOrderSelect(item){
   this.spinner.show();
   this.db.methodGet("order/GetOrderHistoryByOrder/" +item.orderId).subscribe(resdata =>{
     this.spinner.hide();
     this.selectedMainData = item;
     this.selectedOrderDetails = resdata;
     this.showOrderDetails = true;
   })
 }

  buildDeliveryForm() {
    this.deliveryForm = this.fb.group({
      addressId :[0 , Validators.required],
      name: ['', Validators.required],  //
      address1: ['', Validators.required],  //
      street: ['',Validators.required],
      landmark: ['' ,Validators.required],  //
      pincode: [''],
      cityId: ['', Validators.required], //
      cityName: [''],
      mobileNumber: ['', Validators.required],
      countryCode: ["974"],
      carrierCode: ['QAT'],
      userId :['', Validators.required],
      isPrimary: true,
      isActive:[true],
      isHomeAddess: true,
    });
  }
  buildProfileForm() {
    this.profileForm = this.fb.group({
      userName :['' , Validators.required],
      userId: [0, Validators.required],  //
      userFullName: ['', Validators.required],  //
      userMobile :['',Validators.required],
      userEmail: ['',[Validators.required , Validators.email]],
      genderId:[],
      password: ['' ,Validators.required],
      isCustomer:[],
      isActive:[],
      passwordSalt:[],
      countryCode:[],
      carrierCode:[],
      dob:[],
      createdDate:[new Date],
      updatedDate:[new Date],

    });
  }
  cancelForm : FormGroup;

  get rf() { return this.cancelForm.controls };
  get pf() { return this.profileForm.controls };
 
  buildCancelForm() {
    this.cancelForm = this.fb.group({
      Reason :['' , Validators.required],
     

    });
  }

  genderList =[
    {id : 1 , name :'Male'},
    {id : 2 , name :'Female'},
    {id : 3 , name :'Prefer not to say'},


  ]

  updateProfileDetails(){


    this.pfsubmitted = true;
  
    this.profileForm.controls.countryCode.setValue("QAT");
    this.profileForm.controls.carrierCode.setValue("974");
    this.profileForm.controls.isCustomer.setValue(true);
    this.profileForm.controls.isActive.setValue(true);

    if(this.profileForm.invalid){
      return;
    }

    // this.profileForm.controls.createdDate.setValue("2021-01-15T00:00:00");
    this.profileForm.controls.updatedDate.setValue(new Date);


    let body = {
      "userId": 5,
      "userName": "vijilstephen",
      "password": "1234",
      "passwordSalt": null,
      "userFullName": "Vijil Stephen",
      "userEmail": "vijilstephen@gmail.com",
      "userMobile": "9846054597",
      "countryCode": "IN",
      "carrierCode": "91",
      "genderId": 1,
      "dob": null,
      "createdDate": "2021-01-15T00:00:00",
      "updatedDate": "2021-03-14T22:24:54.267",
      "isCustomer": true,
      "isActive": true
  }
  this.spinner.show();
    this.db.methodPut("User/PutUser/" + this.common.userDetails?.userId , this.profileForm.value).subscribe(resdata =>{
      this.spinner.hide();
      
      if(resdata.userId > 0){
        showNotification('success','Updated','profile details updated successfully');
        this.getProfileDetails();
      }
    })
  }

  getUserAddresses(){
    this.spinner.show();
    this.db.methodGet("Addresses/GetUserAddress/" + this.common.userDetails?.userId).subscribe(resdata =>{
      
      this.spinner.hide();
      this.addressList = resdata;
    })
  }

   openCity(evt, cityName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  }



  submitted : boolean = false;
  formSubmit(){



    this.submitted  = true;
    this.f.userId.setValue(this.common.userDetails?.userId)
   
    this.f.isActive.setValue(true)

    if(this.deliveryForm.invalid){
      return;
    }
    this.f.cityId.setValue(Number(this.f.cityId.value))

    if(!this.isUpdateMode){
      this.spinner.show();
      this.db.methodPost("Addresses/PostAddress" , this.deliveryForm.value).subscribe(resdata =>{
        this.spinner.hide();
        
        if(resdata.addressId >0 ){
          showNotification('success','Added','Address added successfully');
          this.getUserAddresses();
          this.onFormCancel();

        }
      })
    }
    else{
      this.spinner.show();
      this.db.methodPut("Addresses/PutAddresses/" +   this.f.addressId.value, this.deliveryForm.value).subscribe(resdata =>{
        
        this.spinner.hide();
        if(resdata.addressId >0 ){
          showNotification('success','Updated','Address updated successfully');
          this.getUserAddresses();
          this.onFormCancel();
        }
      })
    }

  }


  get f() { return this.deliveryForm.controls }

  deleteAddress(element){
    this.confirmationDialogService.confirm('Please Confirm ','Are you sure delete this address ?')
    .then((confirmed) =>
    {
      if(confirmed){
        this.spinner.show();
     this.db.methodDelete("Addresses/DeleteAddress/" + element.addressId).subscribe(resdata =>{
       this.spinner.hide();
       
       if(resdata.addressId > 0){
        showNotification('success','Updated','Deleted succesfully');
        this.getUserAddresses();
       }
     })

    }
     }
    )
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));



  }


  onCityChange(){
    this.f.cityName.setValue(this.cityList.find(x => x.cityId === Number(this.f.cityId.value)).city1);
  }
  getCities(){
    this.db.methodGet("Configs/GetCities/1").subscribe(resdata =>{
      this.cityList = resdata;
    })
  }

  onFormCancel(){
    this.addNewAddress = false;
    this.isUpdateMode = false;
    this.buildDeliveryForm();
  }
  // Get the element with id="defaultOpen" and click on it

  onEdit(address){
    this.spinner.show();
    this.db.methodGet("Addresses/GetAddress/" + address.addressId).subscribe(resdata =>{
      this.spinner.hide();
      
      this.deliveryForm.patchValue(resdata);
      this.addNewAddress = true;
      this.isUpdateMode = true;
    })

  }





  isReviewUpdateMode : boolean = false
  
   review =""
   selectedItem ;
  onClickViewClick(item , key){
    this.selectedItem = item;
    this.review = "";


    if(key == 0){
      this.isReviewUpdateMode = false;
    }
    else{
      this.isReviewUpdateMode = true;
    }


    if(this.isReviewUpdateMode){
      let body = {
        ProductId : item.productId,
        CustomerId :this.common.userDetails.userId
      }
      this.db.methodPost("Ratings/GetCustomerRatings" , body).subscribe(resdata =>{
        
        this.review = resdata[0].review ;
        this.productRating = resdata[0].starRating 
      })
    }
    
  }

  onReviewSubmit(){
    let body = {
      productId : this.selectedItem.productId,
      customerId : this.common.userDetails.userId,
      starRating : this.productRating,
      review :this.review ,
      submittedOn : this.datePipe.transform(new Date , 'yyyy-MM-dd')

    }


    this.db.methodPost("Ratings",body).subscribe(resdata =>{
      
      if(resdata.ratingId > 0){
        showNotification('success','Success','Review Submitted Succesfully !');
        document.getElementById('closeId').click();
      }

    })
  }



  productRating : number = 0;
selectedBody ;

popUpClose(){

  document.getElementById('close-button').click();
}
  cancelOrder(){


    this.rsubmitted = true;
    if(this.cancelForm.invalid){
      return;
    }

    console.log(this.cancelForm.value)
    
    this.confirmationDialogService.confirm('Please Confirm ','Are you sure to continue ?')
    .then((confirmed) =>
    {
      if(confirmed){


        this.selectedBody.cancelNote =this.rf.Reason.value;
        // this.selectedBody= {
        //   orderId : orderId ,
        //   orderStatusId : status,
        //   userId : this.common.userDetails?.userId,
        //   cancelNote : ""
        //  }
     

        this.spinner.show();
         this.db.methodPut("order/CancelOrder/" + this.selectedBody.orderId  , this.selectedBody).subscribe(resdata =>{
           
           this.spinner.hide();
           if(resdata.orderId > 0){
             showNotification('success','Cancelled','order cancelled successfully');
   

             this.popUpClose();
             this.getCustomerOrderHistoryList()
                 // this.onSubmit();
           }
           else{
             showNotification('danger','Failed','order cancellation failed');
   
             // this.snack.open("Failed" , "Ok" , { duration : 4000})
           }
         })
      }
  
      }
      )
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  




  
  }



  createBody(orderId){
       this.buildCancelForm();
        this.selectedBody= {
          orderId : orderId ,
          isCancelled : true,
          userId : this.common.userDetails?.userId,
          cancelNote : ""
         }

  }

}
