


<section class="bg-light">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-12 mt-3 mt-md-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item"><a class="text-dark" href="#"><i class="fa fa-home mr-1"></i>{{'menu.Home' | translate}}</a>
            </li>
            <li class="breadcrumb-item active text-primary" aria-current="page">{{'menu.Terms&Conditions' | translate}}</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
  </section>


  <div class="page-content"  style="height: px;">

    <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          
        <div class="page" >
          <h3 class="text-primary mb-1">{{'menu.Terms&Conditions' | translate}}</h3> 
          <p class="lead mb-0" *ngIf="lang == 0">
            Site functionality a allows you to navigate the site and use our features, e.g. wish list, add to cart.
            Site analytics a allows us to measure and analyze how customers use our site in order to improve their shopping
             experience further. Customer preference a when browsing a customer's preference are noted in order to make their
              shopping experience as seamless and personalized as possible. Targeting preference a relevant ads and promotions
               for customer are displayed during marketing campaigns. By using our site, you agree to us placing these sorts of
                cookies on your device and accessing them when you visit the site in the future. If you want to delete any cookies
                 that are already on your computer, the help section in your browser should provide instructions on how to locate
                  the file or directory that stores cookies. A Further information about cookies can be found at www.aboutcookies.
                  org. Please note that by deleting or disabling future cookies, your user experience may be affected and you might
                   not be able to take advantage of certain functions of our site that  provides to its customers.

          </p>
          <p class="lead mb-0" *ngIf="lang == 1">
            تتيح لك وظائف الموقع أ إمكانية التنقل في الموقع واستخدام ميزاتنا ، على سبيل المثال قائمة الرغبات ، أضف إلى السلة. تسمح لنا تحليلات الموقع (أ) بقياس وتحليل كيفية استخدام العملاء لموقعنا من أجل تحسين تجربة التسوق لديهم بشكل أكبر. تتم الإشارة إلى تفضيل العميل أ عند استعراض تفضيلات العميل من أجل جعل تجربة التسوق الخاصة به سلسة وشخصية قدر الإمكان. استهداف التفضيل يتم عرض الإعلانات والعروض الترويجية ذات الصلة للعملاء أثناء الحملات التسويقية. باستخدام موقعنا ، فإنك توافق على وضع هذه الأنواع من ملفات تعريف الارتباط على جهازك والوصول إليها عند زيارة الموقع في المستقبل. إذا كنت ترغب في حذف أي ملفات تعريف ارتباط موجودة بالفعل على جهاز الكمبيوتر الخاص بك ، فيجب أن يوفر قسم المساعدة في متصفحك إرشادات حول كيفية تحديد موقع الملف أو الدليل الذي يخزن ملفات تعريف الارتباط. يمكن العثور على مزيد من المعلومات حول ملفات تعريف الارتباط على www.aboutcookies. غزاله. يرجى ملاحظة أنه من خلال حذف أو تعطيل ملفات تعريف الارتباط المستقبلية ، قد تتأثر تجربة المستخدم الخاصة بك وقد لا تتمكن من الاستفادة من بعض وظائف موقعنا التي توفر لعملائها.

          </p>
        
        </div>




          <!--<div class="d-md-flex align-items-end justify-content-between border-top pt-5">
            <div>
              <label class="text-dark h4" for="coupon">Coupon</label>
              <p>Enter your coupon code if you have one.</p>
              <div class="row form-row">
                <div class="col">
                  <input class="form-control" id="coupon" placeholder="Coupon Code" type="text">
                </div>
                <div class="col col-auto">
                  <button class="btn btn-dark btn-animated">Apply Coupon</button>
                </div>
              </div>
            </div>
            <button class="btn btn-primary btn-animated mt-3 mt-md-0">Update Cart</button>
          </div>-->
        </div>


        </div>

    </div>
    </section>

    </div>

  <!--body content end-->










