import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxSpinnerService } from 'ngx-spinner';
import { DbService } from 'src/app/services/db.service';
declare var $: any;
declare function owlcarousel(): any;

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  sectionImages : any;
  shopByCategoryList =[];
  shopByBrandsList =[];
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

//   imageObject : any;
// // , {
// //     image: '.../iOe/xHHf4nf8AE75h3j1x64ZmZ//Z==', // Support base64 image
// //     thumbImage: '.../iOe/xHHf4nf8AE75h3j1x64ZmZ//Z==', // Support base64 image
// //     title: 'Image title', //Optional: You can use this key if want to show image with title
// //     alt: 'Image alt' //Optional: You can use this key if want to show image with alt
// // }
// // ];
  constructor(private db : DbService , private router  : Router,
    private spinner : NgxSpinnerService) { }

  ngOnInit(): void {
  this.getSectionImages();
  this.getShopByBrands();
  this.getShopByCategory();
  }


  onlcick(evnt){
  }

  getShopByBrands(){
    this.db.methodOptions("Common/GetShopByBrands").subscribe(resdata =>{

      this.shopByBrandsList = resdata;
      this.shopByBrandsList.forEach((x,index) =>{
      })
    })
  }
  navigate(element){
    if(element.brandId == undefined){
      element.brandId= 0
    }
    if(element.subCategoryId == undefined){
      element.subCategoryId= 0
    }
    if(element.categoryId == undefined){
      element.categoryId= 0
    }
        this.router.navigate(['/product-list'] ,{
          queryParams: { name : element.name  , brandId : btoa(element.brandId)  , categoryId : btoa(element.categoryId)
            , subCategoryId: btoa(element.subCategoryId)
      }
    })
  }

  getShopByCategory(){
    this.db.methodOptions("Common/GetShopByCategories").subscribe(resdata =>{

      let list = []
      list = resdata;
      this.shopByCategoryList = list.filter(x =>  x.categoryImages.length > 0)      
      // this.shopByCategoryList = resdata;
      owlcarousel();
    //  this.imageObject = this.shopByCategoryList.map((data) =>{
    //         return {
    //           image: 'el',
    //           thumbImage: 'el',
    //           alt: 'alt of image',
    //           title: 'title of image'
    //         }
    // })

    //   this.shopByCategoryList.forEach( x=>{
    //     let element : {
    //       image: '../../../../assets/images/content-img/img9.jpg',
    //       thumbImage: '../../../../assets/images/content-img/img9.jpg',
    //       alt: 'alt of image',
    //       title: 'title of image'
    //     }


    //     this.imageObject.push(element)
    //   })


      // console.log(this.imageObject)

    })
  }

  getSectionImages(){


    this.spinner.show();
    
    this.db.methodOptions("Common/GetSectionimages").subscribe(resdata =>{
       this.spinner.hide();
      this.sectionImages = resdata;


    })
  }

}


