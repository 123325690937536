import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shipping-information',
  templateUrl: './shipping-information.component.html',
  styleUrls: ['./shipping-information.component.scss']
})
export class ShippingInformationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
