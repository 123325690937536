import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ActivateGuard implements CanActivate {
  sidebarnavItems ;
  constructor(private router:Router){
  }
  canActivate(

    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    var succ = localStorage.getItem('currentUser');
    //return true;
    if(succ)
    {

      return true;
    }
    else{
      //alert("Access Denied, Redirecting to Login Page");
      this.router.navigate(['/error']);
      return false;
    }
  }




}
