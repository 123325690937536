import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/common-values/common.service';
import { Config } from 'src/app/common-values/config';
import { DbService } from 'src/app/services/db.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  lang = Number(Config.lang)
  constructor(private db : DbService, public common : CommonService,private router : Router) { }

  ngOnInit(): void {
    this.getConfigDetails();
  }


  footerNavigation(id){
    if(this.common.isLoggedIn){
      if(id == 1){
        this.router.navigate(['/my-account'])
      }
      if(id == 2){
        this.router.navigate(['/wishlist'])
      }
    }
    else{
      this.router.navigate(['/login'])
    }
  }

  getConfigDetails(){
    this.db.methodGet("Configs/1").subscribe(resdata =>{
      this.common.configDetails = resdata;
    })
  }

}
