<section class="bg-light">
  <div class="container">
    <div class="row align-items-center">
      <!-- <div class="col-md-6">
        <h1 class="h2 mb-0">Order</h1>
      </div> -->
      <div class="col-md-12 mt-3 mt-md-0">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb bg-transparent p-0 m-0">
            <li class="breadcrumb-item"><a class="text-dark" href="#"><i class="fa fa-home mr-1"></i>{{'menu.Home' | translate}}</a>
            </li>
            <li class="breadcrumb-item active text-primary" aria-current="page">{{'menu.Order' | translate}}</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- / .row -->
  </div>
  <!-- / .container -->
</section>

<div class="page-content">

  <section class="text-center" style="margin-bottom: 10px">
    <div class="container">
      <div class="row">
        <!-- <div class="col-md-12" style="text-align: center;">
          <h3>{{'desc.ThanksOrderMessage1' | translate}}</h3>

          <div *ngIf="!common?.isLoggedIn" style="text-align: center; margin-bottom: 5px;"> {{'desc.ThanksOrderMessage2' | translate}}</div>
          <div class="col-md-12" style=" text-align: center;">
         <input type="text" id="lname" class="form-verify" placeholder="-----" maxlength="5" *ngIf="!common?.isLoggedIn"></div>
        </div>

        <div class="col-md-12">
  
          <button *ngIf="!common?.isLoggedIn"  class="btn btn-primary btn-animated"> {{'label.VerifyYourPhoneNumber' | translate}}</button>
        </div>
        <div class="col-md-12">
          <button *ngIf="!common?.isLoggedIn"  class="resend"> {{'button.ResendVerification' | translate}}</button>
        </div> -->

        <div class="col-md-12">
         <div class="col-md-2" style="float: left;"></div>



         <div class="col-md-8" style="float: left;">
          <div class="row">
            <div class="col-md-12">
              <a class="items">
                <h5> {{'label.Order' | translate}} #{{orderDetails?.billNo}} </h5>
              </a> <a class="edit-btn"> </a>
            </div>


            <ng-container *ngFor="let item of orderDetails?.orderDetails">
            <div class="col-md-3" style="margin-top: 15px;">
              <img [src]="item?.productImages[0]?.imagePath" class="mobile-image"  style="width: 100%;" />
            </div>


            <div class="col-md-9" style="margin-top: 15px;">
              <div style="margin:0px; padding:0px;">
                <ul class="list-unstyled list-left">
                  <li class="pb-3"><span> <b> 
                    
                    
                    <span *ngIf="lang ==0">{{item?.productName}}</span>
                    <span *ngIf="lang ==1">{{item?.productNameAr}}</span>
  
                        </b></span> </li>
                  <li class="pb-3"><span>
                    
                    <span *ngIf="lang ==0">{{item?.colorName}}</span>
                    <span *ngIf="lang ==1">{{item?.colorNameAr}}</span>
                  
                  
                  </span>
                  </li>

                  <li class="pb-3"><span> {{'label.Size' | translate}} : {{item?.sizeOptionName}} </span>
                  </li>

                  <li class="pb-3"> <span>
                    {{'label.Qty' | translate}} : {{item?.quantity}} </span>
                  </li>


                  <li class="pb-3"><span>  {{'label.Price' | translate}} : {{item?.price | number : '1.2-2'}} </span> </li>
                 </ul>










              </div>
            </div>
          </ng-container>

          <div class="col-md-12">
            <div class="col-md-5" style="float: left;"></div>
            <div class="col-md-7" style="float: left;">
              <ul class="list-unstyled list-cart">
                <li style="text-align: right;">
                  <span style="font-size: 19px;"><strong>  {{'label.Total' | translate}} </strong></span>
                  <span style="margin-left: 40px; font-size: 17px;"> QR. {{orderDetails?.totalAmount |
                    number:default_currencyformat}} </span>
                </li>

                <li style="text-align: right;" *ngIf="orderDetails?.discountAmount > 0">
                  <span style="font-size: 19px;"><strong>  {{'label.CouponDiscount' | translate}}  </strong></span>
                  <span style="margin-left: 40px; font-size: 17px;"> QR. {{orderDetails?.discountAmount |
                    number:default_currencyformat}} </span>
                </li>

                <li style="text-align: right;" *ngIf="orderDetails?.discountAmount > 0">
                  <span style="font-size: 19px;"><strong>  {{'label.NetTotal' | translate}} </strong></span>
                  <span style="margin-left: 40px; font-size: 17px;"> QR. {{orderDetails?.discounttotalAmount |
                    number:default_currencyformat}} </span>
                </li>

              </ul>
            </div>
          </div>



          <div class="col-md-12">
            <div class="border" style="margin: 15px 0px 15px 0px;"></div>
              <div class="col-md-4" style="float: left;"><ul class="list-unstyled list-cart">
                <li><span><strong> <b> {{'label.DeliveringTo' | translate}}</b>
                    </strong></span>
                </li>
                <p> {{orderDetails?.address?.name}} <br />
                  {{orderDetails?.address?.address1}}<br />
                  {{orderDetails?.address?.mobileNumber}}
                </p>
              </ul></div>
              <div class="col-md-4" style="float: left;">
                <ul class="list-unstyled list-cart">
                <li><span><strong> <b> {{'label.PaymentMethod' | translate}}</b>
                    </strong></span>
                </li>
                <p *ngIf="orderDetails?.paymentModeId == 1"> {{'label.CashOnDelivery' | translate}}</p>
                <p *ngIf="orderDetails?.paymentModeId == 2"> {{'button.Card' | translate}}</p>

              </ul></div>

              <div class="col-md-4" style="float: left;">

               <a class="btn btn-primary btn-animated" routerLink="/"><i class="fa fa-home mr-1"></i>{{'button.Home' | translate}}</a>

                <a class="btn btn-dark btn-animated" style="color: white;" (click)="navigate()"><i class="fa fa-shopping-cart mr-1"></i>{{'button.CountinueShopping' | translate}}</a>
             </div>
          </div>










         </div>
         </div>
         <div class="col-md-2" style="float: left;"></div>

        </div>







      </div>
    </div>
  </section>

</div>
